import './styles.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { EnumDropdownField, getItemsFromEnum } from '@/components/EnumDropdown';

import { AirportDropdownField } from '@/components/AirportDropdown';
import { DatetimeInputField } from '@/components/DatetimeInput';
import Form from '@/common/Form';
import GenericFileUpload from '@/components/FileUpload/new';
import { InputField } from '@/components/Input';
import { PhoneInputField } from '@/components/MaskedInput/PhoneInput';
import { TextAreaField } from '@/components/TextArea';
import { TripTypeDropdownField } from '@/components/TripTypeDropdown';
import { UserDropdownField } from '@/components/UserDropdown';
import useLocale from '@/hooks/useLocale';

const IssueHistoryForm = ({ onSubmit, locale: customLocale, value = {} }: IssueHistoryFormDefinitions.Props): JSX.Element => {
  const locale = useLocale({
    ...IssueHistoryFormDefinitions.getLocale(value),
    ...customLocale,
  });
  const handleSubmit = async (data) => {
    data = await onSubmit?.(data);
    // await Call BE and submit the data.
  };

  //Remove these enums after form is wired up to backend
  enum TEMP_RootCauseEnum {
    DISPATCH = 'Dispatch',
    OTHER = 'Other',
  }
  enum TEMP_UsedTaxiEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TEMP_PlanePushedLateEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TEMP_RecoveryNeededEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TEMP_RecoveryCompleteEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TEMP_TaxiDetailsEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TEMP_NumberOfCrewEnum {
    ZERO = '0',
    ONE = '1',
    TWO = '2',
    THREE = '3',
    FOUR = '4',
    FIVE = '5',
    SIX = '6',
    SEVEN = '7',
    EIGHT = '8',
    NINE = '9',
    TEN = '10',
  }

  return (
    <Form className="IssueHistoryForm" onSubmit={handleSubmit} value={IssueHistoryFormDefinitions.initValue}>
      <Row className="IssueHistoryFormHeader">
        <Col>
          {
            /* Section appears if trip linked */
            /*value.id*/ true && (
              <>
                <span>DL1212 1/22/25 05:30 EST</span>
                <span>ATL - Keith Sanderson - #4321</span>
              </>
            )
          }
          <h4 className="IssueHistoryFormTitle">{locale('Dispatch Issues')}</h4>
        </Col>
      </Row>
      {
        /* Section appears if no trip linked */
        /*!value.id*/ false && (
          <Row className="IssueHistoryFormLinkTrips">
            <Col>
              <span>THIS IS NOT LINKED TO TRIP.</span>
              <span>
                WOULD YOU LIKE TO LINK THIS TO A TRIP? <a className="Link">YES</a>
              </span>
            </Col>
          </Row>
        )
      }
      <Row>
        {
          /* Station field appears if no trip linked */
          /*value.id*/ false && (
            <Col xs={6}>
              <Form.Input as={AirportDropdownField} name="station" label={locale('Station')} />
            </Col>
          )
        }
        <Col xs={6}>
          <Form.Input as={EnumDropdownField} name="rootCause" label={locale('Root Cause')} enum={getItemsFromEnum(TEMP_RootCauseEnum)} />
        </Col>
        {/* Date time field centers if trip is not linked */}
        <Col xs={{ span: 6, offset: /*value.id*/ true ? 0 : 3 }}>
          <Form.Input as={DatetimeInputField} name="issueDateTime" label={locale('Issue Date/Time')} />
        </Col>
      </Row>
      {
        /* Section appears if trip linked */
        /*value.id*/ true && (
          <Row>
            <Col>
              <Form.Input as={TripTypeDropdownField} name="flightType" label={locale('Flight Type')} />
            </Col>
          </Row>
        )
      }
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="description" label={locale('Description Of Flight Issue')} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={UserDropdownField} name="dispatcher" label={locale('Dispatcher')} />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="planeLate"
            label={locale('Did The Plane Push Late?')}
            enum={getItemsFromEnum(TEMP_PlanePushedLateEnum)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={EnumDropdownField} name="usedTaxi" label={locale('Used Taxi/Lyft?')} enum={getItemsFromEnum(TEMP_UsedTaxiEnum)} />
        </Col>
        <Col xs={6}>
          <Form.Input as={InputField} name="taxiCost" label={locale('Cost Of Taxi/Lyft')} maxLength={10} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={InputField} name="recoveryFlightNumber" label={locale('Recovery Flight #')} maxLength={10} />
        </Col>
        <Col xs={6}>
          <Form.Input as={DatetimeInputField} name="recoveryDateTime" label={locale('Recovery Date/Time')} />
        </Col>
      </Row>
      {
        /* Section appears if trip linked */
        /*value.id*/ true && (
          <Row>
            <Col>
              <Form.Input as={TextAreaField} name="outboundFlightInfo" label={locale('Outbound Flight Information')} />
            </Col>
          </Row>
        )
      }
      <Row>
        <Col>
          <Form.Input as={InputField} name="modNotified" label={locale('Which Mod Notified For Recovery')} maxLength={10} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={InputField} name="crewMemberName" label={locale('Crew Member Name')} maxLength={10} />
        </Col>
        <Col xs={6}>
          <Form.Input as={PhoneInputField} name="crewMemberPhone" label={locale('Crew Member Phone')} />
        </Col>
      </Row>
      <Row className="IssueHistoryFormSectionHeader">
        <Col>
          <h4 className="IssueHistoryFormSummarySectionTitle">{locale('Issue Summary')}</h4>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={UserDropdownField} name="driverName" label={locale('Driver Name')} maxLength={10} />
        </Col>
        <Col xs={6}>
          <Form.Input as={PhoneInputField} name="driverPhone" label={locale('Driver Phone')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input
            as={TextAreaField}
            name="rootCauseExplanation"
            label={locale('Root Cause')}
            placeholder={locale('Detailed Explanation')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="recoveryNeeded"
            label={locale('Is Recovery Needed?')}
            enum={getItemsFromEnum(TEMP_RecoveryNeededEnum)}
          />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="recoveryComplete"
            label={locale('Is Recovery Complete?')}
            enum={getItemsFromEnum(TEMP_RecoveryCompleteEnum)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="taxiDetails"
            label={locale('Taxi Details')}
            enum={getItemsFromEnum(TEMP_TaxiDetailsEnum)}
          />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="numberCrewGiftCard"
            label={locale('# Of Crew For Gift Card')}
            enum={getItemsFromEnum(TEMP_NumberOfCrewEnum)}
            options={{
              locale: {
                Zero: locale('0'),
                One: locale('1'),
                Two: locale('2'),
                Three: locale('3'),
                Four: locale('4'),
                Five: locale('5'),
                Six: locale('6'),
                Seven: locale('7'),
                Eight: locale('8'),
                Nine: locale('9'),
                Ten: locale('10'),
              },
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Button name="SUBMIT" variant="success" onClick={handleSubmit} style={{ width: '100%', textTransform: 'uppercase' }}>
            {locale('Create Dispatch Issue')}
          </Button>
        </Col>
      </Row>
      <Row className="IssueHistoryFormUpload">
        <Col>
          <GenericFileUpload
            endpoint=""
            options={{
              showFiles: true,
              multiple: true,
            }}
            locale={{
              Upload: 'UPLOAD FILES',
            }}
            onError={async (...args) => console.log('', ...args)}
            onSuccess={async (...args) => console.log('', ...args)}
          />
        </Col>
      </Row>
      <Row className="IssueHistoryFormHistory">
        <Col>
          <h4 className="IssueHistoryFormHistoryTitle">{locale('Dispatch Issue History')}</h4>
        </Col>
      </Row>
    </Form>
  );
};

export namespace IssueHistoryFormDefinitions {
  export type Locale = {
    SUBMIT_BUTTON_TEXT?: string;
    'Dispatch Issues'?: string;
    Station?: string;
    'Root Cause'?: string;
    'Issue Date/Time'?: string;
    'Flight Type'?: string;
    'Description Of Flight Issue'?: string;
    Dispatcher?: string;
    'Did The Plane Push Late?'?: string;
    'Used Taxi/Lyft?'?: string;
    'Cost Of Taxi/Lyft'?: string;
    'Recovery Flight #'?: string;
    'Recovery Date/Time'?: string;
    'Outbound Flight Information'?: string;
    'Which Mod Notified For Recovery'?: string;
    'Crew Member Name'?: string;
    'Crew Member Phone'?: string;
    'Issue Summary'?: string;
    'Driver Name'?: string;
    'Driver Phone'?: string;
    'Detailed Explanation'?: string;
    'Is Recovery Needed?'?: string;
    'Is Recovery Complete?'?: string;
    'Taxi Details'?: string;
    '# Of Crew For Gift Card'?: string;
    'Create Dispatch Issue'?: string;
    'Dispatch Issue History'?: string;
    '0'?: string;
    '1'?: string;
    '2'?: string;
    '3'?: string;
    '4'?: string;
    '5'?: string;
    '6'?: string;
    '7'?: string;
    '8'?: string;
    '9'?: string;
    '10'?: string;
  };
  export type Value = {
    id: string;
    station: string;
    rootCause: string;
    issueDateTime: string;
    flightType: string;
    description: string;
    dispatcher: string;
    planeLate: string;
    usedTaxi: string;
    taxiCost: string;
    recoveryFlightNumber: string;
    recoveryDateTime: string;
    outboundFlightInfo: string;
    modNotified: string;
    crewMemberName: string;
    driverName: string;
    driverPhone: string;
    rootCauseExplanation: string;
    recoveryNeeded: string;
    recoveryComplete: string;
    taxiDetails: string;
    numberCrewGiftCard: string;
  };
  export type Props = {
    onSubmit?: (data: Value) => void | Value | Promise<Value> | undefined;
    locale?: Partial<Locale>;
    value?: Partial<Value>;
  };
  export const initValue: Value = {
    id: undefined,
    station: '',
    rootCause: '',
    issueDateTime: '',
    flightType: '',
    description: '',
    dispatcher: '',
    planeLate: '',
    usedTaxi: '',
    taxiCost: '',
    recoveryFlightNumber: '',
    recoveryDateTime: '',
    outboundFlightInfo: '',
    modNotified: '',
    crewMemberName: '',
    driverName: '',
    driverPhone: '',
    rootCauseExplanation: '',
    recoveryNeeded: '',
    recoveryComplete: '',
    taxiDetails: '',
    numberCrewGiftCard: '',
  };
  export const getLocale = (value: Partial<Value>): Partial<Locale> => ({
    SUBMIT_BUTTON_TEXT: value.id ? 'Edit Issue' : 'Create Issue',
  });
}

export default IssueHistoryForm;
