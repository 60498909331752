import { DATETIME_FORMAT, TIME_FORMAT } from '@/constants';
import { Datetime, getClasses } from '@/utils';
import { ForwardRefExoticComponent, forwardRef, useCallback } from 'react';

import { Button } from 'react-bootstrap';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';
import Tippy from '@tippyjs/react';
import { useIssueHistory } from '@/features/IssueHistory';

const IssueHistoryTableRow = ({ id }: { id: string }, ref: ForwardRefExoticComponent<HTMLDivElement>): JSX.Element => {
  const [{ issue, selected }, setState] = useIssueHistory(({ issues, selected }) => ({
    issue: issues.get(id),
    selected: selected.has(id),
  }));

  const onSelect = useCallback(
    (): void =>
      setState((current) => {
        const newSelected = new Map(current.selected);
        if (newSelected.has(id)) newSelected.delete(id);
        else newSelected.set(id, current.issues.get(id));
        return { ...current, selected: newSelected };
      }),
    [issue, id, setState]
  );
  const onEdit = useCallback((): void => {
    console.log('ON_EDIT', id);
  }, [id]);
  const onDelete = useCallback((): void => {
    console.log('ON_DELETE', id);
  }, [id]);

  return (
    <SimpleTableRow id={id} className={getClasses(selected ? 'SELECTED' : undefined)} onDoubleClick={onEdit} ref={ref}>
      <SimpleTableCell.Select checked={selected} onChange={onSelect} />
      <SimpleTableCell name="AIRPORT">{issue.airport}</SimpleTableCell>
      <SimpleTableCell name="DATETIME">{new Datetime(issue.datetime).format(DATETIME_FORMAT)}</SimpleTableCell>
      <SimpleTableCell name="AIRLINE">{issue.airline}</SimpleTableCell>
      <SimpleTableCell name="FLIGHT_NUMBER">{issue.flightNumber}</SimpleTableCell>
      <SimpleTableCell name="EXPLOYEE">{new Datetime(issue.employeeId).format(TIME_FORMAT)}</SimpleTableCell>
      <SimpleTableCell name="ROOT_CAUSE">{issue.rootCause}</SimpleTableCell>
      <SimpleTableCell name="TAXI_CALLED">{issue.taxiCalled}</SimpleTableCell>
      <SimpleTableCell name="TAXI_COST">{issue.taxiCost}</SimpleTableCell>
      <SimpleTableCell name="RECOVERY">{issue.recovery}</SimpleTableCell>
      <SimpleTableCell name="PLANE_PUSHED" style={{ color: issue.planePushed ? 'var(--bs-danger)' : 'var(--bs-green)' }}>
        {issue.planePushed ? 'Yes' : 'No'}
      </SimpleTableCell>
      <SimpleTableCell name="EDIT">
        <Tippy content="Edit Issue">
          <Button name="EDIT" variant="icon" onClick={onEdit}>
            <i className="sv sv-pencil" />
          </Button>
        </Tippy>
      </SimpleTableCell>
      <SimpleTableCell name="DELETE">
        <Tippy content="Delete Issue">
          <Button name="DELETE" variant="icon" onClick={onDelete}>
            <i className="sv sv-trash2" />
          </Button>
        </Tippy>
      </SimpleTableCell>
    </SimpleTableRow>
  );
};

export default forwardRef(IssueHistoryTableRow);
