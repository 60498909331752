import './styles.scss';

import DynamicContent, { DynamicContentAs } from '@/components/DynamicContent';
import React, { useMemo } from 'react';
import { UseTripViewState, initUseTripsModalState, useTripViewState } from '@/features/Trips/components/TripModal/views/trip/hook';

import ActivityView from '@/features/Trips/components/TripModal/views/activity';
import IncidentView from '@/features/Trips/components/TripModal/views/incident';
import { Trip } from '@/models/gen/graphql';
import TripView from '@/features/Trips/components/TripModal/views/trip';
import { createComponentState } from '@/state';
import equal from 'fast-deep-equal/es6/react';
import useConfirmation from '@/hooks/useConfirmation';
import useSettings from '@/state/settings';

export type TripModalTab =
  | 'trip'
  | 'flag'
  | 'communication'
  | 'completion'
  | 'activity'
  | 'fcr'
  | 'combine'
  | 'rate'
  | 'incident'
  | 'accident'
  | 'lostAndFound';
export type TripModalState = {
  as?: DynamicContentAs;
  show: boolean;
  data: Partial<Trip>;
  tab?: TripModalTab;
  selected?: Partial<Trip>[];
  onSubmit?: (state: any) => void;
};
export type TripModalViewProps = {
  as?: DynamicContentAs;
  data: Partial<Trip>;
  title?: string;
  mode?: 'editing' | 'creating';
  selected?: Partial<Trip>[];
  onSubmit?: (state: UseTripViewState, persist?: boolean) => void;
  onHide?: () => Promise<boolean>;
};
const initState: TripModalState = { as: 'drawer', show: false, data: null, tab: 'trip', selected: [] };
export const useTripModalState = createComponentState(initState);

const TripModal = (): JSX.Element => {
  const state = useTripModalState(({ state }) => state);
  const setState = useTripModalState(({ setState }) => setState);
  const original = useTripViewState(({ state }) => state?.original);
  const trip = useTripViewState(({ state }) => state?.trip);
  const setViewState = useTripViewState(({ setState }) => setState);
  const { as, show, data, tab, onSubmit, ...rest } = state;
  const mode = data?.id ? 'editing' : 'creating';
  const [{ dynamicContentAs }] = useSettings(
    ({
      state: {
        trips: { dynamicContentAs = 'drawer' },
      },
    }) => [{ dynamicContentAs } as { dynamicContentAs: DynamicContentAs }]
  );

  const confirmHide = useConfirmation({
    Body: {
      message: (
        <>
          <span>Are you sure?</span>
          <br />
          <small>Any unsaved changes will be lost.</small>
        </>
      ),
    },
  });

  const onClickModalTab = (tabName: TripModalTab) => {
    setState((current) => ({ ...current, tab: tabName }));
  };

  const handleSubmit = (state: any, persist: boolean = false) => {
    onSubmit?.(state);
    if (!persist) onHide(false);
  };
  const onHide = async (isCancel: boolean = true): Promise<boolean> => {
    try {
      if (!equal(original, trip) && isCancel) await confirmHide();
      setState(initState);
      setViewState(initUseTripsModalState);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const [View, title] = useMemo(() => {
    const DefaultView = () => <></>;
    switch (tab) {
      case 'trip':
        return [TripView, mode === 'editing' ? 'Edit Trip' : 'Create Trip'];
      case 'activity':
        return [ActivityView, 'Activity'];
      case 'incident':
        return [IncidentView, 'Incident'];
      case 'accident':
        return [IncidentView, 'Accident'];
      case 'lostAndFound':
        return [IncidentView, 'Lost And Found'];
      default:
        return [DefaultView];
    }
  }, [tab, mode]);

  const createTabOption = (iconName, tooltip, tabName) => ({
    icon: <i className={`sv sv-${iconName}`} />,
    tooltip,
    onClick: () => onClickModalTab(tabName),
    active: tab === tabName,
  });

  const tabOptions =
    mode === 'editing'
      ? [
          createTabOption('plane', 'Trip', 'trip'),
          createTabOption('bubble-dots', 'Activity', 'activity'),
          //createTabOption('flag-empty', 'Flags', 'flag'),
          //createTabOption('message2', 'Communications', 'communication'),
          //createTabOption('completion', 'Completion', 'completion'),
          //createTabOption('fcr', 'Complaints', 'fcr'),
          createTabOption('ambulance', 'Report Accident', 'accident'),
          createTabOption('register', 'Report Incident', 'incident'),
          createTabOption('box', 'Report Lost Item', 'lostAndFound'),
          //createTabOption('combine-icon', 'Combine', 'combine'),
          //createTabOption('bag-dollar', 'Rate', 'rate'),
        ]
      : undefined;

  return (
    <DynamicContent
      className={`TripModal p-3 TripModal-${dynamicContentAs || as}`}
      show={show}
      drawer
      modal
      as="drawer"
      onChangeAs={(as: DynamicContentAs) => setState((current) => ({ ...current, as }))}
      onHide={onHide}
      options={{
        modal: { props: { style: { top: 15 }, tabs: tabOptions, modalType: mode === 'editing' ? 'editingTrip' : 'createTrip' } },
        drawer: {
          props: {
            width: 560,
            tabs: tabOptions,
          },
        },
      }}
    >
      {data && (
        <View
          data={data}
          title={title}
          mode={data?.id ? 'editing' : 'creating'}
          onSubmit={handleSubmit}
          onHide={onHide}
          {...rest}
          as={dynamicContentAs || as}
        />
      )}
    </DynamicContent>
  );
};

export default TripModal;
