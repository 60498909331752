import Input, { InputProps } from '@/components/Input';
import React, { useMemo } from 'react';
import { format, useMask } from '@react-input/mask';

import { fieldWrapper } from '@/components/Field';
import { getClasses } from '@/utils';

const maskOptions = { mask: '(___) ___-____', replacement: { _: /\d/ } };
const placeholder = maskOptions.mask;

const PhoneInput = (inputProps: InputProps): JSX.Element => {
  const inputRef = useMask(maskOptions);
  const handleChange = (value: string): void => inputProps.onChange?.(value.replace(/\D/g, ''));
  const value = useMemo((): string => format(inputProps.value as string, maskOptions), [inputProps.value]);
  return (
    <Input
      {...inputProps}
      className={getClasses('PhoneInput', inputProps.className)}
      type="tel"
      ref={inputRef}
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
    />
  );
};

export const PhoneInputField = fieldWrapper<InputProps>(PhoneInput);

export default PhoneInput;
