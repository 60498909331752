import './styles.scss';

import { Offcanvas as BootstrapOffCanvas, OffcanvasProps as BootstrapOffCanvasProps } from 'react-bootstrap';

import CloseButton from '@/common/CloseButton';
import DrawerBody from '@/common/Drawer/DrawerBody';
import DrawerHeader from '@/common/Drawer/DrawerHeader';
import { getClasses } from '@/utils';

export type DrawerPlacement = 'top' | 'right' | 'bottom' | 'left';
export type DrawerProps = {
  title?: string;
  icon?: JSX.Element;
  content?: () => JSX.Element;
  header?: () => JSX.Element;
  placement?: DrawerPlacement;
} & Omit<BootstrapOffCanvasProps, 'placement' | 'title'>;

const Drawer = ({ title, icon, content: Content, header: Header, children, ...offCanvasProps }: DrawerProps) => {
  const adaptedProps = adaptProps({ ...offCanvasProps });
  return (
    <BootstrapOffCanvas
      placement="end"
      {...adaptedProps}
      className={getClasses('Drawer', adaptedProps.className)}
      backdropClassName={getClasses('Drawer-Backdrop', adaptedProps.backdropClassName)}
    >
      <CloseButton className="Drawer-Close" onClick={adaptedProps.onHide} />
      {Content && <Content />}
      <DrawerHeader title={title} icon={icon}>
        {Header && <Header />}
      </DrawerHeader>
      <DrawerBody>{children}</DrawerBody>
    </BootstrapOffCanvas>
  );
};

function adaptProps(props: DrawerProps): BootstrapOffCanvasProps {
  return (Object.keys(props) as Array<keyof DrawerProps>).reduce<BootstrapOffCanvasProps>((acc, key) => {
    const adaptor = {
      placement: {
        top: 'top',
        bottom: 'bottom',
        left: 'start',
        right: 'end',
      },
    };
    acc[key] = adaptor?.[key]?.[props?.[key]] || props?.[key];
    return acc;
  }, {} as BootstrapOffCanvasProps);
}

export default Drawer;
