import { Modal as BootstrapModal } from 'react-bootstrap';
import { ModalBodyProps as BootstrapModalBodyProps } from 'react-bootstrap/esm/ModalBody';
import { ReactNode } from 'react';
import { getClasses } from '@/utils';

export type ModalBodyProps = { children?: ReactNode } & BootstrapModalBodyProps;

const ModalBody = ({ children, ...bodyProps }: ModalBodyProps): JSX.Element => {
  if (!children) return null;
  return (
    <BootstrapModal.Body {...bodyProps} className={getClasses('Modal-Body', bodyProps.className)}>
      {children}
    </BootstrapModal.Body>
  );
};

export default ModalBody;
