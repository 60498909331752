import Dropdown, { DropdownItem, DropdownProps } from '@/components/Dropdown';
import { ReactNode, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

export type NavigationDropdownProps = Omit<DropdownProps, 'onChange' | 'name' | 'value'> & {
  name?: string;
  ignore?: string[];
};

const NavigationDropdown = ({ ignore, ...props }: NavigationDropdownProps): ReactNode => {
  const navigate = useNavigate();
  const onChange = (value: string): void => navigate(value);

  useEffect((): void => {
    const isIgnored = ignore?.includes?.(window.location.pathname);
    const options = props.items.map((item: DropdownItem): string => item.value);
    if ((window.location.pathname && options.includes(window.location.pathname)) || isIgnored) return;
    navigate(props.items[0].value);
  }, [navigate, props.items]);

  return (
    <Dropdown
      name="navigationDropdown"
      {...props}
      value={window.location.pathname}
      onChange={onChange}
      options={{ showClearButton: false, ...(props?.options || {}) }}
    />
  );
};

export default NavigationDropdown;
