// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LostAndFoundHistoryForm .LostAndFoundHistoryFormHeader {
  padding-bottom: 1rem;
}
.LostAndFoundHistoryForm .LostAndFoundHistoryFormHeader > .col {
  display: flex;
  flex-direction: column;
}
.LostAndFoundHistoryForm .LostAndFoundHistoryFormHeader > .col .LostAndFoundHistoryFormTitle {
  padding: 0.25rem 0 1rem 0;
  border-bottom: 1px solid #ccc;
  text-transform: uppercase;
}
.LostAndFoundHistoryForm .LostAndFoundHistoryFormUpload {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.LostAndFoundHistoryForm .LostAndFoundHistoryFormHistory {
  padding-bottom: 1rem;
}
.LostAndFoundHistoryForm .LostAndFoundHistoryFormHistory .LostAndFoundHistoryFormHistoryTitle {
  padding: 0.25rem 0 1rem 0;
  border-bottom: 1px solid #ccc;
  text-transform: uppercase;
}
.LostAndFoundHistoryForm .TextArea[name=description],
.LostAndFoundHistoryForm .TextArea[name=notes] {
  height: 7rem !important;
  resize: none !important;
}
.LostAndFoundHistoryForm .btn {
  text-transform: uppercase;
}
.LostAndFoundHistoryForm .btn[name=SUBMIT] {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/features/LostAndFoundHistory/components/LostAndFoundHistoryForm/styles.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;AAAJ;AACI;EACE,aAAA;EACA,sBAAA;AACN;AAAM;EACE,yBAAA;EACA,6BAAA;EACA,yBAAA;AAER;AAEE;EACE,mBAAA;EACA,sBAAA;AAAJ;AAEE;EACE,oBAAA;AAAJ;AACI;EACE,yBAAA;EACA,6BAAA;EACA,yBAAA;AACN;AAEE;;EAEE,uBAAA;EACA,uBAAA;AAAJ;AAEE;EACE,yBAAA;AAAJ;AACI;EACE,WAAA;AACN","sourcesContent":[".LostAndFoundHistoryForm .LostAndFoundHistoryFormHeader {\n  padding-bottom: 1rem;\n}\n.LostAndFoundHistoryForm .LostAndFoundHistoryFormHeader > .col {\n  display: flex;\n  flex-direction: column;\n}\n.LostAndFoundHistoryForm .LostAndFoundHistoryFormHeader > .col .LostAndFoundHistoryFormTitle {\n  padding: 0.25rem 0 1rem 0;\n  border-bottom: 1px solid #ccc;\n  text-transform: uppercase;\n}\n.LostAndFoundHistoryForm .LostAndFoundHistoryFormUpload {\n  padding-top: 0.6rem;\n  padding-bottom: 0.6rem;\n}\n.LostAndFoundHistoryForm .LostAndFoundHistoryFormHistory {\n  padding-bottom: 1rem;\n}\n.LostAndFoundHistoryForm .LostAndFoundHistoryFormHistory .LostAndFoundHistoryFormHistoryTitle {\n  padding: 0.25rem 0 1rem 0;\n  border-bottom: 1px solid #ccc;\n  text-transform: uppercase;\n}\n.LostAndFoundHistoryForm .TextArea[name=description],\n.LostAndFoundHistoryForm .TextArea[name=notes] {\n  height: 7rem !important;\n  resize: none !important;\n}\n.LostAndFoundHistoryForm .btn {\n  text-transform: uppercase;\n}\n.LostAndFoundHistoryForm .btn[name=SUBMIT] {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
