import { Button, ButtonProps } from 'react-bootstrap';
import { KeyboardEvent, MouseEvent, useCallback, useContext } from 'react';

import { FormContext } from '@/common/Form';
import { useFormContext } from 'react-hook-form';

export type FormButtonProps = {
  onClick?: (values: Record<string, any>, event: React.MouseEvent | React.KeyboardEvent) => void | Promise<void>;
} & Omit<ButtonProps, 'onClick'>;
const FormButton = ({ ...buttonProps }: FormButtonProps): JSX.Element => {
  const form = useFormContext();
  const {
    validatingFields: [validatingFields],
  } = useContext(FormContext);

  const isValid = Object.entries(form.formState.errors).reduce((acc: boolean, [key, value]) => {
    if (validatingFields.has(key) || form.getValues(key)) return acc && value === undefined;
    return acc;
  }, true);

  const isDisabled = (buttonProps.type === 'submit' && !isValid) || (buttonProps.disabled !== undefined && buttonProps.disabled !== false);

  const onClick = useCallback(
    async (event: MouseEvent | KeyboardEvent): Promise<void> => {
      if (buttonProps.type !== 'submit') {
        event.preventDefault();
        event.stopPropagation();
      }
      if (buttonProps.type === 'reset') {
        await form.reset();
        await form.trigger();
      }
      if (isDisabled) return;
      const values = form.getValues();
      return buttonProps.onClick?.(values, event);
    },
    [buttonProps, form, isDisabled]
  );

  return <Button {...buttonProps} disabled={isDisabled} onClick={onClick} />;
};

export default FormButton;
