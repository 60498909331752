import './styles.scss';

import Popup, { PopupProps } from '@/common/Popup';

import LostAndFoundHistoryForm from '@/features/LostAndFoundHistory/components/LostAndFoundHistoryForm';
import { createComponentState } from '@/state';
import { getClasses } from '@/utils';
import { setFormStorage } from '@/common/Form';

export type LostAndFoundHistoryDrawerState = {
  show: boolean;
};
export const initLostAndFoundHistoryState: LostAndFoundHistoryDrawerState = {
  show: false,
};
export const useLostAndFoundHistoryDrawer = createComponentState<LostAndFoundHistoryDrawerState>(initLostAndFoundHistoryState);
export type LostAndFoundHistoryDrawerProps = { locale?: Record<string, string> } & PopupProps;
const LostAndFoundHistoryDrawer = ({ locale: customLocale, ...popupProps }: LostAndFoundHistoryDrawerProps): JSX.Element => {
  const [state, setState] = useLostAndFoundHistoryDrawer(({ state, setState }) => [state, setState]);
  const { show } = state;

  const onHide = (): void => {
    setState({ ...state, show: false });
    setFormStorage('LostAndFoundHistoryForm', () => ({}));
  };
  const onExited = (): void => setState(initLostAndFoundHistoryState);
  const onSubmit = (data): void => {
    console.log('Submit Lost and Found', data);
    onHide();
  };

  return (
    <Popup
      {...popupProps}
      className={getClasses('LostAndFoundHistoryDrawer', popupProps.className)}
      show={show}
      onHide={onHide}
      onExited={onExited}
    >
      <LostAndFoundHistoryForm onSubmit={onSubmit} />
    </Popup>
  );
};

export default LostAndFoundHistoryDrawer;
