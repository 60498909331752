import { DATETIME_FORMAT, TIME_FORMAT } from '@/constants';
import { Datetime, getClasses } from '@/utils';
import { ForwardRefExoticComponent, forwardRef, useCallback } from 'react';

import { Button } from 'react-bootstrap';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';
import Tippy from '@tippyjs/react';
import { useIncidentHistory } from '@/features/IncidentHistory';

const IncidentHistoryTableRow = ({ id }: { id: string }, ref: ForwardRefExoticComponent<HTMLDivElement>): JSX.Element => {
  const [{ incident, selected }, setState] = useIncidentHistory(({ incidents, selected }) => ({
    incident: incidents.get(id),
    selected: selected.has(id),
  }));

  const onSelect = useCallback(
    (): void =>
      setState((current) => {
        const newSelected = new Map(current.selected);
        if (newSelected.has(id)) newSelected.delete(id);
        else newSelected.set(id, current.incidents.get(id));
        return { ...current, selected: newSelected };
      }),
    [incident, id, setState]
  );
  const onEdit = useCallback((): void => {
    console.log('ON_EDIT', id);
  }, [id]);
  const onDelete = useCallback((): void => {
    console.log('ON_DELETE', id);
  }, [id]);

  return (
    <SimpleTableRow id={id} className={getClasses(selected ? 'SELECTED' : undefined)} onDoubleClick={onEdit} ref={ref}>
      <SimpleTableCell.Select checked={selected} onChange={onSelect} />
      <SimpleTableCell name="AIRPORT">{incident.airport}</SimpleTableCell>
      <SimpleTableCell name="DATETIME">{new Datetime(incident.datetime).format(DATETIME_FORMAT)}</SimpleTableCell>
      <SimpleTableCell name="AIRLINE">{incident.airline}</SimpleTableCell>
      <SimpleTableCell name="FLIGHT_NUMBER">{incident.flightNumber}</SimpleTableCell>
      <SimpleTableCell name="SCHEDULED_TIME">{new Datetime(incident.scheduledTime).format(TIME_FORMAT)}</SimpleTableCell>
      <SimpleTableCell name="VAN">{incident.van}</SimpleTableCell>
      <SimpleTableCell name="EMPLOYEE">{incident.employee}</SimpleTableCell>
      <SimpleTableCell name="ID">{incident.idNumber}</SimpleTableCell>
      <SimpleTableCell name="MANAGER">{incident.manager}</SimpleTableCell>
      <SimpleTableCell name="INJURY" style={{ color: incident.injury ? 'var(--bs-danger)' : 'var(--bs-green)' }}>
        {incident.injury ? 'Yes' : 'No'}
      </SimpleTableCell>
      <SimpleTableCell name="CREW_INVOLVED" style={{ color: incident.crewInvolved ? 'var(--bs-danger)' : 'var(--bs-green)' }}>
        {incident.crewInvolved ? 'Yes' : 'No'}
      </SimpleTableCell>
      <SimpleTableCell name="EDIT">
        <Tippy content="Edit Incident">
          <Button name="EDIT" variant="icon" onClick={onEdit}>
            <i className="sv sv-pencil" />
          </Button>
        </Tippy>
      </SimpleTableCell>
      <SimpleTableCell name="DELETE">
        <Tippy content="Delete Incident">
          <Button name="DELETE" variant="icon" onClick={onDelete}>
            <i className="sv sv-trash2" />
          </Button>
        </Tippy>
      </SimpleTableCell>
    </SimpleTableRow>
  );
};

export default forwardRef(IncidentHistoryTableRow);
