import { useCallback, useMemo } from 'react';

import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';
import { SortDirectionEnum } from '@/models/gen/graphql';
import Tippy from '@tippyjs/react';
import { useLostAndFoundHistory } from '@/features/LostAndFoundHistory';

const LostAndFoundHistoryTableColumns = (): JSX.Element => {
  return (
    <SimpleTableRow header>
      <SelectAllCell />
      <SortableCell name="AIRPORT">Airport</SortableCell>
      <SortableCell name="DATETIME">Issue Time</SortableCell>
      <SortableCell name="DISPATCHER">Dispatcher</SortableCell>
      <SortableCell name="AIRLINE">AL</SortableCell>
      <SortableCell name="FLIGHT_NUMBER">FLT #</SortableCell>
      <SortableCell name="VAN">Van</SortableCell>
      <SortableCell name="DRIVER">Driver</SortableCell>
      <SortableCell name="DESCRIPTION">Description</SortableCell>
      <SortableCell name="STATUS">Item Status</SortableCell>
      <SimpleTableCell name="EDIT">
        <Tippy content="Edit Issue">
          <i className="sv sv-pencil" />
        </Tippy>
      </SimpleTableCell>
      <SimpleTableCell name="DELETE">
        <Tippy content="Delete Issue">
          <i className="sv sv-trash2" />
        </Tippy>
      </SimpleTableCell>
    </SimpleTableRow>
  );
};

const SelectAllCell = (): JSX.Element => {
  const [{ reports, selected }, setState] = useLostAndFoundHistory(({ reports, selected }) => ({ reports, selected }));

  const isSelectAll = useMemo((): boolean => {
    if (selected.size !== reports.size) return false;
    for (const id of selected.keys()) {
      if (!reports.has(id)) return false;
    }
    return true;
  }, [reports, selected]);

  const onClick = useCallback(
    (): void => setState((current) => ({ ...current, selected: isSelectAll ? new Map() : reports })),
    [reports, isSelectAll, setState]
  );

  return <SimpleTableCell.Select checked={isSelectAll} onChange={onClick} />;
};
const SortableCell = ({ name, children }: { name: string; children: React.ReactNode }): JSX.Element => {
  const [{ sortBy, sortDirection }, setState] = useLostAndFoundHistory(({ sortBy, sortDirection }) => ({ sortBy, sortDirection }));
  const onSort = (name: string, direction: SortDirectionEnum): void =>
    setState((current) => ({ ...current, sortBy: name, sortDirection: direction }));
  return (
    <SimpleTableCell.Sortable name={name} sortBy={sortBy} sortDirection={sortDirection} onSort={onSort}>
      {children}
    </SimpleTableCell.Sortable>
  );
};

export default LostAndFoundHistoryTableColumns;
