import { GetPortalConfigDocument, LoginDocument } from '@/api/queries';
import { User, UserConfig } from '@/models/gen/graphql';

import { ResultOf } from '@graphql-typed-document-node/core';
import config from '@/config.json';
import { createPersistentComponentState } from '@/state';

// TODO: When the login issue for getting groups is resolved, combination can be removed.
export type SessionUser = ResultOf<typeof LoginDocument>['login']['user'] & { groups?: User['groups'] };

export type Config = ResultOf<typeof GetPortalConfigDocument>['getPortalConfig'];

export type SessionState = {
  user: SessionUser;
  config: Config & { userConfig?: UserConfig };
  env: 'local' | 'dev' | 'stage' | 'prod';
  allowedUrls?: string[];
};

// parse env from config
const env = Object.entries(config.env || {}).reduce(
  (acc: string, [name = '', domains = []]: [string, string[]]): string => (domains.includes(window.location.host) ? name : acc),
  'local'
) as 'local' | 'dev' | 'stage' | 'prod';

const initSessionState: SessionState = {
  user: undefined, // set on login
  config: undefined, // set on login
  env,
};

const useSession = createPersistentComponentState('session', initSessionState);

export default useSession;
