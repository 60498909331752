import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

type PortalProps = {
  children?: ReactNode;
  container?: Element;
};
const Portal = ({ children, container }: PortalProps): ReactNode => createPortal(children, container || document.body);
const PortalWhen = (props: PortalProps): ReactNode => (props?.container ? <Portal {...props} /> : props?.children);
const PortalFind = ({ children, selector }: { children?: ReactNode; selector: string } & Omit<PortalProps, 'container'>): JSX.Element => {
  const [container, setContainer] = useState(null);
  const timerRef = useRef(null);

  const findContainer = (): NodeJS.Timeout => {
    const footer = document.querySelector(selector);
    if (!footer)
      return (timerRef.current = setInterval((): void => {
        findContainer();
      }, 1000));
    setContainer(footer);
  };

  useEffect((): (() => void) => {
    findContainer();
    return (): void => clearInterval(timerRef.current);
  }, []);

  if (!container) return null;
  return <Portal container={container}>{children}</Portal>;
};

Portal.When = PortalWhen;
Portal.Find = PortalFind;

export default Portal;
export { PortalWhen, PortalFind };
