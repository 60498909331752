// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form {
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/common/Form/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;AACF","sourcesContent":[".Form {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  flex-grow: 1;\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
