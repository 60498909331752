import NavigationDropdown, { NavigationDropdownProps } from '@/components/NavigationDropdown';

import { DropdownItem } from '@/components/Dropdown';
import { ReactNode } from 'react';

export type PageNavigationDropdownProps = Omit<NavigationDropdownProps, 'items'>;

const pages = {
  'Accident History': '/accident-history',
  'Incident History': '/incident-history',
  'Dispatch Issue History': '/issue-history',
  'Lost and Found History': '/lost-and-found-history',
  'Dispatch Summary History': '/summary-history',
};

const pageNavigationDropdownItems: DropdownItem[] = Object.entries(pages).map(
  ([label, value]: [string, string]): DropdownItem => ({ label, value })
);

const PageNavigationDropdown = (props: PageNavigationDropdownProps): ReactNode => (
  <NavigationDropdown name="pageNavigationDropdownProps" {...props} items={pageNavigationDropdownItems} />
);

export default PageNavigationDropdown;
