import { CreateTripBulkInput, CreateTripInput, TripKindEnum, TripResponse, TripStatusEnum, TripTypeEnum } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils/custom';

import { CreateTripsDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import { Validation } from '@/utils';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

type CreateTripsGraphApiResponse = GraphApiResponse<typeof CreateTripsDocument>;

const title = 'Create Trips';

// Global Service Variables
export const createTripsValidator = new Validation.Validator({
  airportCode: (val: any): Validation.Validity => ({ valid: !!val }),
  attendants: (val: any): Validation.Validity => ({ valid: !Validation.isNil(val), defaultValue: 0 }),
  comments: (val: any): Validation.Validity => ({ valid: !!val }),
  'companyId!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val), defaultValue: '' }),
  completionId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  crewId: (val: any): Validation.Validity => ({
    valid: !!val && Validation.isNumber(parseInt(val)) ? true : val?.length ? false : undefined,
    defaultValue: 0,
  }),
  firstName: (val: any): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
    defaultValue: '',
  }),
  lastName: (val: any): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
    defaultValue: '',
  }),
  doActualDatetime: (val: any): Validation.Validity => ({ valid: !!val }),
  doLocationId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  driverId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  flightNumber: (val: any): Validation.Validity => ({ valid: !Validation.isNil(val) && val >= 0 && val <= 9999 }),
  fromManifest: (val: any): Validation.Validity => ({ valid: !Validation.isNil(val), defaultValue: false }),
  'kind!': (val: any): Validation.Validity => ({ valid: Object.values(TripKindEnum).includes(val), defaultValue: TripKindEnum.Arr }),
  loopName: (val: any): Validation.Validity => ({ valid: !!val, defaultValue: '' }),
  manifestImportId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  payerProviderId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  providerId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  pilots: (val: any): Validation.Validity => ({ valid: !Validation.isNil(val), defaultValue: 0 }),
  puActualDatetime: (val: any): Validation.Validity => ({ valid: !!val }),
  puCompletedDatetime: (val: any): Validation.Validity => ({ valid: !!val }),
  puLocationId: (val: any): Validation.Validity => ({ valid: !!val }),
  scheduled: (val: any): Validation.Validity => ({ valid: !!val, defaultValue: '' }),
  servicerIataAirlineCode: (val: any): Validation.Validity => ({ valid: !!val }),
  'split!': (val: any): Validation.Validity => ({ valid: !Validation.isNil(val), defaultValue: 0 }),
  'status!': (val: any): Validation.Validity => ({
    valid: Object.values(TripStatusEnum).includes(val),
    defaultValue: TripStatusEnum.Cancelled,
  }),
  traceId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  trackFlightId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  'type!': (val: any): Validation.Validity => ({ valid: Object.values(TripTypeEnum).includes(val), defaultValue: TripTypeEnum.Sch }),
  vehicleId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  doAirportCode: (val: any): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
    defaultValue: '',
  }),
});

const createTripsResponseSelector = (res: CreateTripsGraphApiResponse): TripResponse[] =>
  !res.errors ? (res?.createTrips?.response as TripResponse[]) : undefined;

const [runCreateTrips] = graphApi(CreateTripsDocument, {
  onError: (res: CreateTripsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createTrips = async (trips: CreateTripInput[]): Promise<TripResponse[]> => {
  const values: CreateTripInput[] = trips.map((trip: CreateTripInput): CreateTripInput => createTripsValidator.create(trip));
  const input: CreateTripBulkInput = { values };

  const res = await runCreateTrips({ input });
  const selected = createTripsResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateTrips = createGraphApiHook(createTrips);

export default createTrips;
