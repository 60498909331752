// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditAirlinesModal .EditAirlinesModal-Emails {
  overflow-x: hidden;
  max-height: 180px;
  padding-top: 1.2rem;
}
.EditAirlinesModal .EditAirlinesModal-Emails .FormField,
.EditAirlinesModal .EditAirlinesModal-Emails + .EditAirlinesModal-EmailInput .FormField {
  margin: 0.5rem 0;
}
.EditAirlinesModal .Emails-Header {
  border-bottom: 1px solid grey;
  padding: 0.7rem 0;
}`, "",{"version":3,"sources":["webpack://./src/components/EditAirlinesModal/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AAGM;;EACE,gBAAA;AAAR;AAIE;EACE,6BAAA;EACA,iBAAA;AAFJ","sourcesContent":[".EditAirlinesModal .EditAirlinesModal-Emails {\n  overflow-x: hidden;\n  max-height: 180px;\n  padding-top: 1.2rem;\n}\n.EditAirlinesModal .EditAirlinesModal-Emails .FormField,\n.EditAirlinesModal .EditAirlinesModal-Emails + .EditAirlinesModal-EmailInput .FormField {\n  margin: 0.5rem 0;\n}\n.EditAirlinesModal .Emails-Header {\n  border-bottom: 1px solid grey;\n  padding: 0.7rem 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
