import { ChangeEvent, ReactNode, Ref, TextareaHTMLAttributes, forwardRef } from 'react';

import { fieldWrapper } from '@/components/Field';
import { getClasses } from '@/utils';

type TextAreaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  onChange?: (value: string) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  isDirty?: boolean;
  options?: {
    disableResize?: boolean;
  };
};

const TextAreaWithRef = (
  { onChange, isValid, isInvalid, isDirty, ...textareaProps }: TextAreaProps,
  ref: Ref<HTMLTextAreaElement>
): ReactNode => (
  <textarea
    ref={ref}
    {...textareaProps}
    style={{ ...textareaProps?.style, resize: (textareaProps?.style?.resize ?? textareaProps?.options?.disableResize) ? 'none' : 'both' }}
    className={getClasses(
      'TextArea',
      'form-control',
      isValid ? 'is-valid' : isInvalid ? 'is-invalid' : undefined,
      isDirty ? 'is-dirty' : undefined,
      textareaProps?.className
    )}
    onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => onChange?.(event.target.value)}
  />
);

const TextArea = forwardRef(TextAreaWithRef);

export const TextAreaField = fieldWrapper<TextAreaProps>(TextArea);

export default TextArea;
