import { GetPortalConfigDocument } from '@/api/queries';
import { LoginInput } from '@/models/gen/graphql';
import { VariablesOf } from '@graphql-typed-document-node/core';
import { createGraphApiMockResponse } from '@/api/core';

export const getPortalConfig = createGraphApiMockResponse(
  GetPortalConfigDocument,
  {
    getPortalConfig: {
      genericLineItemTypeId: '9bd8f6c7-ea4e-47f4-a065-d0c4023d2a79',
      indexDBVersion: 1,
      driverRoleId: '9bd8f6c7-ea4e-47f4-a065-d0c4023d2a79',
      upsProviderId: '9bd8f6c7-ea4e-47f4-a065-d0c4023d2a79',
      supportedManifestFileTypes: ['pdf', 'xlsx', 'html', 'csv'],
      requestAvailabilityIntervalSeconds: 300,
      highlightApiKey: 'odz6zx3e',
      auth: {
        allowCreateClient: true,
        allowTripStatusInvoiceFilter: true,
        allowInvoiceDuplicate: true,
        allowSearchTripsByRate: true,
        allowToViewMap: true,
        allowRecordSession: true,
        allowCustomizeReports: true,
        allowOverrideRate: true,
        allowViewBufferTime: true,
        allowViewCombineRule: true,
        allowViewVariableTime: true,
        allowAssignAndAutoCombine: false,
        allowGetDriverEstimatedDriveTime: false,
        allowViewChat: true,
        allowHideChat: false,
        allowViewTripShortcuts: false,
        allowUndeleteTrip: false,
        allowDeleteTripHard: false,
        allowSaveAndCopyExistingTrips: true,
      },
    },
  },
  {
    variables: (opts: VariablesOf<LoginInput>): boolean => !!opts,
  }
);
