import { useCallback, useMemo } from 'react';

import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';
import { SortDirectionEnum } from '@/models/gen/graphql';
import Tippy from '@tippyjs/react';
import { useAccidentHistory } from '@/features/AccidentHistory';

const AccidentHistoryTableColumns = (): JSX.Element => (
  <SimpleTableRow header>
    <SelectAllCell />
    <SortableCell name="AIRPORT">Airport</SortableCell>
    <SortableCell name="DATETIME">Date / Time</SortableCell>
    <SortableCell name="AIRLINE">AL</SortableCell>
    <SortableCell name="FLIGHT_NUMBER">FLT #</SortableCell>
    <SortableCell name="SCHEDULED_TIME">Sch Time</SortableCell>
    <SortableCell name="VAN">Van</SortableCell>
    <SortableCell name="EMPLOYEE">Employee</SortableCell>
    <SortableCell name="ID">ID #</SortableCell>
    <SortableCell name="MANAGER">Manager</SortableCell>
    <SortableCell name="INJURY">Injury?</SortableCell>
    <SortableCell name="CREW_INVOLVED">Crew Involved?</SortableCell>
    <SimpleTableCell name="EDIT">
      <Tippy content="Edit Accident">
        <i className="sv sv-pencil" />
      </Tippy>
    </SimpleTableCell>
    <SimpleTableCell name="DELETE">
      <Tippy content="Delete Accident">
        <i className="sv sv-trash2" />
      </Tippy>
    </SimpleTableCell>
  </SimpleTableRow>
);

const SelectAllCell = (): JSX.Element => {
  const [{ accidents, selected }, setState] = useAccidentHistory(({ accidents, selected }) => ({ accidents, selected }));

  const isSelectAll = useMemo((): boolean => {
    if (selected.size !== accidents.size) return false;
    for (const id of selected.keys()) {
      if (!accidents.has(id)) return false;
    }
    return true;
  }, [accidents, selected]);

  const onClick = useCallback(
    (): void => setState((current) => ({ ...current, selected: isSelectAll ? new Map() : accidents })),
    [accidents, isSelectAll, setState]
  );

  return <SimpleTableCell.Select checked={isSelectAll} onChange={onClick} />;
};

const SortableCell = ({ name, children }: { name: string; children: React.ReactNode }): JSX.Element => {
  const [{ sortBy, sortDirection }, setState] = useAccidentHistory(({ sortBy, sortDirection }) => ({ sortBy, sortDirection }));
  const onSort = (name: string, direction: SortDirectionEnum): void =>
    setState((current) => ({ ...current, sortBy: name, sortDirection: direction }));
  return (
    <SimpleTableCell.Sortable name={name} sortBy={sortBy} sortDirection={sortDirection} onSort={onSort}>
      {children}
    </SimpleTableCell.Sortable>
  );
};

export default AccidentHistoryTableColumns;
