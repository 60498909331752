import { ReactNode, createContext, useCallback, useEffect, useRef } from 'react';
import { getCookies, parseQueryString, sleep } from '@/utils';

import { generatePayload } from './utils';
import io from 'socket.io-client';
import useChatMethods from './chat/useChatMethods';
import { useSelectedRoomState } from './chat/useChatRooms';

const reservedEvents = ['connecting', 'connect', 'connect_error', 'disconnect', 'ping', 'pong'];
const maxRetries = 10;
const ASYNC_EMIT_TIMEOUT = 3000;

export type SocketErrorDetails = {
  type: string;
  payload: Record<string, unknown>;
  traceId: string;
};
export class SocketError extends Error {
  type: string;
  payload: Record<string, unknown>;
  traceId: string;
  constructor(message: string, details: SocketErrorDetails) {
    super(message);
    this.name = 'SocketError';
    this.type = details.type;
    this.payload = details.payload;
    this.traceId = details.traceId;
  }
}
export const socket = (() => {
  const requestQueue = [];
  const inFlightRequests = new Set();
  let failedRequests = new Set();
  let queueTimer = null;
  const token = getCookies('token')?.token || parseQueryString()?.token || '';
  const connection = io(window['__config__']['chat-api-url'], {
    transports: ['websocket'],
    withCredentials: true,
    autoConnect: false,
    reconnection: false,
    query: {
      Authorization: `Bearer ${token}`,
      Id: '',
      Platform: 'portal',
    },
  });
  const resolveRequest = async (
    queueType: string,
    queuePayload: unknown,
    queueOverrides: unknown,
    queueTimeout: number
  ): Promise<unknown> => {
    await sleep(1000);
    return await connection.emit.apply(connection, [queueType, queuePayload, queueOverrides, queueTimeout]);
  };
  connection._emit = connection?.emit;
  connection.emit = (
    eventType: string,
    payload: Record<string, unknown> = {},
    overrides: Record<string, unknown> = {},
    timeout: number = ASYNC_EMIT_TIMEOUT
  ): Promise<unknown> =>
    new Promise(async (resolve: (value: unknown) => void, reject: (reason?: unknown) => void): Promise<unknown> => {
      try {
        // Get event type, and async event type from formatted string: '<event>:<asyncEvent>'.
        const [type, responseType] = eventType.split(':');
        // Use built-in emit method for built-in socket events.
        if (reservedEvents.includes(type)) return connection._emit.apply(connection, [type, payload]);
        // If the connection is not connected, add the event to the request queue.
        if (!connection.connected) return await resolveRequest(eventType, payload, overrides, timeout);
        // Generate payload and stringified payload from the provided payload and overrides.
        const wrappedPayload = generatePayload(type, payload as Record<string, unknown>, overrides);
        const stringifiedPayload = JSON.stringify(wrappedPayload);
        const requestId = `${type}:${wrappedPayload.id}`;
        // Handle the request with a promise if it has an async event type.
        if (responseType) {
          // Define the response handler and timeout handler for the request.
          let timer;
          const handleResponse = (response) => {
            // Parse the response and check if the trace_id matches the request id.
            response = JSON.parse(response);
            if (response.trace_id !== wrappedPayload.id) return;
            // If the trace_id matches the request id remove the response listener and timeout.
            connection.off(responseType, handleResponse);
            clearTimeout(timer);
            // Remove the event from the in-flight and failed requests.
            inFlightRequests.delete(requestId);
            failedRequests.clear();
            // Update the connection state and resolve the promise with the response.
            connection.working = inFlightRequests.size > 0;
            connection.warning = failedRequests.size > 0;
            resolve(response);
          };
          const handleTimeout = () => {
            // Remove the response listener and timeout for the request.
            connection.off(responseType, handleResponse);
            clearTimeout(timer);
            // Create and log an error for the timed out request.
            // Remove the event from the in-flight requests and add it to the failed requests.
            inFlightRequests.delete(requestId);
            failedRequests.add(eventType);
            // Update the connection state.
            connection.working = inFlightRequests.size > 0;
            connection.warning = failedRequests.size > 0;
            reject(
              new SocketError(`"${responseType}" Request timed out. (${wrappedPayload.id})`, {
                type: responseType,
                payload: wrappedPayload,
                traceId: wrappedPayload.id as string,
              })
            );
          };
          // Add a response listener and a timeout to the connection for this request.
          connection.on(responseType, handleResponse);
          timer = setTimeout(handleTimeout, timeout);
          // Add the event to the in-flight requests and update the connection state.
          inFlightRequests.add(requestId);
          connection.working = inFlightRequests.size > 0;
        }
        // Emit the event with the stringified payload.
        connection._emit.apply(socket, [type, stringifiedPayload]);
        // Resolve the promise immediately if the event does not have an async event type.
        // Give the promise a reference to the socket connection for use in the response handler.
        if (!responseType) resolve(undefined);
      } catch (err) {
        reject(err);
      }
    });
  return connection;
})();

const SocketContext = createContext(socket);
const SocketProvider = ({ children }: { children: ReactNode }): ReactNode => {
  const retries = useRef<number>(0);
  const retryTimerRef = useRef(null);
  const { getHistory } = useChatMethods();
  const [, setSelectedRoom] = useSelectedRoomState(({ state: { room }, setState }) => [room, setState]);

  const attemptReconnect = useCallback((message: string): void => {
    console.error(message);
    if (retries.current < maxRetries) {
      retryTimerRef.current = setTimeout(
        () => {
          console.log('Attempting to reconnect to chat server.');
          socket.connect();
        },
        Math.min(5000 * 1.25 ** retries.current, 30000)
      );
      retries.current += 1;
    } else {
      console.error('Failed to reconnect to chat server after 10 attempts.');
    }
  }, []);

  useEffect((): (() => void) => {
    socket.on('connect', (): void => {
      console.log('Connected to server.');
      setSelectedRoom((current) => {
        const selectedRoom = current.room;
        if (selectedRoom) {
          getHistory({
            rooms: [selectedRoom?.id],
            limit: 100,
            offset: 0,
          });
        }
        return current;
      });
    });
    socket.on('disconnect', (reason: string): void => {
      attemptReconnect(`Disconnected from server: ${reason}`);
    });
    socket.on('connect_error', (err: Error): void => {
      attemptReconnect(`Connection error: ${err.message}`);
    });

    return (): void => {
      clearTimeout(retryTimerRef.current);
      socket.off('disconnect');
      socket.disconnect();
      retries.current = 0;
    };
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export { SocketContext, SocketProvider };
