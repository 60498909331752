import React, { ErrorInfo, ReactNode } from 'react';

import DefaultError from './DefaultError';

export type CatchErrorProps = {
  fallback?: ((props: { error: Error }) => ReactNode | JSX.Element) | false;
  onError?: (err: Error, info: ErrorInfo) => Promise<void>;
  children?: ReactNode;
};
export type CatchErrorState = { hasError: boolean; error?: Error; info?: ErrorInfo };

class CatchError extends React.Component<CatchErrorProps, CatchErrorState> {
  public state: CatchErrorState = { hasError: false, error: undefined };
  public static getDerivedStateFromError(err): CatchErrorState {
    return { hasError: true, error: err };
  }
  public componentDidCatch(error: Error, info: ErrorInfo): void {
    this?.props?.onError?.(error, info);
  }
  public render(): any {
    //console.log('hasError', this?.state?.hasError);
    if (this?.state?.hasError && this?.props?.fallback !== false) {
      const Fallback = this?.props?.fallback || DefaultError;
      return <Fallback error={this?.state?.error} />;
    }

    return this?.props?.children;
  }
}

export default CatchError;
