// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal .Modal-Dialog .Modal-Content {
  container-name: modal;
  container-type: inline-size;
}
.Modal .Modal-Dialog .Modal-Content .Modal-Close {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  color: rgba(0, 0, 0, 0.5);
}
.Modal .Modal-Dialog .Modal-Content .Modal-Header {
  padding: 0 0 0.75rem 0;
  border-bottom: 1px solid var(--bs-primary) !important;
}
.Modal .Modal-Dialog .Modal-Content .Modal-Header .Modal-Title {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: start;
  align-items: center;
  font-size: 1.66rem;
  font-weight: 400;
  line-height: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.17rem;
}
.Modal .Modal-Dialog .Modal-Content .Modal-Header .Modal-Title > i {
  font-size: 2rem;
}
.Modal .Modal-Dialog .Modal-Content .Modal-Body {
  padding: 1rem 0;
}
.Modal .Modal-Dialog .Modal-Content .Modal-Body > *:last-child {
  margin-bottom: 0;
}
.Modal .Modal-Dialog .Modal-Content .Modal-Footer {
  padding: 0.75rem 0 0 0;
}`, "",{"version":3,"sources":["webpack://./src/common/Modal/styles.scss"],"names":[],"mappings":"AAEI;EACE,qBAAA;EACA,2BAAA;AADN;AAEM;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,yBAAA;AAAR;AAEM;EACE,sBAAA;EACA,qDAAA;AAAR;AACQ;EACE,aAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;EAIA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,uBAAA;AAFV;AALU;EACE,eAAA;AAOZ;AAEM;EACE,eAAA;AAAR;AACQ;EACE,gBAAA;AACV;AAEM;EACE,sBAAA;AAAR","sourcesContent":[".Modal .Modal-Dialog .Modal-Content {\n  container-name: modal;\n  container-type: inline-size;\n}\n.Modal .Modal-Dialog .Modal-Content .Modal-Close {\n  position: absolute;\n  top: 0.25rem;\n  right: 0.25rem;\n  color: rgba(0, 0, 0, 0.5);\n}\n.Modal .Modal-Dialog .Modal-Content .Modal-Header {\n  padding: 0 0 0.75rem 0;\n  border-bottom: 1px solid var(--bs-primary) !important;\n}\n.Modal .Modal-Dialog .Modal-Content .Modal-Header .Modal-Title {\n  display: flex;\n  gap: 1rem;\n  width: 100%;\n  justify-content: start;\n  align-items: center;\n  font-size: 1.66rem;\n  font-weight: 400;\n  line-height: 1.2rem;\n  text-transform: uppercase;\n  letter-spacing: 0.17rem;\n}\n.Modal .Modal-Dialog .Modal-Content .Modal-Header .Modal-Title > i {\n  font-size: 2rem;\n}\n.Modal .Modal-Dialog .Modal-Content .Modal-Body {\n  padding: 1rem 0;\n}\n.Modal .Modal-Dialog .Modal-Content .Modal-Body > *:last-child {\n  margin-bottom: 0;\n}\n.Modal .Modal-Dialog .Modal-Content .Modal-Footer {\n  padding: 0.75rem 0 0 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
