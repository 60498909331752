import './styles.scss';

import { Col, Row } from 'react-bootstrap';

import { CurrencyInputField } from '@/components/CurrencyInput';
import { DatetimeInputField } from '@/components/DatetimeInput';
import Form from '@/common/Form';
import GenericFileUpload from '@/components/FileUpload/new';
import { InputField } from '@/components/Input';
import { LostAndFoundHistoryFormDefinitions } from '@/features/LostAndFoundHistory/components/LostAndFoundHistoryForm/types';
import { PhoneInputField } from '@/components/MaskedInput/PhoneInput';
import { ReturnStatusDropdownField } from '@/features/LostAndFoundHistory/components/LostAndFoundHistoryForm/ReturnStatusDropdown';
import { StateDropdownField } from '@/common/StateDropdown';
import { TextAreaField } from '@/components/TextArea';
import { UseFormReturn } from 'react-hook-form';
import { UserDropdownField } from '@/components/UserDropdown';
import { forwardRef } from 'react';
import useLocale from '@/hooks/useLocale';

const LostAndFoundHistoryForm = forwardRef<UseFormReturn<any>, LostAndFoundHistoryFormDefinitions.Props>(
  ({ onSubmit, locale: customLocale, value = {} }: LostAndFoundHistoryFormDefinitions.Props, ref): JSX.Element => {
    const locale = useLocale({
      ...LostAndFoundHistoryFormDefinitions.getLocale(value),
      ...customLocale,
    });

    const handleSubmit = async (data) => {
      data = await onSubmit?.(data);
      // await Call BE and submit the data.
    };

    return (
      <Form
        name="LostAndFoundHistoryForm"
        className="LostAndFoundHistoryForm"
        onSubmit={handleSubmit}
        value={{ ...LostAndFoundHistoryFormDefinitions.initValue, ...value }}
        validator={LostAndFoundHistoryFormDefinitions.validator(locale)}
        ref={ref}
      >
        <Row className="LostAndFoundHistoryFormHeader">
          <Col>
            {value.tripId && (
              <>
                <span>
                  {value.outboundFlightNumber} {value.outboundDatetime}
                </span>
                <span>
                  {value.tripAirportCode} - {[value.driverFirstName, value.driverLastName].join(' ')} - #4321
                </span>
              </>
            )}
            <h4 className="LostAndFoundHistoryFormTitle">{locale('Lost and Found')}</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Input as={TextAreaField} name="description" label={locale('Description of Item')} validate />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Input as={UserDropdownField} name="dispatcherId" label={locale('Dispatcher')} validate />
          </Col>
          <Col xs={6}>
            <Form.Input as={InputField} name="itemLost" label={locale('Item Lost')} validate />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Input as={UserDropdownField} name="driverId" label={locale('Driver Name')} validate />
          </Col>
          <Col xs={6}>
            <Form.Input as={InputField} name="vehicleId" label={locale('Van Number')} validate />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Input as={InputField} name="outboundFlightNumber" label={locale('Outbound FLT #')} validate />
          </Col>
          <Col xs={6}>
            <Form.Input as={DatetimeInputField} name="outboundDatetime" label={locale('Outbound Date/Time')} validate />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Input as={InputField} name="crewMemberName" label={locale('Crew Member Name')} validate />
          </Col>
          <Col xs={6}>
            <Form.Input as={PhoneInputField} name="crewMemberPhone" label={locale('Crew Member Phone')} validate />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Input as={InputField} name="shippingAddress" label={locale('Shipping Address')} />
          </Col>
          <Col xs={6}>
            <Form.Input as={StateDropdownField} name="shippingStateCode" label={locale('State')} />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Input as={InputField} name="city" label={locale('City')} />
          </Col>
          <Col xs={6}>
            <Form.Input as={InputField} name="shippingZipCode" label={locale('Zip Code')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Input as={InputField} name="crewMemberEmail" label={locale('Crew Member Email')} />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Input as={ReturnStatusDropdownField} name="returnStatus" label={locale('Return Status')} validate />
          </Col>
          <Col xs={6}>
            <Form.Input as={InputField} name="trackingNumber" label={locale('Tracking Number')} />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Input as={InputField} name="shippingMethod" label={locale('Shipping Method')} />
          </Col>
          <Col xs={6}>
            <Form.Input as={CurrencyInputField} name="shippingCost" label={locale('Shipping Cost')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Input as={TextAreaField} name="notes" label={locale('Notes')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Button type="submit" name="SUBMIT" variant="success">
              {locale('SUBMIT_BUTTON_TEXT')}
            </Form.Button>
          </Col>
        </Row>
        <Row className="LostAndFoundHistoryFormUpload">
          <Col>
            <GenericFileUpload
              endpoint={async (file) => {
                const res = ''; // TODO: Create service and call it to get the upload URL.
                return res;
              }}
              options={{
                showFiles: true,
                multiple: true,
              }}
              locale={{
                Upload: locale('Upload Files'),
              }}
              onError={async (...args) => console.log('', ...args)}
              onSuccess={async (...args) => console.log('', ...args)}
            />
          </Col>
        </Row>
        {/* <Row className="LostAndFoundHistoryFormHistory">
        <Col>
          <h4 className="LostAndFoundHistoryFormHistoryTitle">{locale('Lost and Found History')}</h4>
        </Col>
      </Row> */}
      </Form>
    );
  }
);
LostAndFoundHistoryForm.displayName = 'LostAndFoundHistoryForm';

export default LostAndFoundHistoryForm;
