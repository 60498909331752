// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccidentHistoryDrawer .FileUpload .drag-and-drop.border-primary {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/features/AccidentHistory/components/AccidentHistoryDrawer/styles.scss"],"names":[],"mappings":"AAEI;EACE,uFAAA;AADN","sourcesContent":[".AccidentHistoryDrawer .FileUpload .drag-and-drop.border-primary {\n  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
