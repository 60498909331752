import Provider, { useProvider } from '@/common/Provider';
import React, { Dispatch, SetStateAction, useMemo, useRef } from 'react';

import { InvoiceFormatEnum } from '@/models/gen/graphql';
import InvoicePreviewRow from '@/pages/InvoicePreview/components/InvoicePreviewRow';
import { PreviewTrip } from '@/pages/InvoicePreview';
import SimpleTable from '@/components/SimpleTable';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';

type InvoicePreviewTableProps = {
  data?: PreviewTrip[];
  format?: InvoiceFormatEnum;
  payerProviderId?: string;
  onRefetch?: () => Promise<void>;
};
export type InvoicePreviewTableProvider = {
  trips: Map<string, PreviewTrip>;
  format?: InvoiceFormatEnum;
  payerProviderId?: string;
  onRefetch?: () => Promise<void>;
};
export type InvoicePreviewTableMethods = { onRefetch: () => Promise<void> };
export const useInvoicePreviewTable = <S,>(
  selector
): [S, Dispatch<SetStateAction<InvoicePreviewTableProvider>>, InvoicePreviewTableMethods] =>
  useProvider<InvoicePreviewTableProvider, S, InvoicePreviewTableMethods>(selector);

const InvoicePreviewTable = ({ data, format, payerProviderId, onRefetch }: InvoicePreviewTableProps): JSX.Element => {
  const tableRef = useRef();

  const [tripIds, contextValue] = useMemo((): [string[], InvoicePreviewTableProvider] => {
    const trips = new Map();
    const tripIds = data
      .map((row: PreviewTrip): string => {
        if (row?.id) trips.set(row?.id, row);
        return row?.id;
      })
      .filter((id: string): boolean => !!id);
    return [tripIds, { trips, format, payerProviderId }];
  }, [data, format, payerProviderId]);

  return (
    <Provider value={contextValue} methods={() => ({ onRefetch })}>
      <SimpleTable rows={tripIds} columns={InvoicePreviewTableHeader} row={InvoicePreviewRow} ref={tableRef} />
    </Provider>
  );
};

const InvoicePreviewTableHeader = (): JSX.Element => {
  const [format] = useProvider((state: InvoicePreviewTableProvider): InvoiceFormatEnum => state.format);
  return (
    <SimpleTableRow header>
      <SimpleTableCell name="INDEX">No.</SimpleTableCell>
      <SimpleTableCell name="TYPE">Type</SimpleTableCell>
      <SimpleTableCell name="SCHEDULED_DATE">Date</SimpleTableCell>
      <SimpleTableCell name="SCHEDULED_TIME">Time</SimpleTableCell>
      <SimpleTableCell name="AIRPORT_CODE">City</SimpleTableCell>
      <SimpleTableCell name="SERVICER_IATA_AIRLINE_CODE">Airline</SimpleTableCell>
      <SimpleTableCell name="FLIGHT_NUMBER">FLT</SimpleTableCell>
      {format === InvoiceFormatEnum.Crew && <SimpleTableCell name="CREW_ID">Crew ID</SimpleTableCell>}
      <SimpleTableCell name="PILOTS">PLT</SimpleTableCell>
      <SimpleTableCell name="ATTENDANTS">FA</SimpleTableCell>
      <SimpleTableCell name="PU_LOCATION">P/U</SimpleTableCell>
      <SimpleTableCell name="DO_LOCATION">D/O</SimpleTableCell>
      <SimpleTableCell name="RATE">Rate</SimpleTableCell>
    </SimpleTableRow>
  );
};

export default React.memo(InvoicePreviewTable);
