import { getClasses } from '@/utils';
import { ReactNode } from 'react';
import { Offcanvas as BootstrapOffCanvas, OffcanvasBodyProps as BootstrapOffCanvasBodyProps } from 'react-bootstrap';

export type DrawerBodyProps = { children?: ReactNode } & BootstrapOffCanvasBodyProps;

const DrawerBody = ({ children, ...bodyProps }: DrawerBodyProps) => {
  // If no children, then show nothing
  if (!children) return null;
  return (
    <BootstrapOffCanvas.Body {...bodyProps} className={getClasses('Drawer-Body', bodyProps.className)}>
      {children}
    </BootstrapOffCanvas.Body>
  );
};

export default DrawerBody;
