import { ListGroup, ListGroupProps } from 'react-bootstrap';

import { Config } from '@/state/session';
import DrawerHeader from '@/common/Drawer/DrawerHeader';
import { getClasses } from '@/utils';
import { useRoutePermission } from '@/components/Header/NavigationDrawer/NavigationDrawerItem';

export type NavigationDrawerItemGroupProps = {
  title?: string;
  icon?: JSX.Element;
  permissions?: (keyof Config['auth'])[];
  restricted?: boolean;
} & ListGroupProps;

const NavigationDrawerItemGroup = ({
  title,
  icon,
  permissions,
  restricted,
  children,
  ...listGroupProps
}: NavigationDrawerItemGroupProps): JSX.Element => {
  const hasPermission = useRoutePermission(undefined, permissions, restricted);
  if (!hasPermission || !children) return null;
  return (
    <>
      {(title || icon) && <DrawerHeader title={title} icon={icon} />}
      <ListGroup {...listGroupProps} className={getClasses('NavigationDrawer-ItemGroup', listGroupProps.className)}>
        {children}
      </ListGroup>
    </>
  );
};

export default NavigationDrawerItemGroup;
