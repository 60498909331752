import './styles.scss';

import { Drawer, DrawerProps, Modal, ModalProps } from 'antd';
import React, { HTMLAttributes, ReactNode, useState } from 'react';
import { getClasses, properCase } from '@/utils';
import useSettings, { SettingsStatePage } from '@/state/settings';

import ActionsDropdown from '@/components/ActionsDropdown';
import { Button } from 'react-bootstrap';
import TippyWhen from '@/components/TippyWhen';

// NOTE: Adding new types requires the corresponding optional boolean to be added to DynamicContentProps Types.
export const DynamicContentAs = ['inline', 'modal', 'drawer'] as const;
export type DynamicContentAs = (typeof DynamicContentAs)[number];
export type DynamicContentProps = {
  // START GENERIC PROPS
  className?: string;
  children?: ReactNode;
  as?: DynamicContentAs;
  // END GENERIC PROPS
  // START TYPES
  inline?: boolean;
  modal?: boolean;
  drawer?: boolean;
  // END TYPES
  // START TYPE PROPS
  // Modal/Drawer Props
  show?: boolean;
  onHide?: () => void;
  // Drawer Props
  placement?: 'top' | 'bottom' | 'left' | 'right';
  title?: ReactNode;
  // END TYPE PROPS
  options?: {
    modal?: { props?: DynamicModalProps };
    drawer?: { props?: DynamicDrawerProps };
    inline?: { props?: HTMLAttributes<HTMLDivElement> };
  };
  onChangeAs?: (as: DynamicContentAs) => void;
};

const DynamicContent = (props: DynamicContentProps): ReactNode => {
  const { options } = props;
  const inlineProps = options?.inline?.props || {};
  const modalProps = options?.modal?.props || {};
  const drawerProps = options?.drawer?.props || {};

  const typesAs = DynamicContentAs.map((a: DynamicContentAs): DynamicContentAs => a);
  const disableAs = typesAs.filter((a: DynamicContentAs): boolean => props[a] === false);
  const enabledAs = typesAs.filter((a: DynamicContentAs): boolean => props[a] !== undefined && disableAs.includes(a) === false);
  const [as, setAs] = useState<DynamicContentAs>('drawer');
  const isShown = !!props?.show;

  const handleHide = (): void => {
    props?.onHide?.();
  };

  const controls = (
    <ul className="DynamicContent-Controls d-flex align-items-center">
      {props?.onHide && ['modal', 'drawer'].includes(as) && (
        <li>
          <Button variant="icon" onClick={handleHide}>
            <i className="fa fa-times" />
          </Button>
        </li>
      )}
      {enabledAs.length > 1 && (
        <li>
          <ActionsDropdown
            onClick={(key: DynamicContentAs) => {
              if (key === as) return;
              setAs(key);
            }}
            value={as}
            items={[
              {
                key: 'view',
                label: 'View',
                children: enabledAs.map((key: DynamicContentAs): { key: DynamicContentAs; label: string } => ({
                  key,
                  label: properCase(key),
                })),
              },
            ]}
          />
        </li>
      )}
    </ul>
  );

  switch (as) {
    case 'modal':
      return (
        <DynamicContentModal {...modalProps} className={props?.className} open={isShown} onCancel={handleHide} controls={controls}>
          {props?.children}
        </DynamicContentModal>
      );
    case 'drawer':
      return (
        <DynamicContentDrawer
          {...drawerProps}
          className={props?.className}
          placement={props?.placement}
          open={isShown}
          onClose={handleHide}
          title={props?.title}
          controls={controls}
        >
          {props?.children}
        </DynamicContentDrawer>
      );
    case 'inline':
    default:
      return (
        <DynamicContentInline {...inlineProps} className={props?.className} controls={controls}>
          {props?.children}
        </DynamicContentInline>
      );
  }
};

type DynamicModalProps = { controls?: ReactNode; tabs?: DynamicDrawerTab[]; modalType?: string } & ModalProps;
const DynamicContentModal = ({ controls, children, tabs, modalType, ...props }: DynamicModalProps): ReactNode => (
  <Modal
    {...props}
    className={getClasses('DynamicContent DynamicContent-Modal', props?.className, modalType)}
    closeIcon={null}
    footer={null}
    destroyOnClose
  >
    {controls}
    {!!tabs?.length && (
      <div className="DynamicContent-ModalTabs">
        {tabs.map(({ icon, tooltip, onClick, active }: DynamicDrawerTab, index: number) => (
          <TippyWhen key={index} isTrue={!!tooltip} options={{ content: tooltip, placement: 'left', duration: 0 }}>
            <Button onClick={onClick} className={active ? 'isActive' : ''}>
              {icon}
            </Button>
          </TippyWhen>
        ))}
      </div>
    )}
    {children}
  </Modal>
);

type DynamicDrawerTab = { icon: ReactNode; tooltip: string; onClick?: () => void; active?: boolean };
type DynamicDrawerProps = { controls?: ReactNode; tabs?: DynamicDrawerTab[]; children?: ReactNode; className?: string } & DrawerProps;
const DynamicContentDrawer = ({ controls, children, tabs, ...props }: DynamicDrawerProps): ReactNode => (
  <Drawer {...props} className={getClasses('DynamicContent DynamicContent-Drawer', props?.className)} destroyOnClose>
    {controls}
    {!!tabs?.length && (
      <div className="DynamicContent-DrawerTabs">
        {tabs.map(({ icon, tooltip, onClick, active }: DynamicDrawerTab, index: number) => (
          <TippyWhen key={index} isTrue={!!tooltip} options={{ content: tooltip, placement: 'left', duration: 0 }}>
            <Button onClick={onClick} className={active ? 'isActive' : ''}>
              {icon}
            </Button>
          </TippyWhen>
        ))}
      </div>
    )}
    {children}
  </Drawer>
);

type DynamicInlineProps = { controls: ReactNode } & HTMLAttributes<HTMLDivElement>;
const DynamicContentInline = ({ controls, children, ...props }: DynamicInlineProps): ReactNode => (
  <div {...props} className={getClasses('DynamicContent DynamicContent-Inline', props?.className)}>
    {controls}
    {children}
  </div>
);
type DynamicContentTitleProps = { className?: string; icon?: ReactNode; children?: ReactNode };
const DynamicContentTitle = ({ className, ...props }: DynamicContentTitleProps) => {
  return (
    <h3 className={getClasses('DynamicContent-Title', className)}>
      {props?.icon && props.icon}
      {props?.children}
    </h3>
  );
};

DynamicContent.Modal = DynamicContentModal;
DynamicContent.Drawer = DynamicContentDrawer;
DynamicContent.Inline = DynamicContentInline;
DynamicContent.Title = DynamicContentTitle;
export default DynamicContent;
