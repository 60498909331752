import { ChangeEventHandler, ComponentProps, HTMLAttributes, MouseEventHandler, useCallback, useMemo } from 'react';

import { Button } from 'react-bootstrap';
import { SortDirectionEnum } from '@/models/gen/graphql';
import TippyWhen from '@/components/TippyWhen';
import { getClasses } from '@/utils';
import { useAccidentHistory } from '@/features/AccidentHistory';

export type SimpleTableCellProps = { name: string } & HTMLAttributes<HTMLDivElement>;

const SimpleTableCell = ({ name, ...divProps }: SimpleTableCellProps): JSX.Element => (
  <div {...divProps} className={getClasses('SimpleTable-Cell', name, divProps?.className)} />
);

export type SimpleTableCellSortableProps = {
  sortBy: string;
  sortDirection: SortDirectionEnum;
  onSort: (name: string, direction: SortDirectionEnum) => void;
} & SimpleTableCellProps;

const SimpleTableCellSortable = ({
  sortBy,
  sortDirection,
  onSort: handleSort,
  children,
  ...simpleTableCellProps
}: SimpleTableCellSortableProps): JSX.Element => {
  const tooltip = useMemo(
    (): string => sortDirection && (sortDirection === SortDirectionEnum.Asc ? 'Ascending' : 'Descending'),
    [sortDirection, simpleTableCellProps.className]
  );
  const onSort = useCallback((): void => {
    let direction = SortDirectionEnum.Asc;
    if (sortBy === simpleTableCellProps.name) {
      if (sortDirection === SortDirectionEnum.Asc) {
        direction = SortDirectionEnum.Desc;
      } else if (sortDirection === undefined) {
        direction = SortDirectionEnum.Asc;
      } else {
        direction = undefined;
      }
    }
    handleSort(simpleTableCellProps.name, direction);
  }, [sortBy, sortDirection, simpleTableCellProps.name]);

  const isSorted = sortBy === simpleTableCellProps.name && sortDirection;
  const byDirection = sortDirection?.toLowerCase?.() || '';

  return (
    <SimpleTableCell
      {...simpleTableCellProps}
      className={getClasses(
        simpleTableCellProps.className,
        'SimpleTable-Cell-Sortable',
        isSorted ? `SimpleTable-Cell-Sorted-${sortDirection}` : undefined
      )}
    >
      <TippyWhen isTrue={!!tooltip} options={{ content: tooltip }}>
        <Button className="d-flex gap-2" variant="icon" onClick={onSort}>
          <span>{children}</span>
          <span>
            <i className={getClasses('fa', isSorted ? `fa-sort-${byDirection}` : 'fa-sort opacity-25')} />
          </span>
        </Button>
      </TippyWhen>
    </SimpleTableCell>
  );
};
SimpleTableCell.Sortable = SimpleTableCellSortable;

export type SimpleTableCellSelectProps = {} & Omit<SimpleTableCellProps, 'name'> & Pick<ComponentProps<'input'>, 'checked' | 'onChange'>;

const SimpleTableCellSelect = ({ checked, onChange, ...simpleTableCellProps }: SimpleTableCellSelectProps): JSX.Element => (
  <SimpleTableCell {...simpleTableCellProps} name="SELECT" className="w-xs-fixed d-print-none">
    <input type="checkbox" checked={checked} onChange={onChange} />
  </SimpleTableCell>
);
SimpleTableCell.Select = SimpleTableCellSelect;

export default SimpleTableCell;
