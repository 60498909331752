import './styles.scss';

import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { EnumDropdownField, getItemsFromEnum } from '@/components/EnumDropdown';
import Input, { InputField } from '@/components/Input';

import { AirportDropdownField } from '@/components/AirportDropdown';
import { DatetimeInputField } from '@/components/DatetimeInput';
import { ElementType } from 'react';
import Field from '@/components/Field';
import Form from '@/common/Form';
import GenericFileUpload from '@/components/FileUpload/new';
import { TextAreaField } from '@/components/TextArea';
import { UserDropdownField } from '@/components/UserDropdown';
import useLocale from '@/hooks/useLocale';

const AccidentHistoryForm = ({ onSubmit, locale: customLocale, value = {} }: AccidentHistoryFormDefinitions.Props): JSX.Element => {
  const locale = useLocale({
    ...AccidentHistoryFormDefinitions.getLocale(value),
    ...customLocale,
  });
  const handleSubmit = async (data) => {
    data = await onSubmit?.(data);
    // await Call BE and submit the data.
  };

  //Remove these enums after form is wired up to backend
  enum TempCameraWorkingEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempThirdPartyInvolvedEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempCrewInvolvedEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempInjuriesEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempMedicalNeededEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempEmergencyServiceCalledEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempVehicleDriveableEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempDriverFaultEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempKristineCalledEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempSafetyNotifiedEnum {
    YES = 'Yes',
    NO = 'No',
  }

  return (
    <Form className="AccidentHistoryForm" onSubmit={handleSubmit} value={AccidentHistoryFormDefinitions.initValue}>
      <Row className="AccidentHistoryFormHeader">
        <Col>
          {
            /* Section appears if trip linked */
            /*value.id*/ false && (
              <>
                <span>DL1212 1/22/25 05:30 EST</span>
                <span>ATL - Keith Sanderson - #4321</span>
              </>
            )
          }
          <h4 className="AccidentHistoryFormTitle">{locale('Accident')}</h4>
        </Col>
      </Row>
      {
        /* Section appears if no trip linked */
        /*!value.id*/ true && (
          <Row className="AccidentHistoryFormLinkTrips">
            <Col>
              <span>THIS IS NOT LINKED TO TRIP.</span>
              <span>
                WOULD YOU LIKE TO LINK THIS TO A TRIP? <a className="Link">YES</a>
              </span>
            </Col>
          </Row>
        )
      }
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="description" label={locale('Description of Accident')} />
        </Col>
      </Row>
      <Row>
        {
          /* Station field appears if no trip linked */
          /*!value.id*/ true && (
            <Col xs={6}>
              <Form.Input as={AirportDropdownField} name="station" label={locale('Station')} />
            </Col>
          )
        }
        {/* Date time field centers if trip is linked */}
        <Col xs={{ span: 6, offset: /*!value.id*/ true ? 0 : 3 }}>
          <Form.Input as={DatetimeInputField} name="accidentDatetime" label={locale('Accident Date/Time')} />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <Form.Input as={UserDropdownField} name="driverName" label={locale('Driver Name')} />
        </Col>
        <Col xs={6}>
          <Form.Input as={InputField} name="vanNumber" label={locale('Van Number')} maxLength={10} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={UserDropdownField} name="dispatcher" label={locale('Dispatcher Name')} />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="cameraWorking"
            label={locale('Samsara Camera Working?')}
            enum={getItemsFromEnum(TempCameraWorkingEnum)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input as={InputField} name="accidentLocation" label={locale('Specific Location Of The Accident')} maxLength={512} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input
            as={EnumDropdownField}
            name="thirdPartyInvolved"
            label={locale('Was 3rd Party Involved?')}
            enum={getItemsFromEnum(TempThirdPartyInvolvedEnum)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="thirdPartyInfo" label={locale('3rd Party Infofmation')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="otherVehicleDamageDescription" label={locale('Describe Damage To Other Vehicles')} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={EnumDropdownField} name="injuries" label={locale('Anyone Injured?')} enum={getItemsFromEnum(TempInjuriesEnum)} />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="medicalNeeded"
            label={locale('Medical Needed?')}
            enum={getItemsFromEnum(TempMedicalNeededEnum)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={InputField} name="outboundFlightNumber" label={locale('Outbound FLT #')} maxLength={10} />
        </Col>
        <Col xs={6}>
          <Form.Input as={DatetimeInputField} name="outboundDatetime" label={locale('Outbound Date/Time')} />
        </Col>
      </Row>
      <Form.Watch name="injuries">
        {(val) =>
          val === TempInjuriesEnum.YES && (
            <>
              <Row>
                <Col>
                  <Form.Input
                    as={TextAreaField}
                    name="medicalAttentionInfo"
                    label={locale('What Type Of Medical Attention Was Offered/Required?')}
                    placeholder={locale('Treated on scene, which hospital were they sent to, etc')}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Input as={TextAreaField} name="injuredPartiesInfo" label={locale('Information Of All Injured Parties')} />
                </Col>
              </Row>
            </>
          )
        }
      </Form.Watch>
      <Row>
        <Col>
          <Form.Input
            as={EnumDropdownField}
            name="crewInvolved"
            label={locale('Were Crew Involved?')}
            enum={getItemsFromEnum(TempCrewInvolvedEnum)}
          />
        </Col>
      </Row>
      <Form.Watch name="crewInvolved">
        {(val) =>
          val === TempCrewInvolvedEnum.YES && (
            <Row>
              <Col>
                <Form.Input
                  as={TextAreaField}
                  name="crewMembersInfo"
                  label={locale('Information Of All Crew Members')}
                  placeholder={locale('Name and phone numbers')}
                />
              </Col>
            </Row>
          )
        }
      </Form.Watch>
      <Row>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="emergencyServiceCalled"
            label={locale('Did Driver Call 911?')}
            enum={getItemsFromEnum(TempEmergencyServiceCalledEnum)}
          />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="vehicleDriveable"
            label={locale('Can Vehicle Be Driven?')}
            enum={getItemsFromEnum(TempVehicleDriveableEnum)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="driverFault"
            label={locale('Was Driver At Fault?')}
            enum={getItemsFromEnum(TempDriverFaultEnum)}
          />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="kristineCalled"
            label={locale('Was Kristine Called?')}
            enum={getItemsFromEnum(TempKristineCalledEnum)}
          />
        </Col>
      </Row>

      <Field className="AccidentHistoryFormCheckboxHeader" label={locale('Check All That Apply:')}>
        <CheckBox name="transport" label={locale('Arranged another driver to transport crew members (if needed)')} value={value} />
        <CheckBox name="test" label={locale('Driver contacted manager')} value={value} />
        <CheckBox name="test2" label={locale('You contacted manager')} value={value} />
      </Field>

      <Row>
        <Col xs={6}>
          <Form.Input as={InputField} name="safetyOnCall" label={locale('Who Is Safety On Call?')} />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="safetyNotified"
            label={locale('Was Safety Notified?')}
            enum={getItemsFromEnum(TempSafetyNotifiedEnum)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Button name="SUBMIT" variant="success" onClick={handleSubmit} style={{ width: '100%', textTransform: 'uppercase' }}>
            {locale('Create Accident')}
          </Button>
        </Col>
      </Row>
      <Row className="AccidentHistoryFormUpload">
        <Col>
          <GenericFileUpload
            endpoint=""
            options={{
              showFiles: true,
              multiple: true,
            }}
            locale={{
              Upload: 'UPLOAD FILES',
            }}
            onError={async (...args) => console.log('', ...args)}
            onSuccess={async (...args) => console.log('', ...args)}
          />
        </Col>
      </Row>
      <Row className="AccidentHistoryFormHistory">
        <Col>
          <h4 className="AccidentHistoryFormHistoryTitle">{locale('Accident History')}</h4>
        </Col>
      </Row>
    </Form>
  );
};

const CheckBox = ({ name, value, label }) => {
  return (
    <InputGroup style={{ padding: '0.375rem 0.75rem', display: 'flex', flexWrap: 'nowrap', gap: '.5rem' }}>
      <Input type="checkbox" name={name} checked={!!value} />
      <label style={{ flexGrow: '1', fontSize: '0.8rem' }}>{label}</label>
    </InputGroup>
  );
};

export namespace AccidentHistoryFormDefinitions {
  export type Locale = {
    SUBMIT_BUTTON_TEXT?: string;
    Accident?: string;
    'Description of Accident'?: string;
    Station?: string;
    'Accident Date/Time'?: string;
    'Driver Name'?: string;
    'Van Number'?: string;
    'Dispatcher Name'?: string;
    'Samsara Camera Working?'?: string;
    'Specific Location Of The Accident'?: string;
    'Was 3rd Party Involved?'?: string;
    '3rd Party Infofmation'?: string;
    'Describe Damage To Other Vehicles'?: string;
    'Anyone Injured?'?: string;
    'Medical Needed?'?: string;
    'Outbound FLT #'?: string;
    'Outbound Date/Time'?: string;
    'What Type Of Medical Attention Was Offered/Required?'?: string;
    'Treated on scene, which hospital were they sent to, etc'?: string;
    'Information Of All Injured Parties'?: string;
    'Were Crew Involved?'?: string;
    'Information Of All Crew Members'?: string;
    'Name and phone numbers'?: string;
    'Did Driver Call 911?'?: string;
    'Can Vehicle Be Driven?'?: string;
    'Was Driver At Fault?'?: string;
    'Was Kristine Called?'?: string;
    'Check All That Apply:'?: string;
    'Arranged another driver to transport crew members (if needed)'?: string;
    'Driver contacted manager'?: string;
    'You contacted manager'?: string;
    'Was Safety Notified?'?: string;
    'Who Is Safety On Call?'?: string;
    'Create Accident'?: string;
    'Accident History'?: string;
  };
  export type Value = {
    id: string;
    description: string;
    station: string;
    accidentDatetime: string;
    driverName: string;
    vanNumber: string;
    dispatcher: string;
    cameraWorking: string;
    accidentLocation: string;
    thirdPartyInvolved: string;
    thirdPartyInfo: string;
    otherVehicleDamageDescription: string;
    injuries: string;
    medicalNeeded: string;
    outboundFlightNumber: string;
    outboundDatetime: string;
    medicalAttentionInfo: string;
    injuredPartiesInfo: string;
    crewInvolved: string;
    crewMembersInfo: string;
    emergencyServiceCalled: string;
    vehicleDriveable: string;
    driverFault: string;
    kristineCalled: string;
    //checkboxes
    safetyOnCall: string;
    safetyNotified: string;
  };
  export type Props = {
    onSubmit?: (data: Value) => void | Value | Promise<Value> | undefined;
    locale?: Partial<Locale>;
    value?: Partial<Value>;
  };
  export const initValue: Value = {
    id: undefined,
    description: '',
    station: '',
    accidentDatetime: '',
    driverName: '',
    vanNumber: '',
    dispatcher: '',
    cameraWorking: '',
    accidentLocation: '',
    thirdPartyInvolved: '',
    thirdPartyInfo: '',
    otherVehicleDamageDescription: '',
    injuries: '',
    medicalNeeded: '',
    outboundFlightNumber: '',
    outboundDatetime: '',
    medicalAttentionInfo: '',
    injuredPartiesInfo: '',
    crewInvolved: '',
    crewMembersInfo: '',
    emergencyServiceCalled: '',
    vehicleDriveable: '',
    driverFault: '',
    kristineCalled: '',
    //checkboxes
    safetyOnCall: '',
    safetyNotified: '',
  };
  export const getLocale = (value: Partial<Value>): Partial<Locale> => ({
    SUBMIT_BUTTON_TEXT: value.id ? 'Edit Accident' : 'Create Accident',
  });
}

export default AccidentHistoryForm;
