import './styles.scss';

import Provider, { ProviderMethods, ProviderProps } from '@/common/Provider';

import CatchError from '@/components/CatchError';
import Header from '@/components/Header';
import ModalContainer from '@/components/ModalContainer';
import { getClasses } from '@/utils';

/* Feature
  This component is really just a wrapper for the Provider component. It's main
  purpose is to decouple features from our context provider, so that in the future
  if we decide to change to a different state management solution, we can do so without
  having to change everything else that is using Provider.

  Basically just an extension to keep our features isolated from the rest of the app.
*/

export interface FeatureProps<T = unknown, M = ProviderMethods> extends Omit<ProviderProps<T, M>, 'value'> {
  name: string;
  context?: T;
  error?: (props) => JSX.Element;
}

const Feature = ({
  name,
  context,
  methods,
  error: ErrorDisplay,
  children,
  ...providerProps
}: FeatureProps<typeof context, typeof methods>): JSX.Element => {
  return (
    <>
      <Header />
      <Provider {...providerProps} value={context} methods={methods}>
        <main className={getClasses('Feature', name.replace(/\s/g, ''))}>
          <CatchError fallback={ErrorDisplay}>{children}</CatchError>
        </main>
        <ModalContainer />
      </Provider>
    </>
  );
};

export default Feature;
