import Dropdown, { DropdownProps } from '@/components/Dropdown';
import { fieldWrapper } from '@/components/Field';
export type BooleanDropdownProps = Omit<DropdownProps, 'items'>;

const BooleanDropdown = ({ ...dropdownProps }: BooleanDropdownProps): JSX.Element => {
  return (
    <Dropdown
      {...dropdownProps}
      items={[
        { label: 'Yes', value: '1' },
        { label: 'No', value: '0' },
      ]}
    />
  );
};

export const BooleanDropdownField = fieldWrapper<BooleanDropdownProps>(BooleanDropdown);

export default BooleanDropdown;
