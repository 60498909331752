import './styles.scss';

import React, { HTMLAttributes, ReactNode } from 'react';

import { Alert } from 'react-bootstrap';
import CatchError from '@/components/CatchError';
import { getClasses } from '@/utils';

export type FieldProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  label?: string;
  feedback?: string;
  /** @deprecated Use isValid and isInvalid instead. */
  valid?: boolean;
  /** @deprecated Use isDirty instead. */
  dirty?: boolean;
  required?: boolean;
  children?: ReactNode;
  isValid?: boolean;
  isInvalid?: boolean;
  isDirty?: boolean;
};

const Field = ({
  className,
  label,
  feedback,
  valid,
  dirty,
  required,
  children,
  isValid,
  isInvalid,
  isDirty,
  ...divProps
}: FieldProps): JSX.Element => {
  isValid = isValid ?? valid === true;
  isInvalid = isInvalid ?? valid === false;
  isDirty = isDirty ?? dirty === true;
  return (
    <div
      {...divProps}
      className={getClasses(
        'Field',
        label ? 'Field-WithLabel' : undefined,
        feedback ? 'Field-WithFeedback' : undefined,
        className,
        isValid ? 'is-valid' : undefined,
        isInvalid ? 'is-dirty' : undefined,
        isDirty ? 'is-dirty' : undefined
      )}
    >
      {label && (
        <small className="Field-Label">
          <span>{label}</span>
          {required !== undefined && required !== false && <span className="Field-Required">*</span>}
        </small>
      )}
      <CatchError fallback={(): ReactNode => <Alert variant="danger">Something went wrong.</Alert>}>{children}</CatchError>
      {feedback && <small className="Field-Feedback">{feedback}</small>}
    </div>
  );
};

export const fieldWrapper = <ComponentProps,>(Component, name?: string): ((props: ComponentProps & FieldProps) => JSX.Element) => {
  const WrappedComponent = ({
    label,
    feedback,
    required,
    isValid,
    isInvalid,
    isDirty,
    ...componentProps
  }: ComponentProps & FieldProps): JSX.Element => (
    <Field label={label} feedback={feedback} required={required} isValid={isValid} isInvalid={isInvalid} isDirty={isDirty}>
      <Component {...componentProps} isValid={isValid} isInvalid={isInvalid} isDirty={isDirty} />
    </Field>
  );

  WrappedComponent.displayName = `${name || Component.displayName || Component.name || 'Component'}Field`;
  return WrappedComponent;
};

export default Field;
