import { LoadingBlur } from '@/components/LoadingSpinner';
import PageInfo from '@/components/PageInfo';
import { useIncidentHistory } from '@/features/IncidentHistory';

const IncidentHistoryFiltersInfo = (): JSX.Element => {
  const [{ incidents, totalIncidents, loading }] = useIncidentHistory(({ incidents, totalIncidents, loading }) => ({
    incidents,
    totalIncidents,
    loading,
  }));

  return (
    <>
      <LoadingBlur label="Loading Incidents" loading={loading} />
      <PageInfo>
        {incidents.size} / {totalIncidents} Incidents
      </PageInfo>
    </>
  );
};

export default IncidentHistoryFiltersInfo;
