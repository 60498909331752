import SettingsGroup, { settingsPermissions } from '@/components/Header/NavigationDrawer/groups/SettingsMenu/SettingsGroup';

import NavigationDrawerSubMenu from '@/components/Header/NavigationDrawer/NavigationDrawerSubMenu';

const SettingsSubMenu = (): JSX.Element => (
  <NavigationDrawerSubMenu name="Settings" icon={<i className="sv sv-cog" />} restricted permissions={Object.values(settingsPermissions)}>
    <SettingsGroup />
  </NavigationDrawerSubMenu>
);

export default SettingsSubMenu;
