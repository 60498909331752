// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Drawer {
  width: 34.3rem !important;
  container-name: drawer;
  container-type: inline-size;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  box-shadow: 1rem 0 1rem -0.5rem rgba(0, 0, 0, 0.25) !important;
}
.Drawer .Drawer-Close {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  color: rgba(0, 0, 0, 0.5);
}
.Drawer .Drawer-Header {
  padding-bottom: 0;
}
.Drawer .Drawer-Header .Drawer-Title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  color: var(--bs-primary);
  font-weight: 400;
  letter-spacing: 0.15rem;
  border-bottom: 1px solid var(--bs-primary);
  width: 100%;
  text-transform: uppercase;
  padding: 0 0 0.75rem 0;
}
.Drawer .Drawer-Body {
  padding: 2.25rem 2.63rem;
}
.Drawer .Drawer-Body > *:last-child {
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/common/Drawer/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,sBAAA;EACA,2BAAA;EACA,6CAAA;EACA,4CAAA;EACA,kBAAA;EACA,iBAAA;EACA,8DAAA;AACF;AAAE;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,yBAAA;AAEJ;AAAE;EACE,iBAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,wBAAA;EACA,gBAAA;EACA,uBAAA;EACA,0CAAA;EACA,WAAA;EACA,yBAAA;EACA,sBAAA;AAGN;AAAE;EACE,wBAAA;AAEJ;AADI;EACE,gBAAA;AAGN","sourcesContent":[".Drawer {\n  width: 34.3rem !important;\n  container-name: drawer;\n  container-type: inline-size;\n  padding-right: calc(var(--bs-gutter-x) * 0.5);\n  padding-left: calc(var(--bs-gutter-x) * 0.5);\n  margin-right: auto;\n  margin-left: auto;\n  box-shadow: 1rem 0 1rem -0.5rem rgba(0, 0, 0, 0.25) !important;\n}\n.Drawer .Drawer-Close {\n  position: absolute;\n  top: 0.25rem;\n  right: 0.25rem;\n  color: rgba(0, 0, 0, 0.5);\n}\n.Drawer .Drawer-Header {\n  padding-bottom: 0;\n}\n.Drawer .Drawer-Header .Drawer-Title {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: start;\n  gap: 0.5rem;\n  color: var(--bs-primary);\n  font-weight: 400;\n  letter-spacing: 0.15rem;\n  border-bottom: 1px solid var(--bs-primary);\n  width: 100%;\n  text-transform: uppercase;\n  padding: 0 0 0.75rem 0;\n}\n.Drawer .Drawer-Body {\n  padding: 2.25rem 2.63rem;\n}\n.Drawer .Drawer-Body > *:last-child {\n  margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
