import { ChangeEvent, ComponentPropsWithRef, ComponentType, ElementType, useCallback, useContext, useEffect, useRef } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import { FormContext, setFormStorage } from '@/common/Form';

import Input from '@/components/Input';

export type FormInputProps<T extends ElementType> = {
  name: string;
  as?: T | ComponentType<any>;
  validate?: boolean;
} & ComponentPropsWithRef<T>;

const FormInput = ({ name, as: As = Input, validate, ...inputProps }: FormInputProps<typeof As>): JSX.Element => {
  const { control, getValues } = useFormContext();
  const defaultValue = useRef(getValues(name) || '');
  const {
    name: formName,
    validatingFields: [validatingFields, setValidatingFields],
  } = useContext(FormContext);
  const isValidating = (validate !== undefined && validate !== false) || getValues(name) !== defaultValue.current;

  const render = useCallback(
    ({ field, fieldState }: { field: ControllerRenderProps<FieldValues, any>; fieldState: ControllerFieldState }): JSX.Element => {
      const { invalid, isDirty, error } = fieldState;
      const { onChange: change, onBlur, ref, ...fieldProps } = field;
      const validityProps = {
        isValid: invalid === false,
        isInvalid: error !== undefined,
        isDirty,
        feedback: error?.message,
      };

      const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (inputProps.onChange) {
          const result = inputProps.onChange(event);
          if (result !== undefined) event = result;
        }
        if (event?.target && event?.target?.name !== undefined && event?.target?.value !== undefined) return change(event);
        return change({ target: { name, value: event } });
      };

      return (
        <>
          <As value={defaultValue} {...inputProps} {...fieldProps} {...(isValidating ? validityProps : {})} onChange={onChange} />
        </>
      );
    },
    [name, inputProps, As]
  );

  useEffect((): (() => void) => {
    if (validatingFields.has(name) || !isValidating) return;
    setValidatingFields((current: Set<string>): Set<string> => {
      const updatedSet = new Set(current);
      updatedSet.add(name);
      return updatedSet;
    });
    return (): void => {
      setValidatingFields((current: Set<string>): Set<string> => {
        const updatedSet = new Set(current);
        updatedSet.delete(name);
        return updatedSet;
      });
    };
  }, [validate, name]);
  const value = useWatch({ control, name });
  useEffect(() => {
    if (!formName) return;
    setFormStorage(formName, (current) => ({ ...(current || {}), [name]: value }));
  }, [formName, value]);

  return <Controller name={name} control={control} defaultValue={defaultValue.current} render={render} />;
};

export default FormInput;
