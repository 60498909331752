import { Modal as BootstrapModal, ModalFooterProps as BootstrapModalFooterProps } from 'react-bootstrap';

import { ReactNode } from 'react';
import { getClasses } from '@/utils';

export type ModalFooterProps = { children?: ReactNode } & BootstrapModalFooterProps;

const ModalFooter = ({ children, ...footerProps }: ModalFooterProps): JSX.Element => {
  if (!children) return null;
  return (
    <BootstrapModal.Footer {...footerProps} className={getClasses('Modal-Footer', footerProps.className)}>
      {children}
    </BootstrapModal.Footer>
  );
};

export default ModalFooter;
