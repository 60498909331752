import Drawer, { DrawerProps } from '@/common/Drawer';
import { getClasses, pixelsToRem } from '@/utils';

import SummaryHistoryForm from '@/features/SummaryHistory/components/SummaryHistoryForm';
import { createComponentState } from '@/state';

export type SummaryHistoryDrawerState = {
  show: boolean;
};
export const initSummaryHistoryState: SummaryHistoryDrawerState = {
  show: false,
};
export const useSummaryHistoryDrawer = createComponentState<SummaryHistoryDrawerState>(initSummaryHistoryState);
export type SummaryHistoryDrawerProps = { locale?: Record<string, string> } & DrawerProps;
const SummaryHistoryDrawer = ({ locale: customLocale, ...drawerProps }: SummaryHistoryDrawerProps): JSX.Element => {
  const [state, setState] = useSummaryHistoryDrawer(({ state, setState }) => [state, setState]);
  const { show } = state;

  const onHide = (): void => setState({ ...state, show: false });
  const afterClose = (): void => setState(initSummaryHistoryState);
  const onSubmit = (data): void => {
    console.log('Submit Summary', data);
    onHide();
  };

  return (
    <Drawer
      {...drawerProps}
      className={getClasses('SummaryHistoryDrawer', drawerProps.className)}
      show={show}
      onHide={onHide}
      afterClose={afterClose}
      width={pixelsToRem(549)}
    >
      <SummaryHistoryForm onSubmit={onSubmit} />
    </Drawer>
  );
};

export default SummaryHistoryDrawer;
