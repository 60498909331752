import { FieldErrors, FieldValues, UseFormReturn, useFormContext } from 'react-hook-form';
import { ReactNode, useContext } from 'react';

import { FormContext } from '@/common/Form';

export type FormWatchProps = {
  name?: string;
  defaultValue?: any;
  children: (value: unknown, form: UseFormReturn<FieldValues, any, undefined>) => ReactNode;
};
const FormWatch = ({ name, defaultValue, children }: FormWatchProps): ReactNode => {
  const form = useFormContext();
  const value = form.watch(name ?? undefined, defaultValue);
  const {
    validatingFields: [validatingFields],
  } = useContext(FormContext);
  const [isValid, errors] = Object.entries(form.formState.errors).reduce(
    (acc: [boolean, FieldErrors<FieldValues>], [key, value]) => {
      if (validatingFields.has(key)) {
        return [acc[0] && value === undefined, { ...acc[1], [key]: value as FieldErrors<FieldValues>[string] }];
      }
      return acc;
    },
    [true, {} as FieldErrors<FieldValues>]
  );
  return children(value, { ...form, formState: { ...form.formState, isValid, validatingFields, errors } });
};

export default FormWatch;
