import useSession, { SessionUser } from '@/state/session';

import { Button } from 'react-bootstrap';
import EditProfileModal from '@/components/EditUsersModal/EditProfileModal';
import ImageDisplay from '@/components/ImageDisplay';
import { titleCase } from '@/utils';
import { useState } from 'react';

const NavigationDrawerHeader = (): JSX.Element => {
  const user = useSession(({ state }) => state.user || ({} as SessionUser));
  const [open, setOpen] = useState<boolean>(false);

  const fullName = [user?.firstName, user?.lastName].filter(Boolean).join(' ');

  return (
    <>
      <div className="NavigationDrawer-Header d-flex gap-2">
        <div className="NavigationDrawer-Avatar">
          <ImageDisplay src={user?.avatar} />
        </div>
        <div className="NavigationDrawer-Info d-flex flex-column flex-grow-1">
          <span>{fullName}</span>
          <small>
            <em>{titleCase(user.role?.name)}</em>
          </small>
          <span>
            <Button size="sm" variant="text" onClick={() => setOpen(true)}>
              Edit Profile
            </Button>
          </span>
        </div>
      </div>
      {/* TODO: Make this a global modal, so that it doesn't need to be rendered here. */}
      <EditProfileModal show={open} onHide={() => setOpen(false)} />
    </>
  );
};

export default NavigationDrawerHeader;
