import { Modal as BootstrapModal, ModalHeaderProps as BootstrapModalHeaderProps } from 'react-bootstrap';

import { ReactNode } from 'react';
import { getClasses } from '@/utils';

export type ModalHeaderProps = { title?: string; icon?: JSX.Element; children?: ReactNode } & BootstrapModalHeaderProps;

const ModalHeader = ({ title, icon, children, ...headerProps }: ModalHeaderProps): JSX.Element => {
  if (!title && !icon && !children) return null;
  return (
    <BootstrapModal.Header {...headerProps} className={getClasses('Modal-Header', headerProps.className)}>
      {children}
      {!children && (title || icon) && (
        <BootstrapModal.Title className="Modal-Title">
          {icon}
          {title}
        </BootstrapModal.Title>
      )}
    </BootstrapModal.Header>
  );
};

export default ModalHeader;
