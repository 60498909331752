import { Container, ListGroup, Row } from 'react-bootstrap';
import { HTMLAttributes, useEffect, useRef } from 'react';
import { Room, useChatState } from '@/hooks/useSocket/chat/useChatRooms';

import ChatListContent from '@/components/ChatWidget/ChatList/ChatListContent';
import ChatListHeader from '@/components/ChatWidget/ChatList/ChatListHeader';
import ChatMessage from '@/components/ChatWidget/ChatMessage';
import { stringify } from '@/utils';
import useChatMethods from '@/hooks/useSocket/chat/useChatMethods';
import useElementSize from '@/hooks/useElementSize';
import useInterval from '@/hooks/useInterval';
import useSocket from '@/hooks/useSocket';
import useViewport from '@/hooks/useViewport';
import useVisibility from '@/hooks/useVisibility';

type MessageCenterContentProps = HTMLAttributes<HTMLDivElement> & {
  room: Room;
};
const MessageCenterContent = ({ room, ...props }: MessageCenterContentProps) => {
  const socket = useSocket();
  const [history, config, setState] = useChatState(({ state: { history, config }, setState }) => [history, config, setState]);
  const { getHistory } = useChatMethods();
  const messagesEnd = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => messagesEnd.current?.scrollIntoView?.({ behavior: 'instant', block: 'end' });
  const [{ content: { height: viewportHeight = 0 } = {} } = {}] = useViewport();
  const { ref: headerRef, height: headerHeight } = useElementSize();
  const { ref: footerRef, height: footerHeight } = useElementSize();
  const isVisible = useVisibility();
  const lastHistory = useRef<any>(null);
  const retries = useRef(0);

  const getChatHistory = async (retry: boolean = true) =>
    getHistory({
      rooms: [room?.id],
      limit: 100,
      offset: 0,
    }).catch(() => {
      console.error(
        `Failed to ${retry ? 'GET' : 'REFRESH'} history.`,
        retry && retries.current < 10 ? `(${retries.current + 1}/10) Retrying...` : ''
      );
      if (!retry) return;
      if (retries.current >= 10) return (retries.current = 0);
      retries.current += 1;
      getChatHistory(retry);
    });

  useEffect(() => {
    setState((current) => ({ ...current, roomId: room?.id }));
    getChatHistory();
  }, [room]);
  useEffect(() => {
    if (stringify.compare(lastHistory.current, history)) return;
    lastHistory.current = history;
    scrollToBottom();
  }, [history]);

  useInterval(() => {
    if (!isVisible || !room?.id || !socket?.connected) return;
    getChatHistory(false);
  }, config?.client_refresh_interval_milliseconds ?? 5000);

  return (
    <Container {...props} fluid className="d-flex flex-column bg-white">
      <Row className="m-0">
        <ListGroup variant="flush">
          <ListGroup.Item className="bg-transparent" ref={headerRef}>
            <ChatListHeader.Active
              roomId={room?.id}
              name={room?.displayName}
              displayImageUrl={room?.displayImageUrl}
              servicerIataAirlineCode={room?.trip?.servicerIataAirlineCode}
              scheduledTime={room?.trip?.tripScheduled}
              unread={room?.unreadCount}
              roomType={room?.roomType}
            />
          </ListGroup.Item>
        </ListGroup>
      </Row>
      <Row className="align-items-start mx-4 h-100">
        <div
          className={`d-flex flex-column-reverse justify-content-start {max-height:${viewportHeight - headerHeight - footerHeight - 16}px;overflow:hidden|auto;}`}
        >
          {/* This needs to be first because the rows are reversed via css */}
          <div ref={messagesEnd} />
          {history?.map?.((message, index) => <ChatMessage message={message} key={index} />)}
        </div>
      </Row>
      <Row className="w-100" ref={footerRef}>
        <ChatListContent.Footer roomId={room?.id || ''} roomType={room?.roomType} />
      </Row>
    </Container>
  );
};

export default MessageCenterContent;
