import './styles.scss';

import { DefaultValues, FormProvider, SubmitHandler, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import React, {
  FormEvent,
  ForwardRefExoticComponent,
  HTMLAttributes,
  KeyboardEvent,
  RefAttributes,
  createContext,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { getClasses, getResultFromState } from '@/utils';

import FormButton from '@/common/Form/FormButton';
import FormInput from '@/common/Form/FormInput';
import FormWatch from '@/common/Form/FormWatch';
import { ZodType } from 'zod';
import { createComponentState } from '@/state';
import { zodResolver } from '@hookform/resolvers/zod';

export interface FormInterface extends ForwardRefExoticComponent<FormProps<any> & RefAttributes<UseFormReturn<any>>> {
  getStorage: (formName: string) => FormStateStorage;
  setStorage: (formName: string, input: any) => void;
  Input: typeof FormInput;
  Button: typeof FormButton;
  Watch: typeof FormWatch;
}
export type FormProps<T> = {
  name?: string;
  value?: T;
  onSubmit: SubmitHandler<T>;
  validator?: ZodType<T, any, any>;
} & HTMLAttributes<HTMLFormElement>;
type FormContextValue = {
  name?: string;
  validatingFields: [Set<string>, React.Dispatch<React.SetStateAction<Set<string>>>];
};
export const FormContext = createContext<FormContextValue>({
  name: undefined,
  validatingFields: [new Set(), () => undefined],
});

export type FormStateStorage = {
  [key: string]: unknown;
};
export const useFormStorage = createComponentState<FormStateStorage>({});
export const getFormStorage = (formName: string): FormStateStorage => useFormStorage.getState()?.[formName] ?? {};
export const setFormStorage = (formName: string, input) =>
  useFormStorage.setState((current) => ({ ...current, [formName]: getResultFromState(input, current?.[formName] || {}) }));

const Form = forwardRef(
  <InputsType,>({ name, onSubmit, children, value, validator, ...formProps }: FormProps<InputsType>, ref): JSX.Element => {
    const validatingFields = useState<Set<string>>(new Set());

    const methods = useForm<InputsType>({
      defaultValues: name ? (getFormStorage(name) as DefaultValues<InputsType>) : ((value ?? {}) as DefaultValues<InputsType>),
      mode: 'onChange',
      reValidateMode: 'onChange',
      shouldUnregister: true,
      resolver: validator ? zodResolver<InputsType>(validator) : undefined,
    });

    useImperativeHandle(ref, () => methods, [methods]);
    const formContext = useMemo((): FormContextValue => ({ name, validatingFields }), [validatingFields]);

    useEffect(() => {
      if (!validator) return;
      methods.trigger();
    }, []);

    const handleSubmit = useCallback(
      async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();
        await methods.trigger();
        const isValid = Object.entries(methods.formState.errors).reduce((acc: boolean, [key, value]) => {
          if (validatingFields[0].has(key)) return acc && value === undefined;
          return acc;
        }, true);
        if (!isValid) return;
        await onSubmit(methods.getValues(), event);
        if (!name) return;
        setFormStorage(name, (current) => ({ ...current, [name]: methods.getValues() }));
      },
      [onSubmit, methods]
    );

    return (
      <FormProvider {...methods}>
        <FormContext.Provider value={formContext}>
          <form
            {...formProps}
            className={getClasses('Form', formProps.className)}
            onSubmit={handleSubmit}
            onKeyDown={(event: KeyboardEvent<HTMLFormElement>): void =>
              event.key === 'Enter' && !event.shiftKey ? event.preventDefault() : undefined
            }
          >
            {children}
          </form>
        </FormContext.Provider>
      </FormProvider>
    );
  }
) as unknown as FormInterface;

Form.displayName = 'Form';
Form.getStorage = getFormStorage;
Form.setStorage = setFormStorage;
Form.Input = FormInput;
Form.Button = FormButton;
Form.Watch = FormWatch;

export default Form;
export { FormInput, FormButton, FormWatch };
