import Dropdown, { DropdownProps } from '@/components/Dropdown';
import { fieldWrapper } from '@/components/Field';
export type CountryDropdownProps = Omit<DropdownProps, 'items'>;

const CountryDropdown = ({ ...dropdownProps }: CountryDropdownProps): JSX.Element => {
  return <Dropdown {...dropdownProps} items={[{ label: 'United States', value: 'USA' }]} />;
};

export const CountryDropdownField = fieldWrapper<CountryDropdownProps>(CountryDropdown);

export default CountryDropdown;
