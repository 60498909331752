import './styles.scss';

import { Button, InputGroup } from 'react-bootstrap';
import React, { ChangeEvent, ForwardedRef, InputHTMLAttributes, ReactNode, forwardRef, useImperativeHandle, useRef } from 'react';

import { fieldWrapper } from '@/components/Field';
import { getClasses } from '@/utils';

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange?: (value: string) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  isDirty?: boolean;
};

const Input = (() => {
  const Input = (
    { onChange, isValid, isInvalid, isDirty, name, ...inputProps }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ): ReactNode => {
    return (
      <input
        {...inputProps}
        name={name ?? ''}
        className={getClasses(
          'Input',
          inputProps.type !== 'checkbox' ? 'form-control' : undefined,
          isValid ? 'is-valid' : isInvalid ? 'is-invalid' : undefined,
          isDirty ? 'is-dirty' : undefined,
          inputProps?.className
        )}
        onChange={(event: ChangeEvent<HTMLInputElement>): void => onChange?.(event.target.value)}
        {...(ref ? { ref } : {})}
      />
    );
  };
  return forwardRef(Input);
})();

export const InputField = fieldWrapper<InputProps>(Input);

export const InputWithIcon = (() => {
  const InputWithIcon = ({ icon, ...inputProps }: { icon: ReactNode } & InputProps, ref): ReactNode => {
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
      focus: (event): void => {
        event?.preventDefault?.();
        event?.stopPropagation?.();
        if (!inputRef.current) return;
        inputRef.current.focus();
      },
    }));

    return (
      <InputGroup className="InputWithIcon">
        {icon && (
          <InputGroup.Text>
            <Button variant="icon" onClick={(event) => inputRef.current.focus(event)}>
              {icon}
            </Button>
          </InputGroup.Text>
        )}
        <Input {...inputProps} ref={inputRef} />
      </InputGroup>
    );
  };
  return forwardRef(InputWithIcon);
})();

export default Input;
