import { useRef, useState } from 'react';

import useEventListener from '@/hooks/useEventListener';

/* useVisibility
  Returns a boolean value representing the current visibility and focus state of the document.
  This hook will also call the provided function with the current visibility state when it changes.
*/

export type UseVisibilityCallback = (isVisible: boolean) => void;

export const checkVisibility = (): boolean => {
  const hasVisibility = document.visibilityState === 'visible';
  const hasFocus = true; // document.hasFocus();
  return hasVisibility && hasFocus;
};

const useVisibility = (fn?: UseVisibilityCallback): boolean => {
  const [visible, setVisible] = useState(checkVisibility);
  const lastVisibility = useRef(visible);

  const updateVisibility = (): void => {
    const isVisible = checkVisibility();
    lastVisibility.current = isVisible;
    setVisible(isVisible);
    fn?.(isVisible);
  };

  useEventListener('visibilitychange', updateVisibility);
  // useEventListener('focus', updateVisibility);
  // useEventListener('blur', updateVisibility);

  return visible;
};

export default useVisibility;
