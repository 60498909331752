import { Config } from '@/state/session';
import NavigationDrawerItem from '@/components/Header/NavigationDrawer/NavigationDrawerItem';
import NavigationDrawerItemGroup from '@/components/Header/NavigationDrawer/NavigationDrawerItemGroup';

export const viewServiceIssuePermissions: Record<string, keyof Config['auth']> = {
  accident: 'allowViewAccidentHistory' as keyof Config['auth'],
  incident: 'allowViewIncidentHistory' as keyof Config['auth'],
  lostAndFound: 'allowViewLostAndFoundHistory' as keyof Config['auth'],
  dispatchIssues: 'allowViewDispatchIssuesHistory' as keyof Config['auth'],
  dispatchSummaries: 'allowViewDispatchSummaryHistory' as keyof Config['auth'],
};

const ViewServiceIssuesGroup = (): JSX.Element => (
  <NavigationDrawerItemGroup title="Service Issue History" permissions={Object.values(viewServiceIssuePermissions)}>
    <NavigationDrawerItem
      name="Accident History"
      icon={<i className="sv sv-ambulance" />}
      to="/accident-history"
      restricted
      permissions={[viewServiceIssuePermissions.accident]}
    />
    <NavigationDrawerItem
      name="Incident History"
      icon={<i className="sv sv-shield-alert" />}
      to="/incident-history"
      restricted
      permissions={[viewServiceIssuePermissions.incident]}
    />
    <NavigationDrawerItem
      name="Lost and Found History"
      icon={<i className="sv sv-box" />}
      to="/lost-and-found-history"
      restricted
      permissions={[viewServiceIssuePermissions.lostAndFound]}
    />
    <NavigationDrawerItem
      name="Dispatch Issue History"
      icon={<i className="sv sv-headset" />}
      to="/dispatch-issues-history"
      restricted
      permissions={[viewServiceIssuePermissions.dispatchIssues]}
    />
    <NavigationDrawerItem
      name="Dispatch Summary History"
      icon={<i className="sv sv-bookmark" />}
      to="/dispatch-summaries-history"
      restricted
      permissions={[viewServiceIssuePermissions.dispatchSummaries]}
    />
  </NavigationDrawerItemGroup>
);

export default ViewServiceIssuesGroup;
