import './styles.scss';

import { IncidentHistoryRow, useIncidentHistory } from '@/features/IncidentHistory';

import { Datetime } from '@/utils';
import IncidentHistoryTableColumns from '@/features/IncidentHistory/components/IncidentHistoryTable/IncidentHistoryTableColumns';
import IncidentHistoryTableRow from '@/features/IncidentHistory/components/IncidentHistoryTable/IncidentHistoryTableRow';
import SimpleTable from '@/components/SimpleTable';
import { SortDirectionEnum } from '@/models/gen/graphql';

const IncidentHistoryTable = (): JSX.Element => {
  const [{ incidents: sortedIncidents }] = useIncidentHistory(({ incidents, sortBy, sortDirection }) => ({
    incidents: Array.from(incidents.keys()).sort((a: string, b: string): number => {
      if (!sortDirection || !sortBy) return 0;
      const sortIncidents = compareIncidentsBy(sortBy);
      const result = sortIncidents(incidents.get(a), incidents.get(b));
      if (sortDirection === SortDirectionEnum.Desc) return -result;
      return result;
    }),
  }));

  return (
    <SimpleTable
      className="IncidentHistoryTable"
      rows={sortedIncidents}
      columns={IncidentHistoryTableColumns}
      row={IncidentHistoryTableRow}
    />
  );
};

const compareIncidentsBy =
  (sortBy: string) =>
  (incidentA: IncidentHistoryRow, incidentB: IncidentHistoryRow): number => {
    if (!incidentA || !incidentB) return 0;
    switch (sortBy) {
      case 'AIRPORT':
        return incidentA.airport.localeCompare(incidentB.airport);
      case 'DATETIME':
        return new Datetime(incidentA.datetime).diff(incidentB.datetime, 'millisecond');
      case 'AIRLINE':
        return incidentA.airline.localeCompare(incidentB.airline);
      case 'FLIGHT_NUMBER':
        return incidentA.flightNumber.localeCompare(incidentB.flightNumber);
      case 'SCHEDULED_TIME':
        return new Datetime(incidentA.scheduledTime).diff(incidentB.scheduledTime, 'millisecond');
      case 'VAN':
        return incidentA.van.localeCompare(incidentB.van);
      case 'EMPLOYEE':
        return incidentA.employee.localeCompare(incidentB.employee);
      case 'ID':
        return incidentA.idNumber.localeCompare(incidentB.idNumber);
      case 'MANAGER':
        return incidentA.manager.localeCompare(incidentB.manager);
      case 'INJURY':
        return incidentA.injury === incidentB.injury ? 0 : incidentA.injury ? -1 : 1;
      case 'CREW_INVOLVED':
        return incidentA.crewInvolved === incidentB.crewInvolved ? 0 : incidentA.crewInvolved ? -1 : 1;
      default:
        return 0;
    }
  };

export default IncidentHistoryTable;
