import { NavigationDrawerState, useNavigationDrawer } from '@/components/Header/NavigationDrawer';

import HotkeysModal from '@/components/HotkeysModal';
import NavigationDrawerItem from '@/components/Header/NavigationDrawer/NavigationDrawerItem';
import NavigationDrawerItemGroup from '@/components/Header/NavigationDrawer/NavigationDrawerItemGroup';
import { clearCache } from '@/utils';
import useConfirmation from '@/hooks/useConfirmation';
import useModal from '@/hooks/useModal';

const ControlsGroup = (): JSX.Element => {
  const { hotKeys } = useNavigationDrawer(({ state: { hotKeys } }) => ({ hotKeys }));
  const setState = useNavigationDrawer(({ setState }) => setState);

  const [, { show: showChangePasswordModal }] = useModal('ChangePasswordModal');
  const handleShowChangePasswordModal = () => showChangePasswordModal();
  const confirmClearCache = useConfirmation();
  const handleClearCache = async () =>
    confirmClearCache()
      .then(() => clearCache())
      .catch(() => console.log('User denied clearing cache.'));
  const onShowHotKeys = () => setState((current: NavigationDrawerState): NavigationDrawerState => ({ ...current, hotKeys: true }));
  const onHideHotKeys = () => setState((current: NavigationDrawerState): NavigationDrawerState => ({ ...current, hotKeys: false }));

  return (
    <>
      <NavigationDrawerItemGroup className="NavigationDrawer-Controls">
        <NavigationDrawerItem name="Hot Keys" onClick={onShowHotKeys} />
        <NavigationDrawerItem name="Privacy Policy" to="/privacy-policy" />
        <NavigationDrawerItem name="Change Password" onClick={handleShowChangePasswordModal} />
        <NavigationDrawerItem name="Clear Cache" onClick={handleClearCache} />
      </NavigationDrawerItemGroup>
      <HotkeysModal show={hotKeys} onHide={onHideHotKeys} />
    </>
  );
};

export default ControlsGroup;
