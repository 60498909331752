import { useEffect, useRef } from 'react';

/* useCssVariable
  Allows readying and setting of CSS variables within elements.
  This hook specifically does not use state to prevent causing re-renders, however
  CSS will read the updated variables automatically and update the elements
  accordingly.
*/
const useCssVariable = (name: `--${string}`, root: HTMLElement = document.documentElement): [string, (value: string) => void] => {
  const lastValue = useRef<string>(root.style.getPropertyPriority(name) || '');

  const setValue = (value: string): void => {
    if (lastValue.current === value) return;
    lastValue.current = value;
    root.style.setProperty(name, value);
  };

  useEffect((): (() => void) => {
    return (): void => {
      root.style.removeProperty(name);
    };
  }, []);

  return [lastValue.current, setValue];
};

export default useCssVariable;
