// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popup .ActionsDropdown {
  position: absolute;
  top: 0.95rem;
  right: 0.25rem;
  z-index: 100;
}
.Popup .ActionsDropdown i {
  font-size: 1.25rem;
}
.Popup .CloseButton + .ActionsDropdown {
  transform: translateX(-2rem);
}`, "",{"version":3,"sources":["webpack://./src/common/Popup/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,YAAA;EACA,cAAA;EACA,YAAA;AAAJ;AACI;EACE,kBAAA;AACN;AAEE;EACE,4BAAA;AAAJ","sourcesContent":[".Popup .ActionsDropdown {\n  position: absolute;\n  top: 0.95rem;\n  right: 0.25rem;\n  z-index: 100;\n}\n.Popup .ActionsDropdown i {\n  font-size: 1.25rem;\n}\n.Popup .CloseButton + .ActionsDropdown {\n  transform: translateX(-2rem);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
