import './styles.scss';

import Drawer, { DrawerProps } from '@/common/Drawer';
import { getClasses, pixelsToRem } from '@/utils';

import IssueHistoryForm from '@/features/IssueHistory/components/IssueHistoryForm';
import { createComponentState } from '@/state';

export type IssueHistoryDrawerState = {
  show: boolean;
};
export const initIssueHistoryState: IssueHistoryDrawerState = {
  show: false,
};
export const useIssueHistoryDrawer = createComponentState<IssueHistoryDrawerState>(initIssueHistoryState);
export type IssueHistoryDrawerProps = { locale?: Record<string, string> } & DrawerProps;
const IssueHistoryDrawer = ({ locale: customLocale, ...drawerProps }: IssueHistoryDrawerProps): JSX.Element => {
  const [state, setState] = useIssueHistoryDrawer(({ state, setState }) => [state, setState]);
  const { show } = state;

  const onHide = (): void => setState({ ...state, show: false });
  const afterClose = (): void => setState(initIssueHistoryState);
  const onSubmit = (data): void => {
    console.log('Submit Issue', data);
    onHide();
  };

  return (
    <Drawer
      {...drawerProps}
      className={getClasses('IssueHistoryDrawer', drawerProps.className)}
      show={show}
      onHide={onHide}
      afterClose={afterClose}
      width={pixelsToRem(549)}
    >
      <IssueHistoryForm onSubmit={onSubmit} />
    </Drawer>
  );
};

export default IssueHistoryDrawer;
