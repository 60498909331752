import { HTMLProps, useMemo } from 'react';

import PageInfo from '@/components/PageInfo';
import Tippy from '@tippyjs/react';
import { getClasses } from '@/utils';
import useSocket from '@/hooks/useSocket';

const ChatConnectionStatus = ({ displayStatus, ...iconProps }: { displayStatus?: boolean } & HTMLProps<HTMLSpanElement>): JSX.Element => {
  const socket = useSocket();

  const status = useMemo((): string => {
    if (socket?.warning === true) return 'Chat Refreshing';
    if (socket?.connected === true) return 'Chat Connected';
    return 'Chat Disconnected - Reconnecting now...';
  }, [socket?.connected, socket?.warning]);
  const color = useMemo((): string => {
    if (socket?.warning === true) return 'text-warning';
    if (socket?.connected === true) return 'text-success';
    return 'text-danger';
  }, [socket?.connected, socket?.warning]);

  const classes = getClasses('fa fa-circle', color);

  return (
    <>
      <Tippy content={status}>
        <div className="d-flex gap-1">
          <div className="d-flex flex-column justify-content-center">
            <i {...iconProps} className={classes} />
          </div>
          {displayStatus !== undefined && <span>{status}</span>}
        </div>
      </Tippy>
    </>
  );
};

export default ChatConnectionStatus;
