import useSession from '@/state/session';

const useHasRoute = (path: string): boolean => {
  const hasRoute = useSession(({ state }) => {
    if (!path || !state?.config?.userConfig?.menus?.navigation?.length) return true;
    return !!state.config.userConfig.menus.navigation.find((route) => route.url === path);
  });
  return hasRoute;
};

export default useHasRoute;
