import React, { useMemo } from 'react';

import TripForm from './drawer';
import { TripModalViewProps } from '@/features/Trips/components/TripModal';

export type TripViewProps = Omit<TripModalViewProps, 'as'>;
const TripView = ({ as, ...props }: TripModalViewProps) => {
  return <TripForm {...props} />;
};

export default TripView;
