import CreateServiceIssuesGroup, {
  createServiceIssuePermissions,
} from '@/components/Header/NavigationDrawer/groups/ServiceIssuesMenu/CreateServiceIssuesGroup';
import ViewServiceIssuesGroup, {
  viewServiceIssuePermissions,
} from '@/components/Header/NavigationDrawer/groups/ServiceIssuesMenu/ViewServiceIssuesGroup';

import NavigationDrawerSubMenu from '@/components/Header/NavigationDrawer/NavigationDrawerSubMenu';

const ServiceIssuesSubMenu = (): JSX.Element => (
  <NavigationDrawerSubMenu
    name="Service Issues"
    icon={<i className="sv sv-register" />}
    restricted
    permissions={Object.values({ ...createServiceIssuePermissions, ...viewServiceIssuePermissions })}
  >
    <CreateServiceIssuesGroup />
    <ViewServiceIssuesGroup />
  </NavigationDrawerSubMenu>
);

export default ServiceIssuesSubMenu;
