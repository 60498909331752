import { useCallback, useMemo } from 'react';

const globalLocale = {};

const useLocale = <T extends string>(
  defaultLocale: Partial<Record<T, string>> = {} as Partial<Record<T, string>>
): ((key: T) => string) => {
  const locale = useMemo<Partial<Record<T, string>>>(
    (): Partial<Record<T, string>> => ({ ...globalLocale, ...defaultLocale }),
    [defaultLocale]
  );
  const getLocale = useCallback((key: T): string => locale[key] || key, [locale]);
  return getLocale;
};

export default useLocale;
