import './styles.scss';

import React, { ReactNode } from 'react';

import ImageDisplay from '@/components/ImageDisplay';
import { getClasses } from '@/utils';

const ChatMessageAvatar = ({ url }: { url?: string }): ReactNode => {
  return <ImageDisplay src={url} circle alt="avatar" className="ChatMessageAvatar" />;
};

export default ChatMessageAvatar;
