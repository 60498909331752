import { GetUserGroupsDocument, LoginDocument } from '@/api/queries';
import { Group, LoginInput, LoginResponse, UserEdge } from '@/models/gen/graphql';

import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { queryInput } from '@/utils';

type LoginGraphApiResponse = GraphApiResponse<typeof LoginDocument>;
type GetUserGroupsGraphApiResponse = GraphApiResponse<typeof GetUserGroupsDocument>;

const loginResponseSelector = (res: LoginGraphApiResponse): LoginResponse => res?.login as LoginResponse;
const getUserGroupsSelector = (res: GetUserGroupsGraphApiResponse): Group[] =>
  res?.searchUsers?.userConnection?.edges?.flatMap((edge: UserEdge): Group[] => edge.node?.groups || []) || [];

export const [runLogin] = graphApi(LoginDocument);
export const [runGetUserGroups] = graphApi(GetUserGroupsDocument);

export const login = async (username: string, password: string): Promise<LoginResponse> => {
  const input: LoginInput = { username, password };
  const res = await runLogin({ input });

  return loginResponseSelector(res);
};

/* TEMPORARY SOLUTION
  According to Dahmitri, the BE Login resolver is incomplete, and getting the groups is not
  possible due to an auth-check within the resolver.

  Getting the groups in that query causes the mutation to fail, so this query is used after
  the user is logged in specifically to get the groups and append them to the user object for
  the session.

  Once this issue on the BE is resolved, this query should be removed and the groups should be
  added to the login response.
*/
// TODO: Remove this query when the login issue for getting groups is resolved.
// TODO: Add "groups" to the login response when the issue is resolved.
export const getUserGroups = async (userId: string): Promise<Group[]> => {
  const res = await runGetUserGroups({
    input: {
      query: [
        {
          id: queryInput(userId),
        },
      ],
    },
  });
  return getUserGroupsSelector(res);
};

export const useLogin = createGraphApiHook(login);

export default login;
