import { LatestRate, LatestRateSearch, LatestRateTypeEnum, RateTableConnectionFormatEnum, SortDirectionEnum } from '@/models/gen/graphql';
import { QueryInputType, onEnter, queryInput, stringify } from '@/utils';
import { ReactNode, useMemo, useRef, useState } from 'react';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';

import { AirportGroupDropdown } from '@/components/AirportDropdown';
import ClientDropdown from '@/components/ClientDropdown';
import ConfirmationButton from '@/components/ConfirmationButton';
import { ConnectionDetails } from '@/utils/custom';
import CurrencyDropdown from '@/components/CurrencyDropdown';
import EditRatesForm from '@/components/EditRatesForm';
import EnumDropdown from '@/components/EnumDropdown';
import Filters from '@/components/Filters';
import FormButton from '@/components/FormButton';
import FormField from '@/components/FormField';
import LocationQueryDropdown from '@/components/LocationDropdown';
import PageInfo from '@/components/PageInfo';
import RateTypeCell from '@/pages/Rates/components/RateTypeCell/RateTypeCell';
import { SEARCH_RATES_PAGE_SIZE } from '@/api/services/rates/searchRates';
import { getClasses } from '@/utils/strings';
import { useDeleteRateGroupBulk } from '@/api/services/rates/deleteRateGroupBulk';
import useHotkeys from 'hooks/useHotkeys';
import useLocale from '@/hooks/useLocale';
import { useLocation } from 'react-router-dom';
import { useSearchRatesTable } from '@/api/services/rates/searchRatesTable';

interface RatesTableFilters {
  payerProviderId: string;
  airportCode: string[];
  thatLocationId: string;
  thisLocationId: string;
  currency: string;
  format: RateTableConnectionFormatEnum;
}
interface RatesTableState {
  selected: string[];
  search: string;
  sorting: {
    column: string;
    direction: string;
  };
  ratesForm: {
    visible: boolean;
    rateGroupId?: string | undefined;
  };
}
const initRatesTableFilters: RatesTableFilters = {
  payerProviderId: '',
  airportCode: [],
  thatLocationId: '',
  thisLocationId: '',
  currency: '',
  format: RateTableConnectionFormatEnum.Current,
};
const initRatesTableState: RatesTableState = {
  selected: [],
  search: '',
  sorting: {
    column: undefined,
    direction: undefined,
  },
  ratesForm: {
    visible: false,
    rateGroupId: undefined,
  },
};
const RatesTable = (): JSX.Element => {
  // Init State
  const [state, setState] = useState(initRatesTableState);
  const location = useLocation();
  const { selected, search, sorting, ratesForm } = state;
  const [{ data, loading }, { refetch, fetchMore }] = useSearchRatesTable();
  const [{ loading: isDeletingRate }, { fetch: deleteRateGroupBulk }] = useDeleteRateGroupBulk();
  const { rows, totalCount, hasNextPage } = data || {};
  const { onSelect, makeSortable, filteredRows: tableRows } = useVirtualTable(setState, { selected, sorting, rows, search });
  const filteredRows = useMemo(() => {
    return tableRows?.filter((item: LatestRate): boolean =>
      search?.length > 0
        ? stringify([
            item?.payerProvider?.name,
            item?.airportCode,
            item?.thatLocation?.name,
            item?.thisLocation?.name,
            item?.duration,
            parseFloat(`${item?.rate}`).toFixed(2),
            item?.currency,
            item?.type,
            item?.tripDistance,
          ])
            .toLowerCase()
            .includes(search?.toLowerCase())
        : true
    );
  }, [search, tableRows]);
  const locale = useLocale({
    NON_CONTRACT: 'NON-CONTRACT',
  });

  useHotkeys('rates', {
    'shift+ctrl+s': {
      name: 'Focus Search Bar',
      description: 'Focuses the Search Bar',
      action: (): any => document.getElementById('search').focus(),
    },
    // 'shift+ctrl+c': {
    //   name: 'Toggle Add Rate',
    //   description: 'Opens and closes the Add Rate modal',
    //   action: (): any => triggerEditRates({ selectedRows: [] }),
    // },
    // 'shift+ctrl+e': {
    //   name: 'Toggle Edit Rate(s)',
    //   description: 'Opens and closes the Edit Trip modal',
    //   action: (): void => triggerEditRates({ selectedRows }),
    // },
  });

  const lastQuery = useRef(null);

  const handleGo = async (filters: RatesTableFilters): Promise<void> => {
    const { payerProviderId, thisLocationId, thatLocationId, format, airportCode } = filters;
    const locations = [];
    let queries: LatestRateSearch[] = [];
    const baseQuery: LatestRateSearch = {
      payerProviderId: payerProviderId ? queryInput(payerProviderId) : null,
      airportCode: airportCode?.length ? queryInput(airportCode) : null,
      createdAt: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Desc, 0),
    };
    if (thisLocationId) locations.push(thisLocationId);
    if (thatLocationId) locations.push(thatLocationId);
    if (locations?.length === 1) {
      queries = [
        {
          ...baseQuery,
          thisLocationId: locations?.length ? queryInput(locations) : null,
        },
        {
          ...baseQuery,
          thatLocationId: locations?.length ? queryInput(locations) : null,
        },
      ];
    } else if (locations?.length === 2) {
      queries = [
        {
          ...baseQuery,
          thisLocationId: queryInput([thisLocationId]),
          thatLocationId: queryInput([thatLocationId]),
        },
        {
          ...baseQuery,
          thisLocationId: queryInput([thatLocationId]),
          thatLocationId: queryInput([thisLocationId]),
        },
      ];
    }

    const query: LatestRateSearch[] = queries?.length ? queries : [baseQuery];
    await refetch({ search: query, format });
    setState((current: RatesTableState): RatesTableState => ({ ...current, selected: [] }));
    lastQuery.current = query;
  };

  const getMore = async (after: number): Promise<ConnectionDetails<LatestRate>> => {
    if (loading || !hasNextPage) return;
    return await fetchMore(lastQuery.current, {
      page: Math.round(after / SEARCH_RATES_PAGE_SIZE),
      merge: true,
    });
  };

  const handleDeleteRates = async (): Promise<void> => {
    await deleteRateGroupBulk(selected);
    setState((current: RatesTableState): RatesTableState => ({ ...current, selected: [] }));
    refetch();
  };

  const handleHideRatesForm = (performRefetch = true): void => {
    setState(
      (current: RatesTableState): RatesTableState => ({ ...current, selected: [], ratesForm: { ...initRatesTableState.ratesForm } })
    );
    if (performRefetch) refetch();
  };

  return (
    <>
      <PageInfo>
        {filteredRows?.length} / {totalCount || 0} Rates
      </PageInfo>
      <Filters
        name="ratesTable"
        value={initRatesTableFilters}
        onSubmit={handleGo}
        onReset={(): void => setState((current: RatesTableState): RatesTableState => ({ ...current, search: '' }))}
        submitOnMount
        key={JSON.stringify(location.search)}
        primary={({ values, onChange }): JSX.Element => {
          const { airportCode, payerProviderId, thatLocationId, thisLocationId, currency, format } = values;
          return (
            <>
              <ClientDropdown
                name="payerProviderId"
                value={payerProviderId}
                onChange={(value: string) => onChange({ target: { name: 'payerProviderId', value } })}
              />
              <AirportGroupDropdown
                name="airportCode"
                value={airportCode || initRatesTableFilters?.airportCode}
                onChange={(value: string[]) => onChange({ target: { name: 'airportCode', value } })}
                options={{
                  locale: { 'Select...': 'Airport' },
                }}
              />
              <LocationQueryDropdown
                name="thatLocationId"
                airports={airportCode || initRatesTableFilters?.airportCode || []}
                value={thatLocationId || ''}
                onChange={(value: string): void => onChange({ target: { name: 'thatLocationId', value } })}
                options={{
                  locale: { 'Select...': 'Pick-up' },
                }}
              />
              <LocationQueryDropdown
                name="thisLocationId"
                airports={airportCode || initRatesTableFilters?.airportCode || []}
                value={thisLocationId || ''}
                onChange={(value: string): void => onChange({ target: { name: 'thisLocationId', value } })}
                options={{
                  locale: { 'Select...': 'Drop-off' },
                }}
              />
              <EnumDropdown
                name="format"
                enum={RateTableConnectionFormatEnum}
                value={format}
                onChange={(value: string) => onChange({ target: { name: 'format', value } })}
                options={{
                  showClearButton: false,
                }}
              />
              <CurrencyDropdown
                name="currency"
                type="rate"
                value={currency}
                onChange={(value: string) => onChange({ target: { name: 'currency', value } })}
                options={{
                  locale: { 'Select...': 'Currency', Usd: 'USD' },
                }}
              />
            </>
          );
        }}
        controls={({ values, onChange }): JSX.Element => {
          const { search } = values;
          return (
            <>
              <FormField
                prepend={<i className="sv sv-magnifier fs-4" />}
                name="search"
                value={search || null}
                onChange={onChange}
                onBlur={(): void => setState((current: RatesTableState): RatesTableState => ({ ...current, search }))}
                onKeyDown={onEnter((): void => {
                  setState((current: RatesTableState): RatesTableState => ({ ...current, search }));
                  const refocusSearchTimer = setTimeout(() => {
                    document.getElementById('search').focus();
                    clearTimeout(refocusSearchTimer);
                  }, 0);
                })}
                placeholder="Search"
                condensed
              />
            </>
          );
        }}
        alternate={(): JSX.Element => {
          return (
            <>
              <FormButton
                name="CREATE_RATE"
                icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
                variant="outline-gray"
                onClick={(): void =>
                  setState(
                    (current: RatesTableState): RatesTableState => ({ ...current, ratesForm: { visible: true, rateGroupId: undefined } })
                  )
                }
              >
                <span>Add Rate{selected?.length > 1 ? 's' : ''}</span>
              </FormButton>
              <FormButton
                name="EDIT_RATE"
                icon={<i className="sv sv-layers {font-size:1.5rem;}" />}
                variant="outline-gray"
                onClick={(): void =>
                  setState(
                    (current: RatesTableState): RatesTableState => ({ ...current, ratesForm: { visible: true, rateGroupId: selected[0] } })
                  )
                }
                disabled={selected?.length !== 1}
              >
                <span>Edit Rate</span>
              </FormButton>
              <ConfirmationButton
                name="DELETE_RATE"
                icon={<i className="sv sv-trash2 {font-size:1.5rem;}" />}
                feedback={`Delete Rate${selected?.length > 1 ? 's' : ''}`}
                variant="outline-danger"
                onConfirm={handleDeleteRates}
                disabled={!selected?.length || isDeletingRate}
                options={{
                  confirmation: {
                    Body: { message: `Are you sure you wish to delete the selected rate${selected?.length > 1 ? 's' : ''}` },
                  },
                }}
              />
            </>
          );
        }}
      />
      <VirtualTable
        name="provider"
        data={filteredRows}
        loading={loading}
        selected={selected}
        onLazyLoad={hasNextPage && rows?.length < totalCount ? getMore : undefined}
        className="RatesTable"
        header={{
          payerProvider: { name: 'Client' },
          airportCode: 'Airport',
          thatLocation: { name: 'Pick-Up' },
          thisLocation: { name: 'Drop-Off' },
          duration: 'Duration',
          rate: 'Rate',
          currency: 'Currency',
          type: 'Type',
          tripDistance: 'Miles',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected?.length === context.rows?.length : selected.includes(data?.id),
            }}
            className={getClasses(selected?.includes(index) ? 'selected' : '')}
            onDoubleClick={(): void => {
              const selected = Array.from(new Set([data?.id]));
              setState(
                (current: RatesTableState): RatesTableState => ({
                  ...current,
                  selected,
                  ratesForm: { visible: true, rateGroupId: data?.id },
                })
              );
            }}
          >
            <SelectCell className="py-3 {bx:none!;}" onClick={onSelect} />
            <DynamicCell
              selector="payerProvider.name"
              placeholder="--"
              className="text-left py-3 {bx:none!;}"
              width="calc((100% / 7) * 2)"
              sorting={makeSortable('payerProvider.name')}
            />
            <DynamicCell
              selector="airportCode"
              placeholder="--"
              className="text-left py-3 {bx:none!;}"
              width="calc(100% / 7)"
              sorting={makeSortable('airportCode')}
            />
            <DynamicCell
              selector="thatLocation.name"
              placeholder="--"
              className="text-left py-3 {bx:none!;}"
              width="calc((100% / 7) * 2)"
              sorting={makeSortable('thatLocation.name')}
            />
            <DynamicCell
              selector="thisLocation.name"
              placeholder="--"
              className="text-left py-3 {bx:none!;}"
              width="calc((100% / 7) * 2)"
              sorting={makeSortable('thisLocation.name')}
            />
            {/* <DynamicCell
              selector="duration"
              placeholder="--"
              className="text-left py-3 {bx:none!;}"
              width="calc(100% / 9)"
              sorting={makeSortable('duration')}
            /> */}
            <DynamicCell
              selector="rate"
              placeholder="--"
              className="text-end py-3 {bx:none!;}"
              width="calc((100% / 7) / 1.5)"
              sorting={makeSortable('rate')}
              render={({ data }) => `$${parseFloat(data?.rate || '0').toFixed(2)}`}
            />
            <DynamicCell
              selector="currency"
              placeholder="--"
              className="text-left py-3 {bx:none!;}"
              width="calc(100% / 7)"
              sorting={makeSortable('currency')}
            />
            <DynamicCell
              selector="type"
              placeholder="--"
              className="text-left py-3 {bx:none!;}"
              width="calc((100% / 7) * 1.5)"
              sorting={makeSortable('type')}
              render={({ value }): ReactNode => <RateTypeCell data={data as LatestRate} value={value} displayValue={locale(value)} />}
            />
            {/* <DynamicCell
              selector="tripDistance"
              placeholder="--"
              className="text-left py-3 {bx:none!;}"
              width="calc((100% / 9) / 1.5)"
              sorting={makeSortable('tripDistance')}
            /> */}
          </VirtualTableRow>
        )}
      />
      <EditRatesForm
        show={ratesForm.visible}
        value={ratesForm.rateGroupId}
        selected={ratesForm.rateGroupId ? [{ id: ratesForm.rateGroupId }] : undefined}
        onSubmit={handleHideRatesForm}
        onHide={handleHideRatesForm}
        modal
        drawer
        as="drawer"
      />
    </>
  );
};

export default RatesTable;
