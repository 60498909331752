import { RefObject, useEffect } from 'react';

const useMutationObserver = (ref: RefObject<HTMLElement>, effect: MutationCallback, options?: MutationObserverInit): void => {
  useEffect((): (() => void) => {
    if (!ref.current) return;
    const observer = new MutationObserver(effect);
    observer.observe(ref.current, {
      attributes: true,
      childList: true,
      subtree: true,
      ...options,
    });

    return (): void => {
      observer.disconnect();
    };
  }, []);
};

export default useMutationObserver;
