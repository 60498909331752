import { Button, ListGroup } from 'react-bootstrap';

import ControlsGroup from '@/components/Header/NavigationDrawer/groups/ControlsGroup';
import Drawer from '@/common/Drawer';
import NavigationDrawerHeader from '@/components/Header/NavigationDrawer/NavigationDrawerHeader';
import NavigationDrawerItem from '@/components/Header/NavigationDrawer/NavigationDrawerItem';
import ServiceIssuesSubMenu from '@/components/Header/NavigationDrawer/groups/ServiceIssuesMenu';
import SettingsSubMenu from '@/components/Header/NavigationDrawer/groups/SettingsMenu';
import { apolloClient } from '@/components/ApolloRoot';
import { createComponentState } from '@/state';
import { logout } from '@/utils';
import { useEffect } from 'react';

export type NavigationDrawerState = {
  show: boolean;
  hotKeys: boolean;
  loading: boolean;
  menu: string;
};
export const initNavigationDrawerState: NavigationDrawerState = {
  show: false,
  hotKeys: false,
  loading: false,
  menu: undefined,
};
export const useNavigationDrawer = createComponentState(initNavigationDrawerState);

const NavigationDrawer = (): JSX.Element => {
  const setState = useNavigationDrawer(({ setState }) => setState);
  const { show } = useNavigationDrawer(({ state: { show, menu } }) => ({
    show: show || !!menu,
  }));

  const handleLogout = async () => {
    setState((current: NavigationDrawerState): NavigationDrawerState => ({ ...current, loading: true }));
    await apolloClient.clearStore();
    logout();
  };
  const handleShow = () => setState((current: NavigationDrawerState): NavigationDrawerState => ({ ...current, show: true }));
  const handleHide = () =>
    setState((current: NavigationDrawerState): NavigationDrawerState => ({ ...current, show: false, menu: undefined }));

  useEffect(() => {
    return () => setState(initNavigationDrawerState);
  }, []);

  return (
    <>
      <Button className="NavigationDrawer-Button" variant="icon" onClick={handleShow}>
        <i className="sv sv-menu" />
      </Button>
      <Drawer className="NavigationDrawer" show={show} onHide={handleHide} placement="left" header={NavigationDrawerHeader}>
        <ListGroup className="NavigationDrawer-ItemGroup">
          <NavigationDrawerItem name="Trips" icon={<i className="sv sv-earth" />} to="/" restricted />
          <NavigationDrawerItem name="Manifests" icon={<i className="sv sv-document" />} to="/manifests?type=MANIFEST" restricted />
          <NavigationDrawerItem name="Add-Ons" icon={<i className="sv sv-folder-plus" />} to="/manifests?type=ADDON" restricted />
          <NavigationDrawerItem name="Manifest History" icon={<i className="sv sv-calendar-31" />} to="/imports/history" restricted />
          <NavigationDrawerItem name="Availability" icon={<i className="sv sv-calendar-full" />} to="/availability" restricted />
          <NavigationDrawerItem name="Locations" icon={<i className="sv sv-network" />} to="/locations" restricted />
          <NavigationDrawerItem name="Message Center" icon={<i className="sv sv-bubbles" />} to="/message-center" restricted />
          <NavigationDrawerItem name="Invoice" icon={<i className="sv sv-receipt" />} to="/invoices/0" restricted />
          <NavigationDrawerItem name="Invoice History" icon={<i className="sv sv-invoice-history" />} to="/invoices" restricted />
          <NavigationDrawerItem name="Rates" icon={<i className="sv sv-tags" />} to="/rates" restricted />
          <NavigationDrawerItem name="Reports" icon={<i className="sv sv-chart-growth" />} to="/reports" restricted />
          <ServiceIssuesSubMenu />
          <NavigationDrawerItem name="Contract Rates" icon={<i className="sv sv-tags" />} to="/contract-rates" restricted />
          <NavigationDrawerItem name="Users" icon={<i className="sv sv-users2" />} to="/users" restricted />
          <NavigationDrawerItem name="Airlines" icon={<i className="sv sv-plane" />} to="/airlines" restricted />
          <NavigationDrawerItem name="Clients" icon={<i className="sv sv-profile" />} to="/clients" restricted />
          <NavigationDrawerItem name="Vehicles" icon={<i className="sv sv-car" />} to="/vehicles" restricted />
          <NavigationDrawerItem name="Permissions" icon={<i className="sv sv-user-lock" />} to="/permissions" restricted />
          <NavigationDrawerItem name="Complaints" icon={<i className="sv sv-fcr" />} to="/complaints" restricted />
          <NavigationDrawerItem name="Ratings" icon={<i className="sv sv-star" />} to="/ratings" restricted />
          <NavigationDrawerItem name="Punch Policy" icon={<i className="sv sv-watch" />} to="/punch-policy" restricted />
          <NavigationDrawerItem name="Report a Problem" icon={<i className="sv sv-shield-alert" />} to="/report" restricted />
          <SettingsSubMenu />
          <ControlsGroup />
          <NavigationDrawerItem name="Logout" icon={<i className="fa fa-sign-out" />} onClick={handleLogout} />
        </ListGroup>
      </Drawer>
    </>
  );
};

export default NavigationDrawer;
