import './styles.scss';

import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { EnumDropdownField, getItemsFromEnum } from '@/components/EnumDropdown';

import { AirportDropdownField } from '@/components/AirportDropdown';
import Form from '@/common/Form';
import { TextAreaField } from '@/components/TextArea';
import { UserDropdownField } from '@/components/UserDropdown';
import useLocale from '@/hooks/useLocale';

const SummaryHistoryForm = ({ onSubmit, locale: customLocale, value = {} }: SummaryHistoryFormDefinitions.Props): JSX.Element => {
  const locale = useLocale({
    ...SummaryHistoryFormDefinitions.getLocale(value),
    ...customLocale,
  });
  const handleSubmit = async (data) => {
    data = await onSubmit?.(data);
    // await Call BE and submit the data.
  };

  //Remove these enums after form is wired up to backend
  enum TEMP_CameraWorkingEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TEMP_CallVolumeEnum {
    MINIMAL = 'Minimal',
    NOMINAL = 'Nominal',
    ELEVATED = 'Elevated',
  }
  enum TEMP_ShiftsEnum {
    MORNING = 'Morning',
    MIDDAY = 'Midday',
    MIDNIGHT = 'Midnight',
    OTHER = 'Other',
  }

  return (
    <Form className="SummaryHistoryForm" onSubmit={handleSubmit} value={SummaryHistoryFormDefinitions.initValue}>
      <Row className="SummaryHistoryFormHeader">
        <Col>
          <h4 className="SummaryHistoryFormTitle">{locale('Dispatch Summary')}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input
            as={EnumDropdownField}
            name="callVolume"
            label={locale('Call Volume')}
            enum={getItemsFromEnum(TEMP_CallVolumeEnum)}
            options={{
              locale: {
                Minimal: locale('Minimal - Under 5 calls per hour'),
                Nominal: locale('Nominal - Under 10 calls per hour'),
                Elevated: locale('Elevated - Over 10 calls per hour'),
              },
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={UserDropdownField} name="dispatcher" label={locale('Dispatcher')} />
        </Col>
        <Col xs={6}>
          <Form.Input as={EnumDropdownField} name="shift" label={locale('Your Shift')} enum={getItemsFromEnum(TEMP_ShiftsEnum)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="overview" label={locale('Overview Of The Day')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="passdownDetails" label={locale('Passdown Completed? By Whom?')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="eightByEightIssue" label={locale('Was There An 8X8 Issue Today? Explain')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input
            as={EnumDropdownField}
            name="samsaraCamerasWorking"
            label={locale('Were All Samsara Cameras Working In All Stations?')}
            enum={TEMP_CameraWorkingEnum}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="cameraIssueDetails" label={locale('If No, List Stations And Van Numbers Not Working')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="softwareIssueDetails" label={locale('Any Issues With New Software? Please Explain')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="serviceIssuesByStation" label={locale('Service Issues By Station')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="vanIssuesByStation" label={locale('Van Issues By Station')} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={AirportDropdownField} name="station" label={locale('Station')} />
        </Col>
      </Row>
      <Row className="SummaryHistoryFormSectionHeader">
        <Col>
          <h4 className="SummaryHistoryFormCommentsTitle">{locale('Comments And Changes By City')}</h4>
        </Col>
      </Row>
      <Form.Watch name="commentsByCity">
        {(commentsByCity, form) => {
          const handleAddCity = (city) => {
            if (!city) return;
            form.setValue(`commentsByCity.${city}`, '', { shouldValidate: true });
          };
          const handleRemoveCity = (city) => {
            const current = form.getValues(`commentsByCity`);
            delete current[city];
            form.setValue(`commentsByCity`, current || {}, { shouldValidate: true });
          };
          return (
            <>
              {Object.entries(commentsByCity || {}).map(([key, value], index) => {
                return (
                  <Row key={index}>
                    <Col>
                      <InputGroup>
                        <Form.Input as={TextAreaField} className="CommentsByCity" value={value} name={`${key}Comments`} label={key} />
                        <Button variant="icon" onClick={() => handleRemoveCity(key)}>
                          <i className="sv sv-trash2 text-danger" />
                        </Button>
                      </InputGroup>
                    </Col>
                  </Row>
                );
              })}
              <Row>
                <Col>
                  <AirportDropdownField value="" name="addCity" placeholder={locale('Add City')} onChange={handleAddCity} />
                </Col>
              </Row>
            </>
          );
        }}
      </Form.Watch>

      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="alternateTransport" label={locale('Taxis/Lyft By Station')} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input
            as={TextAreaField}
            name="trainingIssues"
            label={locale('DAL Training Issues By Class')}
            placeholder={locale('Detailed Explanation')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Input
            as={TextAreaField}
            name="drivingIssues"
            label={locale('Driving Issues By Station')}
            placeholder={locale('Detailed Explanation')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            name="SUBMIT"
            variant="success"
            onClick={handleSubmit}
            style={{ width: '100%', textTransform: 'uppercase', marginBottom: '1rem' }}
          >
            {locale('Create Dispatch Summary')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export namespace SummaryHistoryFormDefinitions {
  export type Locale = {
    SUBMIT_BUTTON_TEXT?: string;
    'Dispatch Summary'?: string;
    'Call Volume'?: string;
    'Minimal - Under 5 calls per hour'?: string;
    'Nominal - Under 10 calls per hour'?: string;
    'Elevated - Over 10 calls per hour'?: string;
    'Create Dispatch Summary'?: string;
    Dispatcher?: string;
    'Your Shift'?: string;
    'Overview Of The Day'?: string;
    'Passdown Completed? By Whom?'?: string;
    'Was There An 8X8 Issue Today? Explain'?: string;
    'Were All Samsara Cameras Working In All Stations?'?: string;
    'If No, List Stations And Van Numbers Not Working'?: string;
    'Any Issues With New Software? Please Explain'?: string;
    'Service Issues By Station'?: string;
    'Van Issues By Station'?: string;
    Station?: string;
    'Comments And Changes By City'?: string;
    'Add City'?: string;
    'Taxis/Lyft By Station'?: string;
    'DAL Training Issues By Class'?: string;
    'Driving Issues By Station'?: string;
    'Detailed Explanation'?: string;
  };
  export type Value = {
    id: string;
    callVolume: string;
    dispatcher: string;
    shift: string;
    overview: string;
    passdownDetails: string;
    eightByEightIssue: string;
    samsaraCamerasWorking: string;
    cameraIssueDetails: string;
    softwareIssueDetails: string;
    serviceIssuesByStation: string;
    vanIssuesByStation: string;
    station: string;
    //Upload Fields
    alternateTransport: string;
    trainingIssues: string;
    drivingIssues: string;
    commentsByCity: Record<string, string>;
  };
  export type Props = {
    onSubmit?: (data: Value) => void | Value | Promise<Value> | undefined;
    locale?: Partial<Locale>;
    value?: Partial<Value>;
  };
  export const initValue: Value = {
    id: undefined,
    callVolume: '',
    dispatcher: '',
    shift: '',
    overview: '',
    passdownDetails: '',
    eightByEightIssue: '',
    samsaraCamerasWorking: '',
    cameraIssueDetails: '',
    softwareIssueDetails: '',
    serviceIssuesByStation: '',
    vanIssuesByStation: '',
    station: '',
    //City Fields
    alternateTransport: '',
    trainingIssues: '',
    drivingIssues: '',
    commentsByCity: {},
  };
  export const getLocale = (value: Partial<Value>): Partial<Locale> => ({
    SUBMIT_BUTTON_TEXT: value.id ? 'Edit Summary' : 'Create Summary',
  });
}

export default SummaryHistoryForm;
