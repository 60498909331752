import Dropdown, { DropdownProps } from '@/components/Dropdown';

import { LostAndFoundIssueWithCacheReturnStatusEnum } from '@/models/gen/graphql';
import { fieldWrapper } from '@/components/Field';

export type ReturnStatusDropdownProps = Omit<DropdownProps, 'items'>;
const ReturnStatusDropdown = ({ ...dropdownProps }: ReturnStatusDropdownProps): JSX.Element => (
  <Dropdown
    {...dropdownProps}
    items={[
      {
        label: 'Reported Lost',
        display: <span className="text-danger">Reported Lost</span>,
        value: LostAndFoundIssueWithCacheReturnStatusEnum.Created,
      },
      {
        label: 'Unclaimed',
        display: <span className="text-danger">Unclaimed</span>,
        value: LostAndFoundIssueWithCacheReturnStatusEnum.Pending,
      },
      {
        label: 'Shipped',
        display: <span className="text-success">Shipped</span>,
        value: LostAndFoundIssueWithCacheReturnStatusEnum.Shipped,
      },
      // { label: 'Not Found', display: <span className="text-danger">Not Found</span>, value: 'NOT_FOUND' },
      {
        label: 'Returned',
        display: <span className="text-success">Returned</span>,
        value: LostAndFoundIssueWithCacheReturnStatusEnum.Returned,
      },
    ]}
  />
);
export const ReturnStatusDropdownField = fieldWrapper<ReturnStatusDropdownProps>(ReturnStatusDropdown);
export default ReturnStatusDropdown;
