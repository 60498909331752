import './styles.scss';

import ActionsDropdown, { ActionsDropdownProps } from '@/components/ActionsDropdown';

import { ItemType } from 'antd/es/menu/interface';
import { useMemo } from 'react';

export type PopupMenuDropdownProps = { items?: ActionsDropdownProps['items'] } & Omit<ActionsDropdownProps, 'items'>;
const PopupMenuDropdown = ({ items = [], onClick, ...props }: PopupMenuDropdownProps): JSX.Element => {
  const actions = useMemo<ItemType[]>((): ItemType[] => {
    const views: ItemType[] = [
      { label: 'Drawer', key: 'drawer' },
      { label: 'Modal', key: 'modal' },
    ];
    if (!items?.length) return views;
    return [
      ...items,
      { type: 'divider' },
      {
        key: 'view',
        label: 'View',
        children: views,
      },
    ];
  }, [items]);

  const handleClick = (key: string): void => {
    if (key === 'drawer' || key === 'modal') onClick(key);
  };

  return <ActionsDropdown {...props} onClick={handleClick} items={actions} />;
};

export default PopupMenuDropdown;
