import { ListGroup, ListGroupItemProps } from 'react-bootstrap';
import { MouseEvent, useMemo } from 'react';
import { initNavigationDrawerState, useNavigationDrawer } from '@/components/Header/NavigationDrawer';
import { Config } from '@/state/session';

import { Link } from 'react-router-dom';
import { getClasses } from '@/utils';
import useHasPermission from '@/hooks/useHasPermission';
import useHasRoute from '@/hooks/useHasRoute';

export type NavigationDrawerItemProps = {
  name: string;
  icon?: JSX.Element;
  to?: string;
  open?: boolean;
  permissions?: (keyof Config['auth'])[];
  restricted?: boolean;
} & ListGroupItemProps;

const NavigationDrawerItem = ({
  name,
  icon,
  to,
  open,
  permissions,
  restricted,
  onClick,
  children,
  ...listGroupItemProps
}: NavigationDrawerItemProps): JSX.Element => {
  const setState = useNavigationDrawer(({ setState }) => setState);
  const hasPermission = useRoutePermission(to, permissions, restricted);
  const handleClick = (event: MouseEvent): void => {
    if (!onClick) return console.warn('No onClick provided for NavigationDrawerItem.');
    return onClick(event);
  };

  const [destination, reload] = useMemo((): [string, boolean] => {
    if (onClick || !to || !hasPermission) return [undefined, false];
    const currentPath = window.location.pathname;
    const searchObj = new URLSearchParams(window.location.search);
    const [path, pathSearch] = to.split('?');
    const pathSearchObj = new URLSearchParams(pathSearch);
    const samePath = currentPath === path;
    const mustReload = samePath || to.includes('?');
    const search = samePath
      ? new URLSearchParams({ ...Object.fromEntries(searchObj), ...Object.fromEntries(pathSearchObj) })
      : pathSearchObj;
    const searchString = search.toString();
    const result = searchString ? `${path}?${searchString}` : path;
    return [result, mustReload];
  }, []);

  const itemProps = useMemo(() => {
    if (!to) return { onClick: handleClick };
    return {
      to: destination,
      as: Link,
      reloadDocument: reload,
      onClick: () => setState(initNavigationDrawerState),
    };
  }, [to, destination, reload]);

  if (!hasPermission) return null;
  return (
    <ListGroup.Item
      action={true}
      {...listGroupItemProps}
      className={getClasses('NavigationDrawer-Item', open === true ? 'open' : undefined, listGroupItemProps.className)}
      {...itemProps}
    >
      {icon && <span>{icon}</span>}
      {name && <span>{name}</span>}
      {open !== undefined && <i className="sv sv-chevron-right" />}
    </ListGroup.Item>
  );
};

export const useRoutePermission = (path?: string, permissions?: (keyof Config['auth'])[], enforce?: boolean): boolean => {
  const hasPermission = useHasPermission(permissions);
  const routeEnabled = useHasRoute(path);
  return !enforce || (hasPermission && routeEnabled);
};

export default NavigationDrawerItem;
