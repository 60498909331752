import './styles.scss';

import { LostAndFoundHistoryRow, useLostAndFoundHistory } from '@/features/LostAndFoundHistory';

import LostAndFoundHistoryTableColumns from '@/features/LostAndFoundHistory/components/LostAndFoundHistoryTable/LostAndFoundHistoryTableColumns';
import LostAndFoundHistoryTableRow from '@/features/LostAndFoundHistory/components/LostAndFoundHistoryTable/LostAndFoundHistoryTableRow';
import SimpleTable from '@/components/SimpleTable';
import { SortDirectionEnum } from '@/models/gen/graphql';

const LostAndFoundHistoryTable = (): JSX.Element => {
  const [{ reports: sortedReports }] = useLostAndFoundHistory(({ reports, sortBy, sortDirection }) => ({
    reports: Array.from(reports.keys()).sort((a: string, b: string): number => {
      if (!sortDirection || !sortBy) return 0;
      const sortReports = compareReportsBy(sortBy);
      const result = sortReports(reports.get(a), reports.get(b));
      if (sortDirection === SortDirectionEnum.Desc) return -result;
      return result;
    }),
  }));

  return (
    <SimpleTable
      className="LostAndFoundHistoryTable"
      rows={sortedReports}
      columns={LostAndFoundHistoryTableColumns}
      row={LostAndFoundHistoryTableRow}
    />
  );
};

const compareReportsBy =
  (sortBy: string) =>
  (reportA: LostAndFoundHistoryRow, reportB: LostAndFoundHistoryRow): number => {
    if (!reportA || !reportB) return 0;
    switch (sortBy) {
      default:
        return 0;
    }
  };

export default LostAndFoundHistoryTable;
