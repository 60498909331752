import { DATETIME_FORMAT, TIME_FORMAT } from '@/constants';
import { Datetime, getClasses } from '@/utils';
import { ForwardRefExoticComponent, forwardRef, useCallback } from 'react';

import { Button } from 'react-bootstrap';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';
import Tippy from '@tippyjs/react';
import { useLostAndFoundHistory } from '@/features/LostAndFoundHistory';

const LostAndFoundHistoryTableRow = ({ id }: { id: string }, ref: ForwardRefExoticComponent<HTMLDivElement>): JSX.Element => {
  const [{ report, selected }, setState] = useLostAndFoundHistory(({ reports, selected }) => ({
    report: reports.get(id),
    selected: selected.has(id),
  }));

  const onSelect = useCallback(
    (): void =>
      setState((current) => {
        const newSelected = new Map(current.selected);
        if (newSelected.has(id)) newSelected.delete(id);
        else newSelected.set(id, current.reports.get(id));
        return { ...current, selected: newSelected };
      }),
    [report, id, setState]
  );
  const onEdit = useCallback((): void => {
    console.log('ON_EDIT', id);
  }, [id]);
  const onDelete = useCallback((): void => {
    console.log('ON_DELETE', id);
  }, [id]);

  return (
    <SimpleTableRow id={id} className={getClasses(selected ? 'SELECTED' : undefined)} onDoubleClick={onEdit} ref={ref}>
      <SimpleTableCell.Select checked={selected} onChange={onSelect} />
      <SimpleTableCell name="AIRPORT">{report.airport}</SimpleTableCell>
      <SimpleTableCell name="DATETIME">{new Datetime(report.datetime).format(DATETIME_FORMAT)}</SimpleTableCell>
      <SimpleTableCell name="DISPATCHER">{new Datetime(report.dispatcher).format(TIME_FORMAT)}</SimpleTableCell>
      <SimpleTableCell name="AIRLINE">{report.airline}</SimpleTableCell>
      <SimpleTableCell name="FLIGHT_NUMBER">{report.flightNumber}</SimpleTableCell>
      <SimpleTableCell name="VAN">{report.van}</SimpleTableCell>
      <SimpleTableCell name="DRIVER">{report.driver}</SimpleTableCell>
      <SimpleTableCell name="DESCRIPTION">{report.description}</SimpleTableCell>
      <SimpleTableCell name="STATUS">{report.status}</SimpleTableCell>
      <SimpleTableCell name="EDIT">
        <Tippy content="Edit Report">
          <Button name="EDIT" variant="icon" onClick={onEdit}>
            <i className="sv sv-pencil" />
          </Button>
        </Tippy>
      </SimpleTableCell>
      <SimpleTableCell name="DELETE">
        <Tippy content="Delete Report">
          <Button name="DELETE" variant="icon" onClick={onDelete}>
            <i className="sv sv-trash2" />
          </Button>
        </Tippy>
      </SimpleTableCell>
    </SimpleTableRow>
  );
};

export default forwardRef(LostAndFoundHistoryTableRow);
