import './styles.scss';

import Drawer, { DrawerProps } from '@/common/Drawer';
import { getClasses, pixelsToRem } from '@/utils';

import IncidentHistoryForm from '@/features/IncidentHistory/components/IncidentHistoryForm';
import { createComponentState } from '@/state';

export type IncidentHistoryDrawerState = {
  show: boolean;
};
export const initIncidentHistoryState: IncidentHistoryDrawerState = {
  show: false,
};
export const useIncidentHistoryDrawer = createComponentState<IncidentHistoryDrawerState>(initIncidentHistoryState);
export type IncidentHistoryDrawerProps = { locale?: Record<string, string> } & DrawerProps;
const IncidentHistoryDrawer = ({ locale: customLocale, ...drawerProps }: IncidentHistoryDrawerProps): JSX.Element => {
  const [state, setState] = useIncidentHistoryDrawer(({ state, setState }) => [state, setState]);
  const { show } = state;

  const onHide = (): void => setState({ ...state, show: false });
  const afterClose = (): void => setState(initIncidentHistoryState);
  const onSubmit = (data): void => {
    console.log('Submit Incident', data);
    onHide();
  };

  return (
    <Drawer
      {...drawerProps}
      className={getClasses('IncidentHistoryDrawer', drawerProps.className)}
      show={show}
      onHide={onHide}
      afterClose={afterClose}
      width={pixelsToRem(549)}
    >
      <IncidentHistoryForm onSubmit={onSubmit} />
    </Drawer>
  );
};

export default IncidentHistoryDrawer;
