import { useCallback, useMemo } from 'react';

import FormButton from '@/components/FormButton';
import HasPermission from '@/components/HasPermission';
import useConfirmation from '@/hooks/useConfirmation';
import useTripTableState from '@/features/Trips/components/TripsTable/hook';

type TripsTableShortcutsProps = {
  deleteAll: () => void;
  uaCancels: () => void;
  zeroRates: () => void;
  undeleteTrips: (tripIds: string[]) => void;
  hardDeleteTrips: (tripIds: string[]) => void;
};
const TripsTableShortcuts = ({
  deleteAll,
  uaCancels,
  zeroRates,
  undeleteTrips,
  hardDeleteTrips,
}: TripsTableShortcutsProps): React.JSX.Element => {
  const selectedTrips = useTripTableState(({ state }) => state.selected);
  const disabled = useTripTableState(({ state }) => state.shortcutsDisabled);

  const selectedDeletedTrips = useMemo((): string[] => {
    if (!selectedTrips?.size) return [];
    return [...selectedTrips.keys()].filter((id: string): boolean => !!selectedTrips.get(id)?.deletedAt);
  }, [selectedTrips]);

  const confirmUndelete = useConfirmation({ Body: { message: 'Are you sure you want to un-delete the selected trip(s)?' } });
  const confirmHardDelete = useConfirmation({ Body: { message: 'Are you sure you want to permanently delete the selected trip(s)?' } });

  const handleUndeleteTrips = useCallback(async (): Promise<void> => {
    try {
      await confirmUndelete();
      undeleteTrips(selectedDeletedTrips);
    } catch (error) {
      console.error(error);
    }
  }, [confirmUndelete, selectedDeletedTrips, undeleteTrips]);

  const handleHardDeleteTrips = useCallback(async (): Promise<void> => {
    try {
      await confirmHardDelete();
      hardDeleteTrips(selectedDeletedTrips);
    } catch (error) {
      console.error(error);
    }
  }, [confirmHardDelete, selectedDeletedTrips, hardDeleteTrips]);

  return (
    <>
      <HasPermission name="allowViewTripShortcuts">
        <FormButton variant="icon" onClick={deleteAll} icon={<i className="sv sv-trash2" />} feedback="Delete All" disabled={disabled}>
          Delete All
        </FormButton>
        <FormButton
          variant="icon"
          onClick={uaCancels}
          icon={<i className="sv sv-calendar-cross" />}
          feedback="UA Cancels"
          disabled={disabled}
        >
          UA Cancels
        </FormButton>
        <FormButton variant="icon" onClick={zeroRates} icon={<i className="sv sv-prohibited" />} feedback="Zero Rates" disabled={disabled}>
          Zero Rates
        </FormButton>
      </HasPermission>
      <HasPermission name="allowUndeleteTrip">
        {selectedDeletedTrips.length > 0 && (
          <FormButton
            variant="icon"
            onClick={handleUndeleteTrips}
            icon={<i className="sv sv-undo2 fs-5" />}
            feedback="Undelete"
            disabled={disabled}
          >
            Undelete
          </FormButton>
        )}
      </HasPermission>
      <HasPermission name="allowDeleteTripHard">
        {selectedDeletedTrips.length > 0 && (
          <FormButton
            variant="icon"
            onClick={handleHardDeleteTrips}
            icon={<i className="sv sv-shredder fs-5" />}
            feedback="Delete Permanently"
            disabled={disabled}
          >
            Delete Permanently
          </FormButton>
        )}
      </HasPermission>
    </>
  );
};

export default TripsTableShortcuts;
