import { Datetime, changeState } from '@/utils';
import { IssueHistoryContext, initIssueHistoryState, useIssueHistory } from '@/features/IssueHistory';
import { TODAY, TODAY_EOD } from '@/constants';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import AirportDropdown from '@/components/AirportDropdown';
import BooleanDropdown from '@/common/BooleanDropdown';
import { Button } from 'react-bootstrap';
import DateRangePicker from '@/components/DateRangePicker';
import FormButton from '@/components/FormButton';
import { InputWithIcon } from '@/components/Input';
import IssueHistoryFiltersInfo from '@/features/IssueHistory/components/IssueHistoryFilters/IssueHistoryFiltersInfo';
import PageNavigationDropdown from '@/common/PageNavigationDropdown';
import QueryFilters from '@/components/QueryFilters';
import { useCallback } from 'react';
import { useIssueHistoryDrawer } from '../IssueHistoryDrawer';

// TODO: Add appropriate filters to this component based on the design when it is complete.
const IssueHistoryFilters = (): JSX.Element => {
  const [{ filters, loading }, setState, { submitFilters }] = useIssueHistory(({ filters, loading }) => ({ filters, loading }));
  const resetFilters = useCallback(() => setState((current) => ({ ...current, filters: initIssueHistoryState.filters })), [setState]);
  const { startDatetime, endDatetime, airports, airlines, taxiCalled, search } = filters;
  const setIssueHistoryDrawerState = useIssueHistoryDrawer(({ setState }) => setState);

  const onChange = changeState<IssueHistoryContext>(setState, {
    path: 'filters.*',
    handlers: {
      datetime: (value, setState) => {
        const [from, to] = (value?.target?.value?.split(' - ') || [TODAY, TODAY_EOD]).map((date: string): string =>
          new Datetime(date).toString()
        );
        setState((current) => ({
          ...current,
          filters: { ...current.filters, startDatetime: from, endDatetime: to },
        }));
      },
    },
  });

  const onCreate = () => {
    console.log('Create Dispatch Issue');
    setIssueHistoryDrawerState({ show: true });
  };

  const onOpenSettings = () => {
    console.log('Open Settings');
  };

  return (
    <>
      <QueryFilters className="AccidentHistoryFilters justify-content-between">
        <div className="d-flex gap-2">
          <QueryFilters.Control>
            <DateRangePicker value={[startDatetime, endDatetime]} onChange={onChange('datetime')} />
          </QueryFilters.Control>
          <QueryFilters.Input>
            <AirportDropdown.Multi value={airports} onChange={onChange('airports')} options={{ locale: { 'Select...': 'Airports' } }} />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <AirlineIataDropdown.Multi value={airlines} onChange={onChange('airlines')} />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <BooleanDropdown value={taxiCalled} onChange={onChange('taxiCalled')} options={{ locale: { 'Select...': 'Taxi Called?' } }} />
          </QueryFilters.Input>
          <QueryFilters.Control>
            <Button className="border-white" name="SUBMIT" variant="success" onClick={submitFilters} disabled={loading}>
              {loading && <i className="fa fa-spinner fa-pulse" />}
              {!loading && <span>Go</span>}
            </Button>
            <Button name="RESET" variant="outline-secondary-subtle" onClick={resetFilters}>
              Reset
            </Button>
          </QueryFilters.Control>
          <QueryFilters.Control>
            <InputWithIcon
              icon={<i className="sv sv-magnifier fs-4" />}
              name="search"
              value={search || ''}
              onChange={onChange('search')}
              placeholder="Search"
            />
          </QueryFilters.Control>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <QueryFilters.Control>
            <FormButton
              maxWidth={1560}
              icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
              name="CREATE_DISPATCH_ISSUE"
              variant="outline-secondary-subtle"
              onClick={onCreate}
            >
              Create Dispatch Issue
            </FormButton>
          </QueryFilters.Control>
          <QueryFilters.Input>
            <PageNavigationDropdown />
          </QueryFilters.Input>
          <QueryFilters.Control>
            <FormButton
              variant="outline-secondary-subtle"
              onClick={onOpenSettings}
              icon={<i className="sv sv-cog {font-size:1.5rem;}" />}
              feedback="Dispatch Issue History Page Settings & Preferences"
            />
          </QueryFilters.Control>
        </div>
      </QueryFilters>
      <IssueHistoryFiltersInfo />
    </>
  );
};

export default IssueHistoryFilters;
