import { FilterOption } from '@/models/gen/graphql';
import { GetStatesDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetStatesGraphApiResponse = GraphApiResponse<typeof GetStatesDocument>;

const getStatesResponseSelector = (res: GetStatesGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.states as Array<FilterOption>;

const [runGetStates] = graphApi(GetStatesDocument, {
  onError: (res: GetStatesGraphApiResponse): void => handleError(res, { notification: { title: 'Get States' } }),
});

export const getStates = async (): Promise<Array<FilterOption>> => {
  const res = await runGetStates();
  return getStatesResponseSelector(res);
};

export const useGetStates = createGraphApiHook(getStates);
