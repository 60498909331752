import NavigationDrawerItem, { NavigationDrawerItemProps } from '@/components/Header/NavigationDrawer/NavigationDrawerItem';

import Drawer from '@/common/Drawer';
import { useNavigationDrawer } from '@/components/Header/NavigationDrawer';

const NavigationDrawerSubMenu = ({ children, ...navigationDrawerItemProps }: NavigationDrawerItemProps): JSX.Element => {
  const { show, menu } = useNavigationDrawer(({ state: { show, menu } }) => ({ show, menu }));
  const setState = useNavigationDrawer(({ setState }) => setState);

  const onToggle = () =>
    setState((current) => ({
      ...current,
      menu: navigationDrawerItemProps.name !== current.menu ? navigationDrawerItemProps.name : undefined,
    }));

  return (
    <>
      <NavigationDrawerItem {...navigationDrawerItemProps} open={menu === navigationDrawerItemProps.name} onClick={onToggle} />
      <Drawer
        className="Drawer-Menu NavigationDrawer"
        show={show && menu === navigationDrawerItemProps.name}
        onHide={onToggle}
        placement="left"
        backdropClassName="bg-transparent"
      >
        {children}
      </Drawer>
    </>
  );
};

export default NavigationDrawerSubMenu;
