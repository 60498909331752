import './styles.scss';

import { AccidentHistoryRow, useAccidentHistory } from '@/features/AccidentHistory';

import AccidentHistoryTableColumns from '@/features/AccidentHistory/components/AccidentHistoryTable/AccidentHistoryTableColumns';
import AccidentHistoryTableRow from '@/features/AccidentHistory/components/AccidentHistoryTable/AccidentHistoryTableRow';
import { Datetime } from '@/utils';
import SimpleTable from '@/components/SimpleTable';
import { SortDirectionEnum } from '@/models/gen/graphql';

const AccidentHistoryTable = (): JSX.Element => {
  const [{ accidents: sortedAccidents }] = useAccidentHistory(({ accidents, sortBy, sortDirection }) => ({
    accidents: Array.from(accidents.keys()).sort((a: string, b: string): number => {
      if (!sortDirection || !sortBy) return 0;
      const sortAccidents = compareAccidentsBy(sortBy);
      const result = sortAccidents(accidents.get(a), accidents.get(b));
      if (sortDirection === SortDirectionEnum.Desc) return -result;
      return result;
    }),
  }));

  return (
    <SimpleTable
      className="AccidentHistoryTable"
      rows={sortedAccidents}
      columns={AccidentHistoryTableColumns}
      row={AccidentHistoryTableRow}
    />
  );
};

const compareAccidentsBy =
  (sortBy: string) =>
  (accidentA: AccidentHistoryRow, accidentB: AccidentHistoryRow): number => {
    if (!accidentA || !accidentB) return 0;
    switch (sortBy) {
      case 'AIRPORT':
        return accidentA.airport.localeCompare(accidentB.airport);
      case 'DATETIME':
        return new Datetime(accidentA.datetime).diff(accidentB.datetime, 'millisecond');
      case 'AIRLINE':
        return accidentA.airline.localeCompare(accidentB.airline);
      case 'FLIGHT_NUMBER':
        return accidentA.flightNumber.localeCompare(accidentB.flightNumber);
      case 'SCHEDULED_TIME':
        return new Datetime(accidentA.scheduledTime).diff(accidentB.scheduledTime, 'millisecond');
      case 'VAN':
        return accidentA.van.localeCompare(accidentB.van);
      case 'EMPLOYEE':
        return accidentA.employee.localeCompare(accidentB.employee);
      case 'ID':
        return accidentA.idNumber.localeCompare(accidentB.idNumber);
      case 'MANAGER':
        return accidentA.manager.localeCompare(accidentB.manager);
      case 'INJURY':
        return accidentA.injury === accidentB.injury ? 0 : accidentA.injury ? -1 : 1;
      case 'CREW_INVOLVED':
        return accidentA.crewInvolved === accidentB.crewInvolved ? 0 : accidentA.crewInvolved ? -1 : 1;
      default:
        return 0;
    }
  };

export default AccidentHistoryTable;
