// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CurrencyInput {
  position: relative;
}
.CurrencyInput .Input {
  padding-left: 1.5rem;
}
.CurrencyInput:before {
  content: "\$";
  position: absolute;
  display: block;
  z-index: 1;
  height: 100%;
  margin: 1px;
  padding: 0.375rem 0.75rem;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/components/CurrencyInput/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,oBAAA;AAEJ;AAAE;EACE,YAAA;EACA,kBAAA;EACA,cAAA;EACA,UAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,oBAAA;EACA,yBAAA;AAEJ","sourcesContent":[".CurrencyInput {\n  position: relative;\n}\n.CurrencyInput .Input {\n  padding-left: 1.5rem;\n}\n.CurrencyInput:before {\n  content: \"$\";\n  position: absolute;\n  display: block;\n  z-index: 1;\n  height: 100%;\n  margin: 1px;\n  padding: 0.375rem 0.75rem;\n  pointer-events: none;\n  color: rgba(0, 0, 0, 0.5);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
