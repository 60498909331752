import { LoadingBlur } from '@/components/LoadingSpinner';
import PageInfo from '@/components/PageInfo';
import { useAccidentHistory } from '@/features/AccidentHistory';

const AccidentHistoryFiltersInfo = (): JSX.Element => {
  const [{ accidents, totalAccidents, loading }] = useAccidentHistory(({ accidents, totalAccidents, loading }) => ({
    accidents,
    totalAccidents,
    loading,
  }));

  return (
    <>
      <LoadingBlur label="Loading Accidents" loading={loading} />
      <PageInfo>
        {accidents.size} / {totalAccidents} Accidents
      </PageInfo>
    </>
  );
};

export default AccidentHistoryFiltersInfo;
