import Drawer, { DrawerProps } from '@/common/Drawer';
import Modal, { ModalProps } from '@/common/Modal';
import PopupMenuDropdown, { PopupMenuDropdownProps } from '@/common/Popup/PopupMenuDropdown';
import { getClasses, properCase } from '@/utils';
import { useCallback, useMemo } from 'react';

import useLocalStorage from '@/hooks/useLocalStorage';

export type PopupProps = {
  as?: 'drawer' | 'modal';
  actions?: PopupMenuDropdownProps['items'];
} & ModalProps &
  DrawerProps;

const Popup = ({ as = 'drawer', actions, children, ...modalAndDrawerProps }: PopupProps): JSX.Element => {
  const [display, setDisplay] = useLocalStorage<PopupProps['as']>('lastPopupView', as);
  const As = useMemo(() => {
    switch (display) {
      case 'modal':
        return Modal;
      case 'drawer':
      default:
        return Drawer;
    }
  }, [display]);
  const props = useMemo(() => {
    switch (display) {
      default:
        return modalAndDrawerProps;
    }
  }, [as, modalAndDrawerProps]);
  const ActionsMenu = useCallback(
    (): JSX.Element => <PopupMenuDropdown value={display} onClick={onChangeDisplay} items={actions} />,
    [display, actions]
  );

  const onChangeDisplay = (key: PopupProps['as']): string => setDisplay(key);

  return (
    <As {...props} className={getClasses(`Popup Popup-${properCase(display)}`, props.className)} content={ActionsMenu}>
      {children}
    </As>
  );
};

export default Popup;
