import useSession, { Config } from '@/state/session';
export type PermissionAuth = Omit<Config['auth'], '__typename'>;
const HasPermission = ({
  name,
  inverse,
  children = [],
}: {
  name: keyof PermissionAuth;
  inverse?: boolean;
  children?: any;
}): JSX.Element => {
  const found = useSession(({ state }) => state.config?.auth?.[name] || false);
  if (!found || (inverse !== undefined && found)) return null;
  return children;
};

HasPermission.check = (name: keyof PermissionAuth): boolean => useSession.getState().state.config?.auth?.[name] || false;
HasPermission.checkUserConfig = (page: string, id: string, key: string): boolean => {
  const found = useSession.getState().state.config?.userConfig?.permissions?.find((p) => p.resource === page);
  return found?.sections?.find((s) => s.name === id)?.columns?.find((c) => c.name === key)?.allowView;
};

export const HasGroup = ({ groupId, inverse, children = [] }: { groupId: string; inverse?: boolean; children?: any }): JSX.Element => {
  const found = useSession(({ state }) => !!(state.user?.groups || [])?.find(({ id }) => id === groupId) || false);
  if (!found || (inverse !== undefined && found)) return null;
  return children;
};

export default HasPermission;
