import { useContext, useEffect, useMemo, useState } from 'react';

import { Socket } from 'socket.io-client';
import { SocketContext } from './context';

const wrapHandler = (handler) => (response) => handler(JSON.parse(response));

const useSocket = (events?: Record<string, Function>): Socket => {
  const socket = useContext(SocketContext);
  const [connected, setConnected] = useState(false);

  useEffect((): (() => void) => {
    /* CONNECTION STATE
      This effect will update the connection state when the socket connection changes.
    */
    const isConnected = !!socket?.connected;
    if (!socket || isConnected === connected) return;
    setConnected(isConnected);
  }, [socket?.connected]);

  const wrappedEvents = useMemo((): {} => {
    return Object.entries(events || {}).reduce(
      (acc: {}, [event, handler]: [string, Function]): {} => ({
        ...acc,
        [event]: wrapHandler(handler),
      }),
      {}
    );
  }, [events]);

  useEffect((): (() => void) => {
    /* EVENT LISTENERS
      This effect will add and remove event listeners for the provided events whenever they change.
    */
    Object.entries(wrappedEvents).forEach(([event, handler]: [string, Function]): void => {
      socket.on(event, handler);
    });
    return (): void => {
      Object.entries(wrappedEvents).forEach(([event, handler]: [string, Function]): void => {
        socket.off(event, handler);
      });
    };
  }, [wrappedEvents]);

  useEffect((): (() => void) => {
    /* CLEANUP
      This effect will remove all of the event listeners only when the component unmounts.
    */
    return (): void => {
      Object.entries(events || {}).forEach(([event]: [string, Function]): void => {
        socket.off(event);
      });
    };
  }, []);

  useEffect((): void => {
    /* VISIBILITY
      This effect will update the connection state when the visibility changes.
    */
    if (connected) return;
    socket.connect();
  }, [connected]);

  return socket;
};

export default useSocket;
