import './styles.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { Email, EmailTypeEnum, Provider, ProviderTypeEnum } from '@/models/gen/graphql';
import { Validation, changeState, createNotification, getBulkValues, getProperty, queryInput } from '@/utils';
import { createAirlineWithEmail, updateAirlineWithEmail } from '@/api/services/airlines';
import { useEffect, useMemo } from 'react';

import EditModal from '@/components/EditModal/new';
import FormButton from '@/components/FormButton';
import { InputField } from '@/components/Input';
import Logger from '@/utils/logs';
import { EnumDropdownField } from '@/components/EnumDropdown';
import { Toast } from '@/models';
import { createAirlineValidator } from '@/api/services/airlines/createAirlineBulk';
import useForm from '@/hooks/useForm';
import { useSearchAirlines } from '@/api/services/providers/searchProviders';
import useValidation from '@/hooks/useValidation';
import { PhoneInputField } from '@/components/MaskedInput/PhoneInput';
import { EventObject } from '@/hooks/useOnChange';
import { StateDropdownField } from '@/common/StateDropdown';

const log = Logger.of('EditAirlinesModal');

interface Props {
  show: boolean;
  title?: string;
  name: string;
  onHide?: Function;
  onSubmit?: (updates: any, selected: Provider[]) => void;
  onExited?: Function;
  onCancel?: Function;
  data: any;
  options?: {
    selected?: Provider[];
  };
  loading?: boolean;
}

type EditAirlinesState = Partial<Provider> & {
  billingSameAsPrimary?: boolean;
  newEmail?: string;
  newEmailType?: string;
};

// TOOD: remove this
const initState: EditAirlinesState = { type: ProviderTypeEnum.Airline }; // the state needs to be an object for useValidation to work

const EditAirlinesModal = ({ show, onHide, onSubmit, onCancel, onExited, data: initData, options = {}, ...props }: Props): JSX.Element => {
  const [form, onChange, setForm] = useForm<EditAirlinesState>(initState);
  const [isValid, validity] = useValidation(createAirlineValidator, form);

  const [{ data, loading }, { refetch: searchAirlines }] = useSearchAirlines();
  const provider = useMemo((): Provider => data?.rows?.[0], [data]);

  const mode = initData?.id ? 'edit' : 'create';
  const selected = options?.selected || !!provider ? [provider] : [];
  const selectedCount = selected?.length || 0;
  const fields = useMemo(() => (mode === 'edit' ? getBulkValues(selected) : undefined), [selected, mode]);

  const handleSubmit = async (): Promise<void> => {
    try {
      if (onSubmit) await onSubmit(form, selected);
      else {
        if (mode === 'edit') await updateAirlineWithEmail(form, selected);
        else await createAirlineWithEmail(form);
      }
    } catch (err) {
      log.error('handleSubmit', err);
    } finally {
      await onHide();
    }
  };

  const handleChange = changeState<EditAirlinesState>(setForm, {
    handlers: {
      primaryAddress: (value: string): void =>
        setForm(
          (current: EditAirlinesState): EditAirlinesState => ({
            ...current,
            primaryAddress: value,
            billingAddress: current?.billingSameAsPrimary ? value : current?.billingAddress,
          })
        ),
      primaryCityCode: (value: string): void =>
        setForm(
          (current: EditAirlinesState): EditAirlinesState => ({
            ...current,
            primaryCityCode: value,
            billingCityCode: current?.billingSameAsPrimary ? value : current?.billingCityCode,
          })
        ),
      primaryStateCode: (value: string): void =>
        setForm(
          (current: EditAirlinesState): EditAirlinesState => ({
            ...current,
            primaryStateCode: value,
            billingStateCode: current?.billingSameAsPrimary ? value : current?.billingStateCode,
          })
        ),
      primaryZipCode: (value: string): void =>
        setForm(
          (current: EditAirlinesState): EditAirlinesState => ({
            ...current,
            primaryZipCode: parseInt(value),
            billingZipCode: current?.billingSameAsPrimary ? parseInt(value) : current?.billingZipCode,
          })
        ),
    },
  });

  const handleAddEmail = (event: any): void => {
    // TODO: Add mutation to add email to the location.
    if (!form?.newEmail) return; // TODO: Add Invalid logic
    if (!form?.newEmailType) return; // TODO: Add Invalid logic
    setForm(
      (current: EditAirlinesState): EditAirlinesState => ({
        ...current,
        emails: [...(current.emails || []), { address: form?.newEmail, type: form?.newEmailType } as Email],
        newEmail: '',
        newEmailType: '',
      })
    );
  };

  const handleRemoveEmail = (index: number): void => {
    setForm(
      (current: EditAirlinesState): EditAirlinesState => ({
        ...current,
        emails: current.emails.filter((email: Email, a: number): boolean => a !== index),
      })
    );
  };

  useEffect((): void => {
    if (form?.billingSameAsPrimary) {
      setForm(
        (current: EditAirlinesState): EditAirlinesState => ({
          ...current,
          billingAddress: current?.primaryAddress,
          billingCityCode: current?.primaryCityCode,
          billingStateCode: current?.primaryStateCode,
          billingZipCode: current?.primaryZipCode,
        })
      );
    }
  }, [form?.billingSameAsPrimary]);

  const handleSearch = async (): Promise<void> => {
    if (!initData?.id || !show) return;
    const res = await searchAirlines([{ id: queryInput(initData?.id) }]);
    const provider = res?.rows?.[0];
    if (!provider) return createNotification('Provider not found.', Toast.Type.WARNING, 'Search Airline');
    setForm(provider);
  };

  useEffect((): void => {
    if (show) {
      handleSearch();
    } else {
      setForm(initState);
    }
  }, [show]);

  const title = props?.title || mode === 'edit' ? 'Edit Airline' : 'Create Airline';

  return (
    <EditModal
      show={show}
      title={title}
      icon="fa fa-location-dot"
      size="xl"
      onHide={onHide}
      onCancel={onCancel}
      onExited={onExited}
      onSubmit={isValid ? handleSubmit : false}
      options={undefined}
      loading={props?.loading || loading}
      name="editAirlines"
      className="EditAirlinesModal"
    >
      <Row className="EditAirlines mt-4">
        <Row>
          <Col md={4}>
            <InputField
              label="Legal Name:"
              feedback={validity?.name?.message}
              value={form?.name || ''}
              onChange={handleChange('name')}
              isValid={Validation.convertValidityTypeToBoolean(validity?.name?.valid) === true}
              isInvalid={Validation.convertValidityTypeToBoolean(validity?.name?.valid) === false}
              placeholder="Legal Name"
              disabled={(fields?.name || []).length > 1}
              required
            />
            <InputField
              label="Shortname:"
              feedback={validity?.displayName?.message}
              value={form?.displayName || ''}
              onChange={handleChange('displayName')}
              isValid={Validation.convertValidityTypeToBoolean(validity?.displayName?.valid) === true}
              isInvalid={Validation.convertValidityTypeToBoolean(validity?.displayName?.valid) === false}
              placeholder="Shortname"
              disabled={(fields?.displayName || []).length > 1}
              required
            />
            <InputField
              label="Website:"
              feedback={validity?.website?.message}
              value={form?.website || ''}
              onChange={handleChange('website')}
              isValid={Validation.convertValidityTypeToBoolean(validity?.website?.valid) === true}
              isInvalid={Validation.convertValidityTypeToBoolean(validity?.website?.valid) === false}
              placeholder="Website"
              disabled={(fields?.website || []).length > 1}
            />
          </Col>
          <Col md={4}>
            <InputField
              label="IATA Code:"
              feedback={validity?.iataAirlineCode?.message}
              value={form?.iataAirlineCode || ''}
              onChange={handleChange('iataAirlineCode')}
              isValid={!!Validation.convertValidityTypeToBoolean(validity?.iataAirlineCode?.valid) === true}
              isInvalid={!!Validation.convertValidityTypeToBoolean(validity?.iataAirlineCode?.valid) === false}
              placeholder="IATA Code"
              disabled={(fields?.iataAirlineCode || []).length > 1}
            />
            <InputField
              label="Ref ID:"
              feedback={validity?.referenceNumber?.message}
              value={form?.referenceNumber || ''}
              onChange={handleChange('referenceNumber')}
              isValid={Validation.convertValidityTypeToBoolean(validity?.referenceNumber?.valid) === true}
              isInvalid={Validation.convertValidityTypeToBoolean(validity?.referenceNumber?.valid) === false}
              placeholder="Ref ID"
              disabled={(fields?.referenceNumber || []).length > 1}
            />
            <PhoneInputField
              label="Phone:"
              feedback={validity?.primaryPhoneNumber?.message}
              value={form?.primaryPhoneNumber || ''}
              onChange={handleChange('primaryPhoneNumber')}
              isValid={Validation.convertValidityTypeToBoolean(validity?.primaryPhoneNumber?.valid) === true}
              isInvalid={Validation.convertValidityTypeToBoolean(validity?.primaryPhoneNumber?.valid) === false}
              disabled={(fields?.primaryPhoneNumber || []).length > 1}
            />
            <InputField
              type="email"
              label="Email:"
              placeholder="Email"
              value={form?.primaryEmail || ''}
              onChange={handleChange('primaryEmail')}
              disabled={selectedCount > 1}
              isValid={Validation.convertValidityTypeToBoolean(validity?.primaryEmail?.valid) === true}
              isInvalid={Validation.convertValidityTypeToBoolean(validity?.primaryEmail?.valid) === false}
            />
          </Col>
          <Col md={4}>
            <InputField
              label="Contact Person:"
              feedback={validity?.contactPerson?.message}
              value={form?.contactPerson || ''}
              onChange={handleChange('contactPerson')}
              isValid={Validation.convertValidityTypeToBoolean(validity?.contactPerson?.valid) === true}
              isInvalid={Validation.convertValidityTypeToBoolean(validity?.contactPerson?.valid) === false}
              placeholder="Contact Person"
              disabled={(fields?.contactPerson || []).length > 1}
            />
            <PhoneInputField
              label="Mobile Phone:"
              feedback={validity?.mobilePhoneNumber?.message}
              value={form?.mobilePhoneNumber || ''}
              onChange={handleChange('mobilePhoneNumber')}
              isValid={Validation.convertValidityTypeToBoolean(validity?.mobilePhoneNumber?.valid) === true}
              isInvalid={Validation.convertValidityTypeToBoolean(validity?.mobilePhoneNumber?.valid) === false}
              disabled={(fields?.mobilePhoneNumber || []).length > 1}
            />
            <PhoneInputField
              label="Occ Phone:"
              feedback={validity?.occPhoneNumber?.message}
              value={form?.occPhoneNumber || ''}
              onChange={handleChange('occPhoneNumber')}
              isValid={Validation.convertValidityTypeToBoolean(validity?.occPhoneNumber?.valid) === true}
              isInvalid={Validation.convertValidityTypeToBoolean(validity?.occPhoneNumber?.valid) === false}
              disabled={(fields?.occPhoneNumber || []).length > 1}
            />
            <PhoneInputField
              label="Fax:"
              feedback={validity?.faxNumber?.message}
              value={form?.faxNumber || ''}
              onChange={handleChange('faxNumber')}
              isValid={Validation.convertValidityTypeToBoolean(validity?.faxNumber?.valid) === true}
              isInvalid={Validation.convertValidityTypeToBoolean(validity?.faxNumber?.valid) === false}
              disabled={(fields?.faxNumber || []).length > 1}
            />
          </Col>
        </Row>
      </Row>
      <Row className="mt-4">
        <Col md={6} className="d-flex align-items-center">
          <h5>
            <strong>Primary Address</strong>
          </h5>
        </Col>
        <Col md={6} className="pe-0 d-flex align-items-center justify-content-between">
          <h5>
            <strong>Billing Address</strong>
          </h5>

          <div
            className="bg-gray bg-opacity-10 d-flex align-items-center justify-content-center"
            style={{ borderRadius: '1.5rem 1.5rem 0 0', padding: '1rem 1rem .75rem 1rem' }}
          >
            <input name="billingSameAsPrimary" type="checkbox" checked={form?.billingSameAsPrimary || false} onChange={onChange.toggle} />
            <div className="ps-1">Same As Primary Address</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Row className="bg-gray bg-opacity-10 g-0 {border-radius:1.5rem|0|1.5rem|1.5rem;}">
          <Col md={6} className="p-3">
            <Row style={{ borderRight: '1px solid black' }}>
              <Row>
                <Col>
                  <InputField
                    label="Full Address:"
                    feedback={validity?.primaryAddress?.message}
                    value={form?.primaryAddress || ''}
                    onChange={handleChange('primaryAddress')}
                    isValid={Validation.convertValidityTypeToBoolean(validity?.primaryAddress?.valid) === true}
                    isInvalid={Validation.convertValidityTypeToBoolean(validity?.primaryAddress?.valid) === false}
                    placeholder="Full Address"
                    disabled={(fields?.primaryAddress || []).length > 1}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="pe-0">
                  <InputField
                    label="City:"
                    feedback={validity?.primaryCityCode?.message}
                    value={form?.primaryCityCode || ''}
                    onChange={handleChange('primaryCityCode')}
                    isValid={Validation.convertValidityTypeToBoolean(validity?.primaryCityCode?.valid) === true}
                    isInvalid={Validation.convertValidityTypeToBoolean(validity?.primaryCityCode?.valid) === false}
                    placeholder="City"
                    disabled={(fields?.primaryCityCode || []).length > 1}
                  />
                </Col>
                <Col className="pe-0">
                  <StateDropdownField
                    name="primaryStateCode"
                    label="State:"
                    value={form?.primaryStateCode || ''}
                    onChange={handleChange('primaryStateCode')}
                    isValid={Validation.convertValidityTypeToBoolean(validity?.primaryStateCode?.valid) === true}
                    isInvalid={Validation.convertValidityTypeToBoolean(validity?.primaryStateCode?.valid) === false}
                    placeholder="--"
                    feedback={validity?.primaryStateCode?.message}
                    disabled={(fields?.primaryStateCode || []).length > 1}
                  />
                </Col>
                <Col>
                  <InputField
                    label="Zip:"
                    feedback={validity?.primaryZipCode?.message}
                    type="number"
                    value={form?.primaryZipCode || ''}
                    onChange={handleChange('primaryZipCode')}
                    isValid={Validation.convertValidityTypeToBoolean(validity?.primaryZipCode?.valid) === true}
                    isInvalid={Validation.convertValidityTypeToBoolean(validity?.primaryZipCode?.valid) === false}
                    placeholder="Zip"
                    disabled={(fields?.primaryZipCode || []).length > 1}
                  />
                </Col>
              </Row>
            </Row>
          </Col>
          <Col md={6} className="p-3">
            <Row>
              <Row>
                <Col>
                  <InputField
                    label="Full Address:"
                    feedback={validity?.billingAddress?.message}
                    value={form?.billingAddress || ''}
                    onChange={handleChange('billingAddress')}
                    isValid={Validation.convertValidityTypeToBoolean(validity?.billingAddress?.valid) === true}
                    isInvalid={Validation.convertValidityTypeToBoolean(validity?.billingAddress?.valid) === false}
                    placeholder="Full Address"
                    disabled={!!form?.billingSameAsPrimary || (fields?.billingAddress || []).length > 1}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="pe-0">
                  <InputField
                    label="City:"
                    feedback={validity?.billingCityCode?.message}
                    value={form?.billingCityCode || ''}
                    onChange={handleChange('billingCityCode')}
                    isValid={Validation.convertValidityTypeToBoolean(validity?.billingCityCode?.valid) === true}
                    isInvalid={Validation.convertValidityTypeToBoolean(validity?.billingCityCode?.valid) === false}
                    placeholder="City"
                    disabled={!!form?.billingSameAsPrimary || (fields?.billingCityCode || []).length > 1}
                  />
                </Col>
                <Col className="pe-0">
                  <StateDropdownField
                    name="billingStateCode"
                    label="State:"
                    value={form?.billingStateCode || ''}
                    onChange={handleChange('billingStateCode')}
                    isValid={Validation.convertValidityTypeToBoolean(validity?.billingStateCode?.valid) === true}
                    isInvalid={Validation.convertValidityTypeToBoolean(validity?.billingStateCode?.valid) === false}
                    placeholder="--"
                    feedback={validity?.billingStateCode?.message}
                    disabled={!!form?.billingSameAsPrimary || (fields?.billingStateCode || []).length > 1}
                  />
                </Col>
                <Col>
                  <InputField
                    label="Zip:"
                    feedback={validity?.billingZipCode?.message}
                    type="number"
                    value={form?.billingZipCode || ''}
                    onChange={handleChange('billingZipCode')}
                    isValid={Validation.convertValidityTypeToBoolean(validity?.billingZipCode?.valid) === true}
                    isInvalid={Validation.convertValidityTypeToBoolean(validity?.billingZipCode?.valid) === false}
                    placeholder="Zip"
                    disabled={!!form?.billingSameAsPrimary || (fields?.billingZipCode || []).length > 1}
                  />
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Row>
      {/* Display emails component */}
      <h3 className="Emails-Header">Emails</h3>
      <div className="EditAirlinesModal-Emails">
        {(form?.emails || []).map((_email: Email, e: number): JSX.Element => {
          const email = getProperty(`emails.${e}`, form, {});
          return (
            <Row className="mb-1 align-items-center" key={e}>
              <Col md={8}>
                <InputField
                  label="Email:"
                  value={email?.address}
                  isValid={Validation.isEmail(email.address) === true}
                  isInvalid={Validation.isEmail(email.address) === false}
                  placeholder="Email"
                  onChange={handleChange(`emails.${e}.address`)}
                />
              </Col>
              <Col md={3}>
                <EnumDropdownField
                  label="Type:"
                  value={email?.type}
                  onChange={handleChange(`emails.${e}.type`)}
                  enum={EmailTypeEnum}
                  placeholder="Role"
                  isValid={!!email?.type === true}
                  isInvalid={!!email?.type === false}
                  disabled={selectedCount > 1}
                />
              </Col>
              <Col md={1}>
                <Button
                  className="{margin-top:11px;}"
                  name="REMOVE_EMAIL"
                  variant="outline-danger"
                  onClick={(): void => handleRemoveEmail(e)}
                >
                  <i className="fa fa-minus align-self-center justify-self-center" />
                </Button>
              </Col>
            </Row>
          );
        })}
      </div>
      <Row className="EditAirlinesModal-EmailInput align-items-center">
        <Col md={8}>
          <InputField
            type="email"
            label="Enter Email:"
            placeholder="Enter Email Here"
            value={form?.newEmail || ''}
            onChange={handleChange('newEmail')}
            disabled={selectedCount > 1}
            isValid={Validation.isEmail(form?.newEmail) ? true : form?.newEmail ? false : undefined}
            isInvalid={Validation.isEmail(form?.newEmail) ? false : form?.newEmail ? true : undefined}
          />
        </Col>
        <Col md={3}>
          <EnumDropdownField
            label="Type:"
            value={form?.newEmailType || ''}
            onChange={handleChange('newEmailType')}
            enum={EmailTypeEnum}
            placeholder="Role"
            isValid={form?.newEmailType ? true : undefined}
            disabled={selectedCount > 1}
          />
        </Col>
        <Col md={1}>
          <FormButton
            className="{margin-top:11px;}"
            name="ADD_EMAIL"
            variant="outline-success"
            onClick={handleAddEmail}
            disabled={selectedCount > 1 || !Validation.isEmail(form?.newEmail) || !form?.newEmailType}
            feedback={!Validation.isEmail(form?.newEmail) || !form?.newEmailType ? 'Enter a valid email, and select a type.' : undefined}
          >
            <i className="fa fa-save" />
          </FormButton>
        </Col>
      </Row>
    </EditModal>
  );
};

export default EditAirlinesModal;
