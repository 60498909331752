import { Col, Container, Row } from 'react-bootstrap';
import { Dispatch, SetStateAction } from 'react';
import { TODAY, TODAY_EOD } from '@/constants';

import Feature from '@/common/Feature';
import IssueHistoryFilters from '@/features/IssueHistory/components/IssueHistoryFilters';
import IssueHistoryTable from '@/features/IssueHistory/components/IssueHistoryTable';
import { SortDirectionEnum } from '@/models/gen/graphql';
import { sleep } from '@/utils';
import { useProvider } from '@/common/Provider';

const IssueHistory = (): JSX.Element => {
  return (
    <Feature name="IssueHistory" context={initIssueHistoryState} methods={issueHistoryMethodConstructor}>
      <IssueHistoryFilters />
      <Container>
        <Row>
          <Col>
            <IssueHistoryTable />
          </Col>
        </Row>
      </Container>
    </Feature>
  );
};

export type IssueHistoryFilters = {
  startDatetime: string;
  endDatetime: string;
  airports: string[];
  airlines: string[];
  taxiCalled: string;
  search: string;
};
export type IssueHistoryRow = {
  airport: string;
  datetime: string;
  airline: string;
  flightNumber: string;
  employeeId: string;
  rootCause: string;
  taxiCalled: boolean;
  taxiCost: number;
  recovery: boolean;
  planePushed: boolean;
};
export type IssueHistoryContext = {
  filters: IssueHistoryFilters;
  issues: Map<string, IssueHistoryRow>;
  totalIssues: number;
  selected: Map<string, IssueHistoryRow>;
  loading: boolean;
  sortBy: string;
  sortDirection: SortDirectionEnum;
};
export type IssueHistoryMethods = {
  resetState: (overrides?: Partial<IssueHistoryContext>) => void;
  submitFilters: () => Promise<void>;
};
export const initIssueHistoryState: IssueHistoryContext = {
  filters: {
    startDatetime: TODAY,
    endDatetime: TODAY_EOD,
    airports: [],
    airlines: [],
    taxiCalled: null,
    search: null,
  },
  issues: new Map(), // SET MOCK DATA
  totalIssues: 0,
  selected: new Map(),
  sortBy: '',
  sortDirection: SortDirectionEnum.Asc,
  loading: false,
};
export const issueHistoryMethodConstructor = (
  state: IssueHistoryContext,
  setState: Dispatch<SetStateAction<IssueHistoryContext>>
): IssueHistoryMethods => ({
  resetState: (overrides?: Partial<IssueHistoryContext>): void => setState({ ...initIssueHistoryState, ...(overrides || {}) }),
  submitFilters: async (): Promise<void> => {
    try {
      console.log('Submitting Filters: ', state.filters);
      setState((prevState) => ({ ...prevState, loading: true }));
      // TODO: Add full query logic once the service has been created.
      await sleep(3000);
    } catch (err) {
      console.error('Error submitting filters:', err);
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  },
});
export function useIssueHistory<T = IssueHistoryContext>(
  selector?: (state: IssueHistoryContext) => T
): [T, Dispatch<SetStateAction<IssueHistoryContext>>, IssueHistoryMethods] {
  return useProvider<IssueHistoryContext, T, IssueHistoryMethods>(selector);
}

export default IssueHistory;
