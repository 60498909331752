import { LoadingBlur } from '@/components/LoadingSpinner';
import PageInfo from '@/components/PageInfo';
import { useLostAndFoundHistory } from '@/features/LostAndFoundHistory';

const LostAndFoundHistoryFiltersInfo = () => {
  const [{ reports, loading }] = useLostAndFoundHistory(({ reports, loading }) => ({
    reports,
    loading,
  }));

  return (
    <>
      <LoadingBlur label="Loading Reports" loading={loading} />
      <PageInfo>{reports.size} Reports</PageInfo>
    </>
  );
};

export default LostAndFoundHistoryFiltersInfo;
