import { HTMLAttributes, useMemo } from 'react';

import { Button } from 'react-bootstrap';
import Sticky from 'react-sticky-el';
import Tippy from '@tippyjs/react';
import { getClasses } from '@/utils';
import { useSimpleTableContext } from '@/components/SimpleTable';
import useViewport from '@/hooks/useViewport';

export type SimpleTableControlsProps = {} & HTMLAttributes<HTMLDivElement>;

const SimpleTableControls = ({ ...divProps }: SimpleTableControlsProps): JSX.Element => {
  const { loading, onScrollToBottom, onScrollToTop, tableHeight } = useSimpleTableContext();
  const [
    {
      content: { height: viewportHeight },
    },
  ] = useViewport();
  const height = useMemo((): number => Math.min(tableHeight, viewportHeight), [tableHeight, viewportHeight]);

  return (
    <Sticky scrollElement="main,.RouteContent" boundaryElement=".SimpleTable" hideOnBoundaryHit={false} bottomOffset={height}>
      <div {...divProps} className={getClasses('SimpleTable-Controls', divProps.className)} style={{ height }}>
        <Tippy content="Scroll to Bottom" placement="left">
          <Button className="border-gray" variant="secondary-subtle" onClick={onScrollToBottom} disabled={loading}>
            <i className="fa fa-chevron-down" />
          </Button>
        </Tippy>
        <Tippy content="Scroll to Top" placement="left">
          <Button className="border-gray" variant="secondary-subtle" onClick={onScrollToTop} disabled={loading}>
            <i className="fa fa-chevron-up" />
          </Button>
        </Tippy>
      </div>
    </Sticky>
  );
};

export default SimpleTableControls;
