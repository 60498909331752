import './styles.scss';

import Drawer, { DrawerProps } from '@/common/Drawer';
import { getClasses, pixelsToRem } from '@/utils';

import AccidentHistoryForm from '@/features/AccidentHistory/components/AccidentHistoryForm';
import { createComponentState } from '@/state';

export type AccidentHistoryDrawerState = {
  show: boolean;
};
export const initAccidentHistoryState: AccidentHistoryDrawerState = {
  show: false,
};
export const useAccidentHistoryDrawer = createComponentState<AccidentHistoryDrawerState>(initAccidentHistoryState);
export type AccidentHistoryDrawerProps = { locale?: Record<string, string> } & DrawerProps;
const AccidentHistoryDrawer = ({ locale: customLocale, ...drawerProps }: AccidentHistoryDrawerProps): JSX.Element => {
  const [state, setState] = useAccidentHistoryDrawer(({ state, setState }) => [state, setState]);
  const { show } = state;

  const onHide = (): void => setState({ ...state, show: false });
  const afterClose = (): void => setState(initAccidentHistoryState);
  const onSubmit = (data): void => {
    console.log('Submit Accident', data);
    onHide();
  };

  return (
    <Drawer
      {...drawerProps}
      className={getClasses('AccidentHistoryDrawer', drawerProps.className)}
      show={show}
      onHide={onHide}
      afterClose={afterClose}
      width={pixelsToRem(549)}
    >
      <AccidentHistoryForm onSubmit={onSubmit} />
    </Drawer>
  );
};

export default AccidentHistoryDrawer;
