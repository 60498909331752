import { LoadingBlur } from '@/components/LoadingSpinner';
import PageInfo from '@/components/PageInfo';
import { useIssueHistory } from '@/features/IssueHistory';

const IssueHistoryFiltersInfo = () => {
  const [{ issues, loading }] = useIssueHistory(({ issues, loading }) => ({
    issues,
    loading,
  }));

  return (
    <>
      <LoadingBlur label="Loading Issues" loading={loading} />
      <PageInfo>{issues.size} Issues</PageInfo>
    </>
  );
};

export default IssueHistoryFiltersInfo;
