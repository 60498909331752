import { initIncidentHistoryState, useIncidentHistory } from '@/features/IncidentHistory';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import AirportDropdown from '@/components/AirportDropdown';
import { Button } from 'react-bootstrap';
import DateRangePicker from '@/components/DateRangePicker';
import Dropdown from '@/components/Dropdown';
import FormButton from '@/components/FormButton';
import IncidentHistoryFiltersInfo from '@/features/IncidentHistory/components/IncidentHistoryFilters/IncidentHistoryFiltersInfo';
import { InputWithIcon } from '@/components/Input';
import PageNavigationDropdown from '@/common/PageNavigationDropdown';
import QueryFilters from '@/components/QueryFilters';
import { useCallback } from 'react';
import { useIncidentHistoryDrawer } from '@/features/IncidentHistory/components/IncidentHistoryDrawer';

const IncidentHistoryFilters = (): JSX.Element => {
  const [{ filters, loading }, setState, { submitFilters }] = useIncidentHistory(({ filters, loading }) => ({ filters, loading }));
  const { startDatetime, endDatetime, airports, airlines, injuries, search } = filters;
  const setIncidentHistoryDrawerState = useIncidentHistoryDrawer(({ setState }) => setState);

  const resetFilters = useCallback((): void => {
    setState((current) => ({ ...current, filters: initIncidentHistoryState.filters }));
  }, [setState]);

  const onChange = useCallback(
    (name: string): ((value) => void) =>
      (value): void => {
        switch (name) {
          case 'datetime':
            const [from, to] = value?.target?.value?.split?.(' - ') || [null, null];
            return setState((current) => ({ ...current, filters: { ...current.filters, startDatetime: from, endDatetime: to } }));
          default:
            return setState((current) => ({ ...current, filters: { ...current.filters, [name]: value } }));
        }
      },
    []
  );

  const onCreate = () => {
    //TODO: Implement create lost and found modal
    console.log('Create Incident');
    setIncidentHistoryDrawerState({ show: true });
  };

  const onOpenSettings = () => {
    //TODO: Implement settings modal/drawer
    console.log('Open Settings');
  };

  return (
    <>
      <QueryFilters className="AccidentHistoryFilters justify-content-between">
        <div className="d-flex gap-2">
          <QueryFilters.Control>
            <DateRangePicker value={[startDatetime, endDatetime]} onChange={onChange('datetime')} />
          </QueryFilters.Control>
          <QueryFilters.Input>
            <AirportDropdown.Multi value={airports} onChange={onChange('airports')} options={{ locale: { 'Select...': 'Airports' } }} />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <AirlineIataDropdown.Multi value={airlines} onChange={onChange('airlines')} options={{ locale: { 'Select...': 'Airlines' } }} />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <Dropdown
              value={injuries}
              onChange={onChange('injuries')}
              items={[
                { label: 'Yes', value: '1' },
                { label: 'No', value: '0' },
              ]}
              options={{ locale: { 'Select...': 'Injuries?' } }}
            />
          </QueryFilters.Input>
          <QueryFilters.Control>
            <Button className="border-white" name="SUBMIT" variant="success" onClick={submitFilters} disabled={loading}>
              {loading && <i className="fa fa-spinner fa-pulse" />}
              {!loading && <span>Go</span>}
            </Button>
            <Button name="RESET" variant="outline-secondary-subtle" onClick={resetFilters}>
              Reset
            </Button>
          </QueryFilters.Control>
          <QueryFilters.Control>
            <InputWithIcon
              icon={<i className="sv sv-magnifier fs-4" />}
              name="search"
              value={search || ''}
              onChange={onChange('search')}
              placeholder="Search"
            />
          </QueryFilters.Control>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <QueryFilters.Control>
            <FormButton
              maxWidth={1560}
              icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
              name="CREATE_INCIDENT"
              variant="outline-secondary-subtle"
              onClick={onCreate}
            >
              Create Incident
            </FormButton>
          </QueryFilters.Control>
          <QueryFilters.Input>
            <PageNavigationDropdown />
          </QueryFilters.Input>
          <QueryFilters.Control>
            <FormButton
              variant="outline-secondary-subtle"
              onClick={onOpenSettings}
              icon={<i className="sv sv-cog {font-size:1.5rem;}" />}
              feedback="Accident History Page Settings & Preferences"
            />
          </QueryFilters.Control>
        </div>
      </QueryFilters>
      <IncidentHistoryFiltersInfo />
    </>
  );
};

export default IncidentHistoryFilters;
