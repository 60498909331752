import { DATETIME_FORMAT, TIME_FORMAT } from '@/constants';
import { Datetime, getClasses } from '@/utils';
import { ForwardRefExoticComponent, forwardRef, useCallback } from 'react';

import { Button } from 'react-bootstrap';
import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';
import Tippy from '@tippyjs/react';
import { useAccidentHistory } from '@/features/AccidentHistory';

const AccidentHistoryTableRow = ({ id }: { id: string }, ref: ForwardRefExoticComponent<HTMLDivElement>): JSX.Element => {
  const [{ accident, selected }, setState] = useAccidentHistory(({ accidents, selected }) => ({
    accident: accidents.get(id),
    selected: selected.has(id),
  }));

  const onSelect = useCallback(
    (): void =>
      setState((current) => {
        const newSelected = new Map(current.selected);
        if (newSelected.has(id)) newSelected.delete(id);
        else newSelected.set(id, current.accidents.get(id));
        return { ...current, selected: newSelected };
      }),
    [accident, id, setState]
  );
  const onEdit = useCallback((): void => {
    console.log('ON_EDIT', id);
  }, [id]);
  const onDelete = useCallback((): void => {
    console.log('ON_DELETE', id);
  }, [id]);

  return (
    <SimpleTableRow id={id} className={getClasses(selected ? 'SELECTED' : undefined)} onDoubleClick={onEdit} ref={ref}>
      <SimpleTableCell.Select checked={selected} onChange={onSelect} />
      <SimpleTableCell name="AIRPORT">{accident.airport}</SimpleTableCell>
      <SimpleTableCell name="DATETIME">{new Datetime(accident.datetime).format(DATETIME_FORMAT)}</SimpleTableCell>
      <SimpleTableCell name="AIRLINE">{accident.airline}</SimpleTableCell>
      <SimpleTableCell name="FLIGHT_NUMBER">{accident.flightNumber}</SimpleTableCell>
      <SimpleTableCell name="SCHEDULED_TIME">{new Datetime(accident.scheduledTime).format(TIME_FORMAT)}</SimpleTableCell>
      <SimpleTableCell name="VAN">{accident.van}</SimpleTableCell>
      <SimpleTableCell name="EMPLOYEE">{accident.employee}</SimpleTableCell>
      <SimpleTableCell name="ID">{accident.idNumber}</SimpleTableCell>
      <SimpleTableCell name="MANAGER">{accident.manager}</SimpleTableCell>
      <SimpleTableCell name="INJURY" style={{ color: accident.injury ? 'var(--bs-danger)' : 'var(--bs-green)' }}>
        {accident.injury ? 'Yes' : 'No'}
      </SimpleTableCell>
      <SimpleTableCell name="CREW_INVOLVED" style={{ color: accident.crewInvolved ? 'var(--bs-danger)' : 'var(--bs-green)' }}>
        {accident.crewInvolved ? 'Yes' : 'No'}
      </SimpleTableCell>
      <SimpleTableCell name="EDIT">
        <Tippy content="Edit Accident">
          <Button name="EDIT" variant="icon" onClick={onEdit}>
            <i className="sv sv-pencil" />
          </Button>
        </Tippy>
      </SimpleTableCell>
      <SimpleTableCell name="DELETE">
        <Tippy content="Delete Accident">
          <Button name="DELETE" variant="icon" onClick={onDelete}>
            <i className="sv sv-trash2" />
          </Button>
        </Tippy>
      </SimpleTableCell>
    </SimpleTableRow>
  );
};

export default forwardRef(AccidentHistoryTableRow);
