import { Config } from '@/state/session';
import NavigationDrawerItem from '@/components/Header/NavigationDrawer/NavigationDrawerItem';
import NavigationDrawerItemGroup from '@/components/Header/NavigationDrawer/NavigationDrawerItemGroup';

export const createServiceIssuePermissions: Record<string, keyof Config['auth']> = {
  accidents: 'allowCreateAccident' as keyof Config['auth'],
  incidents: 'allowCreateIncident' as keyof Config['auth'],
  lostAndFound: 'allowCreateLostAndFound' as keyof Config['auth'],
  dispatchIssues: 'allowCreateDispatchIssue' as keyof Config['auth'],
  dispatchSummaries: 'allowCreateDispatchSummary' as keyof Config['auth'],
};

const CreateServiceIssuesGroup = (): JSX.Element => (
  <NavigationDrawerItemGroup title="New Service Issue" permissions={Object.values(createServiceIssuePermissions)}>
    <NavigationDrawerItem
      name="Create Accident"
      icon={<i className="sv sv-ambulance" />}
      onClick={() => console.log('Create Accident')}
      restricted
      permissions={[createServiceIssuePermissions.accidents]}
    />
    <NavigationDrawerItem
      name="Create Incident"
      icon={<i className="sv sv-shield-alert" />}
      onClick={() => console.log('Create Incident')}
      restricted
      permissions={[createServiceIssuePermissions.incidents]}
    />
    <NavigationDrawerItem
      name="Create Lost and Found"
      icon={<i className="sv sv-box" />}
      onClick={() => console.log('Create Lost and Found')}
      restricted
      permissions={[createServiceIssuePermissions.lostAndFound]}
    />
    <NavigationDrawerItem
      name="Create Dispatch Issue"
      icon={<i className="sv sv-headset" />}
      onClick={() => console.log('Create Dispatch Issue')}
      restricted
      permissions={[createServiceIssuePermissions.dispatchIssues]}
    />
    <NavigationDrawerItem
      name="Create Dispatch Summary"
      icon={<i className="sv sv-bookmark" />}
      onClick={() => console.log('Create Dispatch Summary')}
      restricted
      permissions={[createServiceIssuePermissions.dispatchSummaries]}
    />
  </NavigationDrawerItemGroup>
);

export default CreateServiceIssuesGroup;
