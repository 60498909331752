import { CreateStopBulkInput, CreateStopInput, CreateStopResponse } from '@/models/gen/graphql';
import { createNotification, handleError, validateObj } from '@/utils/custom';

import { CreateStopBulkDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { Validation } from '@/utils';

const validateStop = new Validation.Validator({
  actual: (val: any): Validation.Validity => ({ valid: !!val }),
  completed: (val: any): Validation.Validity => ({ valid: !!val }),
  locationId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  scheduled: (val: any): Validation.Validity => ({ valid: !!val }),
  tripId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  type: (val: any): Validation.Validity => ({ valid: !!val }),
  airportCode: (val: string): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? true : val?.length ? false : undefined,
    defaultValue: '',
  }),
});

type CreateStopBulkGraphApiResponse = GraphApiResponse<typeof CreateStopBulkDocument>;

const title = 'Create Stops';

const createStopBulkResponseSelector = (res: CreateStopBulkGraphApiResponse): CreateStopResponse[] =>
  !res.errors ? ((res?.createStopBulk?.output || []) as CreateStopResponse[]) : undefined;

const [runCreateStopBulk] = graphApi(CreateStopBulkDocument, {
  onError: (res: CreateStopBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const createStopBulk = async (stops: CreateStopInput[]): Promise<CreateStopResponse[]> => {
  const input: CreateStopBulkInput = { values: stops.map((stop: CreateStopInput): CreateStopInput => validateStop.partial(stop)) };

  const res = await runCreateStopBulk({ input });
  const selected = createStopBulkResponseSelector(res);

  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);

  return selected;
};

export const useCreateStopBulk = createGraphApiHook(createStopBulk);

export default createStopBulk;
