import { Datetime, changeState } from '@/utils';
import Input, { InputWithIcon } from '@/components/Input';
import { SummaryHistoryContext, initSummaryHistoryState, useSummaryHistory } from '@/features/SummaryHistory';
import { TODAY, TODAY_EOD } from '@/constants';

import AirportDropdown from '@/components/AirportDropdown';
import { Button } from 'react-bootstrap';
import DateRangePicker from '@/components/DateRangePicker';
import FormButton from '@/components/FormButton';
import PageNavigationDropdown from '@/common/PageNavigationDropdown';
import QueryFilters from '@/components/QueryFilters';
import SummaryHistoryFiltersInfo from '@/features/SummaryHistory/components/SummaryHistoryFilters/SummaryHistoryFiltersInfo';
import { useCallback } from 'react';
import { useSummaryHistoryDrawer } from '@/features/SummaryHistory/components/SummaryHistoryDrawer';

// TODO: Add appropriate filters to this component based on the design when it is complete.
const SummaryHistoryFilters = (): JSX.Element => {
  const [{ filters, loading }, setState, { submitFilters }] = useSummaryHistory(({ filters, loading }) => ({ filters, loading }));
  const resetFilters = useCallback(() => setState((current) => ({ ...current, filters: initSummaryHistoryState.filters })), [setState]);
  const { startDatetime, endDatetime, airports, callVolume, search } = filters;
  const setSummaryHistoryDrawerState = useSummaryHistoryDrawer(({ setState }) => setState);

  const onChange = changeState<SummaryHistoryContext>(setState, {
    path: 'filters.*',
    handlers: {
      datetime: (value, setState) => {
        const [from, to] = (value?.target?.value?.split(' - ') || [TODAY, TODAY_EOD]).map((date: string): string =>
          new Datetime(date).toString()
        );
        setState((current) => ({
          ...current,
          filters: { ...current.filters, startDatetime: from, endDatetime: to },
        }));
      },
    },
  });

  const onCreate = () => {
    //TODO: Implement create dispatch summary modal
    console.log('Create Dispatch Summary');
    setSummaryHistoryDrawerState({ show: true });
  };

  const onOpenSettings = () => {
    //TODO: Implement settings modal/drawer
    console.log('Open Settings');
  };

  return (
    <>
      <QueryFilters className="AccidentHistoryFilters justify-content-between">
        <div className="d-flex gap-2">
          <QueryFilters.Control>
            <DateRangePicker value={[startDatetime, endDatetime]} onChange={onChange('datetime')} />
          </QueryFilters.Control>
          <QueryFilters.Input>
            <AirportDropdown.Multi value={airports} onChange={onChange('airports')} options={{ locale: { 'Select...': 'Airports' } }} />
          </QueryFilters.Input>
          <QueryFilters.Control>
            <Input name="callVolume" value={callVolume || ''} onChange={onChange('callVolume')} placeholder="Call Volume" />
          </QueryFilters.Control>
          <QueryFilters.Control>
            <Button className="border-white" name="SUBMIT" variant="success" onClick={submitFilters} disabled={loading}>
              {loading && <i className="fa fa-spinner fa-pulse" />}
              {!loading && <span>Go</span>}
            </Button>
            <Button name="RESET" variant="outline-secondary-subtle" onClick={resetFilters}>
              Reset
            </Button>
          </QueryFilters.Control>
          <QueryFilters.Control>
            <InputWithIcon
              icon={<i className="sv sv-magnifier fs-4" />}
              name="search"
              value={search || ''}
              onChange={onChange('search')}
              placeholder="Search"
            />
          </QueryFilters.Control>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <QueryFilters.Control>
            <FormButton
              maxWidth={1560}
              icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
              name="CREATE_ACCIDENT"
              variant="outline-secondary-subtle"
              onClick={onCreate}
            >
              Create Dispatch Summary
            </FormButton>
          </QueryFilters.Control>
          <QueryFilters.Input>
            <PageNavigationDropdown />
          </QueryFilters.Input>
          <QueryFilters.Control>
            <FormButton
              variant="outline-secondary-subtle"
              onClick={onOpenSettings}
              icon={<i className="sv sv-cog {font-size:1.5rem;}" />}
              feedback="Accident History Page Settings & Preferences"
            />
          </QueryFilters.Control>
        </div>
      </QueryFilters>
      <SummaryHistoryFiltersInfo />
    </>
  );
};

export default SummaryHistoryFilters;
