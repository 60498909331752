import { Offcanvas as BootstrapOffCanvas, OffcanvasHeaderProps as BootstrapOffCanvasHeaderProps } from 'react-bootstrap';

import { ReactNode } from 'react';
import { getClasses } from '@/utils';

export type DrawerHeaderProps = { title?: string; icon?: JSX.Element; children?: ReactNode } & BootstrapOffCanvasHeaderProps;

const DrawerHeader = ({ title, icon, children, ...headerProps }: DrawerHeaderProps) => {
  // If nothing provided show nothing
  if (!title && !icon && !children) return null;
  return (
    <BootstrapOffCanvas.Header {...headerProps} className={getClasses('Drawer-Header', headerProps.className)}>
      {children}
      {!children && (title || icon) && (
        <BootstrapOffCanvas.Title className="Drawer-Title">
          {icon}
          {title}
        </BootstrapOffCanvas.Title>
      )}
    </BootstrapOffCanvas.Header>
  );
};

export default DrawerHeader;
