import './styles.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { EnumDropdownField, getItemsFromEnum } from '@/components/EnumDropdown';

import { AirportDropdownField } from '@/components/AirportDropdown';
import { DatetimeInputField } from '@/components/DatetimeInput';
import Form from '@/common/Form';
import GenericFileUpload from '@/components/FileUpload/new';
import { InputField } from '@/components/Input';
import { TextAreaField } from '@/components/TextArea';
import { UserDropdownField } from '@/components/UserDropdown';
import useLocale from '@/hooks/useLocale';

const IncidentHistoryForm = ({ onSubmit, locale: customLocale, value = {} }: IncidentHistoryFormDefinitions.Props): JSX.Element => {
  const locale = useLocale({
    ...IncidentHistoryFormDefinitions.getLocale(value),
    ...customLocale,
  });
  const handleSubmit = async (data) => {
    data = await onSubmit?.(data);
    // await Call BE and submit the data.
  };

  //Remove these enums after form is wired up to backend
  enum TempOccurenceEnum {
    OFFICE = 'Office',
    AIRPORT = 'Airport',
    HOTEL = 'Hotel',
    OTHER = 'Other',
  }

  enum TempPropertyDamageEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempCameraWorkingEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempCrewInvolvedEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempInjuriesEnum {
    YES = 'Yes',
    NO = 'No',
  }
  enum TempMedicalNeededEnum {
    YES = 'Yes',
    NO = 'No',
  }

  return (
    <Form className="IncidentHistoryForm" onSubmit={handleSubmit} value={IncidentHistoryFormDefinitions.initValue}>
      <Row className="IncidentHistoryFormHeader">
        <Col>
          {
            /* Section appears if trip linked */
            /*value.id*/ true && (
              <>
                <span>DL1212 1/22/25 05:30 EST</span>
                <span>ATL - Keith Sanderson - #4321</span>
              </>
            )
          }
          <h4 className="IncidentHistoryFormTitle">{locale('Incident')}</h4>
        </Col>
      </Row>
      {
        /* Section appears if no trip linked */
        /*!value.id*/ false && (
          <Row className="IncidentHistoryFormLinkTrips">
            <Col>
              <span>THIS IS NOT LINKED TO TRIP.</span>
              <span>
                WOULD YOU LIKE TO LINK THIS TO A TRIP? <a className="Link">YES</a>
              </span>
            </Col>
          </Row>
        )
      }
      <Row>
        <Col>
          <Form.Input as={TextAreaField} name="description" label={locale('Description')} style={{ height: '7rem', resize: 'none' }} />
        </Col>
      </Row>
      {
        /* Station field appears if no trip linked */
        /*!value.id*/ false && (
          <Row>
            <Col>
              <Form.Input as={AirportDropdownField} name="station" label={locale('Station')} />
            </Col>
          </Row>
        )
      }
      <Row>
        <Col xs={6}>
          <Form.Input as={UserDropdownField} name="driverName" label={locale('Driver Name')} />
        </Col>
        <Col xs={6}>
          <Form.Input as={InputField} name="vanNumber" label={locale('Van Number')} maxLength={10} />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={InputField} name="employeeId" label={locale('Employee ID')} maxLength={10} />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="cameraWorking"
            label={locale('Samsara Camera Working?')}
            enum={getItemsFromEnum(TempCameraWorkingEnum)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={InputField} name="managerOnDuty" label={locale('Manager On Duty')} maxLength={50} />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="crewInvolved"
            label={locale('Were Crew Involved?')}
            enum={getItemsFromEnum(TempCrewInvolvedEnum)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input as={EnumDropdownField} name="injuries" label={locale('Anyone Injured?')} enum={getItemsFromEnum(TempInjuriesEnum)} />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="medicalNeeded"
            label={locale('Medical Needed?')}
            enum={getItemsFromEnum(TempMedicalNeededEnum)}
          />
        </Col>
      </Row>
      <Form.Watch name="crewInvolved">
        {(val) =>
          val === TempCrewInvolvedEnum.YES && (
            <>
              <Row>
                <Col xs={6}>
                  <Form.Input as={UserDropdownField} name="dispatcher" label={locale('Dispatcher Name')} />
                </Col>
                <Col xs={6}>
                  <Form.Input as={DatetimeInputField} name="incidentDatetime" label={locale('Incident Date/Time')} />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Form.Input as={InputField} name="flightNumber" label={locale('Outbound FLT #')} maxLength={10} />
                </Col>
                <Col xs={6}>
                  <Form.Input as={DatetimeInputField} name="outboundDatetime" label={locale('Outbound Date/Time')} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Input
                    as={TextAreaField}
                    name="crewInfo"
                    label={locale('Crew Info')}
                    placeholder={locale('Enter crew members name(s), phone(s), email(s)')}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Input as={TextAreaField} name="recoveryInfo" label={locale('Recovery Info')} />
                </Col>
              </Row>
            </>
          )
        }
      </Form.Watch>
      <Row>
        <Col>
          <Form.Input
            as={TextAreaField}
            name="medicalAttentionInfo"
            label={locale('What Type Of Medical Attention Was Offered/Required')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="propertyDamage"
            label={locale('Property Damage?')}
            enum={getItemsFromEnum(TempPropertyDamageEnum)}
          />
        </Col>
        <Col xs={6}>
          <Form.Input
            as={EnumDropdownField}
            name="incidentLocation"
            label={locale('Where Did This Occur?')}
            enum={getItemsFromEnum(TempOccurenceEnum)}
          />
        </Col>
      </Row>
      <Form.Watch name="incidentLocation">
        {(val) =>
          val === TempOccurenceEnum.OTHER && (
            <Row>
              <Col>
                <Form.Input as={InputField} name="incidentLocationOther" label={locale('Occurence Location?')} maxLength={512} />
              </Col>
            </Row>
          )
        }
      </Form.Watch>
      <Form.Watch name="propertyDamage">
        {(val) =>
          val === TempPropertyDamageEnum.YES && (
            <Row>
              <Col>
                <Form.Input as={TextAreaField} name="damageInfo" label={locale('Describe Damage')} />
              </Col>
            </Row>
          )
        }
      </Form.Watch>
      <Row>
        <Col>
          <Form.Input
            as={TextAreaField}
            name="witnesses"
            label={locale('Witnesses')}
            placeholder={locale('Enter witnesses name(s), phone(s), email(s)')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button name="SUBMIT" variant="success" onClick={handleSubmit} style={{ width: '100%', textTransform: 'uppercase' }}>
            {locale('Create Incident')}
          </Button>
        </Col>
      </Row>
      <Row className="IncidentHistoryFormUpload">
        <Col>
          <GenericFileUpload
            endpoint=""
            options={{
              showFiles: true,
              multiple: true,
            }}
            locale={{
              Upload: 'UPLOAD FILES',
            }}
            onError={async (...args) => console.log('', ...args)}
            onSuccess={async (...args) => console.log('', ...args)}
          />
        </Col>
      </Row>
      <Row className="IncidentHistoryFormHistory">
        <Col>
          <h4 className="IncidentHistoryFormHistoryTitle">{locale('Incident History')}</h4>
        </Col>
      </Row>
    </Form>
  );
};

export namespace IncidentHistoryFormDefinitions {
  export type Locale = {
    SUBMIT_BUTTON_TEXT?: string;
    Incident?: string;
    Description?: string;
    Station?: string;
    'Driver Name'?: string;
    'Van Number'?: string;
    'Employee ID'?: string;
    'Samsara Camera Working?'?: string;
    'Manager On Duty'?: string;
    'Were Crew Involved?'?: string;
    'Anyone Injured?'?: string;
    'Medical Needed?'?: string;
    'Dispatcher Name'?: string;
    'Incident Date/Time'?: string;
    'Outbound FLT #'?: string;
    'Outbound Date/Time'?: string;
    'Crew Info'?: string;
    'Enter crew members name(s), phone(s), email(s)'?: string;
    'Recovery Info'?: string;
    'What Type Of Medical Attention Was Offered/Required'?: string;
    'Property Damage?'?: string;
    'Where Did This Occur?'?: string;
    'Occurence Location?'?: string;
    'Describe Damage'?: string;
    Witnesses?: string;
    'Enter witnesses name(s), phone(s), email(s)'?: string;
    'Create Incident'?: string;
    'Incident History'?: string;
  };
  export type Value = {
    id: string;
    description: string;
    station: string;
    driverName: string;
    vanNumber: string;
    employeeId: string;
    cameraWorking: boolean;
    managerOnDuty: string;
    crewInvolved: boolean;
    injuries: boolean;
    medicalNeeded: boolean;
    dispatcher: string;
    incidentDatetime: string;
    flightNumber: string;
    outboundDatetime: string;
    crewInfo: string;
    recoveryInfo: string;
    medicalAttentionInfo: string;
    propertyDamage: boolean;
    incidentLocation: string;
    damageInfo: string;
    witnesses: string;
  };
  export type Props = {
    onSubmit?: (data: Value) => void | Value | Promise<Value> | undefined;
    locale?: Partial<Locale>;
    value?: Partial<Value>;
  };
  export const initValue: Value = {
    id: undefined,
    description: '',
    station: '',
    driverName: '',
    vanNumber: '',
    employeeId: '',
    cameraWorking: false,
    managerOnDuty: '',
    crewInvolved: false,
    injuries: false,
    medicalNeeded: false,
    dispatcher: '',
    incidentDatetime: '',
    flightNumber: '',
    outboundDatetime: '',
    crewInfo: '',
    recoveryInfo: '',
    medicalAttentionInfo: '',
    propertyDamage: false,
    incidentLocation: '',
    damageInfo: '',
    witnesses: '',
  };
  export const getLocale = (value: Partial<Value>): Partial<Locale> => ({
    SUBMIT_BUTTON_TEXT: value.id ? 'Edit Incident' : 'Create Incident',
  });
}

export default IncidentHistoryForm;
