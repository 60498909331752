import './styles.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { DATETIME_RFC_FORMAT, TIMEZONE_OFFSET, TODAY, TODAY_EOD } from '@/constants';
import MessageCenterFilters, { useMessageCenterFilters } from './components/MessageCenterFilters';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import useChatRooms, { ChatRoomType, useChatState, useSelectedRoomState } from '@/hooks/useSocket/chat/useChatRooms';

import ChatConnectionStatus from '@/components/ChatWidget/ChatConnectionStatus';
import { Datetime } from '@/utils';
import MessageCenterChatList from './components/MessageCenterChatList';
import MessageCenterContent from './components/MessageCenterContent';
import MessageCenterNoContent from './components/MessageCenterNoContent';
import PageInfo from '@/components/PageInfo';
import ProtectedRoute from '@/components/ProtectedRoute';
import useInterval from '@/hooks/useInterval';
import useSocket from '@/hooks/useSocket';
import useViewport from '@/hooks/useViewport';
import useVisibility from '@/hooks/useVisibility';

const MessageCenter = (): JSX.Element => {
  const socket = useSocket();
  const filters = useMessageCenterFilters(({ state }) => state);
  const [config, setState] = useChatState(({ state: { config }, setState }) => [config, setState]);
  const [selectedRoom, setSelectedRoom] = useSelectedRoomState(({ state: { room }, setState }) => [room, setState]);
  const [, , { getRooms }] = useChatRooms();
  const defaultChatRef = useRef<HTMLButtonElement>(null);
  const buttonTabsRef = useRef<HTMLDivElement>(null);
  const [{ content: { height: viewportHeight = 0 } = {} } = {}] = useViewport();
  const isVisible = useVisibility();
  const retries = useRef({
    [ChatRoomType.ALL_CHAT]: 0,
    [ChatRoomType.MY_CHAT]: 0,
  });

  useEffect(() => {
    setSelectedRoom({ room: undefined });
  }, []);

  useInterval(() => {
    setState((current) => {
      const now = Date.now();
      return {
        ...current,
        usersTyping: current.usersTyping.filter((user: any) => now - user.timestamp < 3000),
      };
    });
  }, 3000);

  const getChatRooms = useCallback(
    (format: ChatRoomType, vals = filters, retry: boolean = true) => {
      const generalFilters = {
        start_datetime: new Datetime(vals?.startDatetime || TODAY)
          .startOf('day')
          .add(TIMEZONE_OFFSET, 'minutes')
          .format(DATETIME_RFC_FORMAT),
        end_datetime: new Datetime(vals?.endDatetime || TODAY_EOD).endOf('day').add(TIMEZONE_OFFSET, 'minutes').format(DATETIME_RFC_FORMAT),
        user_provider_id: vals?.userProviderId ? [vals?.userProviderId] : null,
        keyword: vals?.keyword ? vals?.keyword : null,
      };
      return getRooms({ format, ...generalFilters }).catch(() => {
        console.error(
          `Failed to ${retry ? 'GET' : 'REFRESH'} rooms for ${format} format.`,
          retry && retries.current[format] < 10 ? `(${retries.current[format] + 1}/10) Retrying...` : ''
        );
        if (!retry) return;
        if (retries.current[format] >= 10) return (retries.current[format] = 0);
        retries.current[format] += 1;
        getChatRooms(format, vals, retry);
      });
    },
    [getRooms, filters]
  );

  const getList = useCallback((filters?, retry: boolean = true) => {
    getChatRooms(ChatRoomType.ALL_CHAT, filters, retry);
    getChatRooms(ChatRoomType.MY_CHAT, filters, retry);
  }, []);

  useEffect(() => {
    if (!isVisible) return;
    getList();
  }, [isVisible]);

  useInterval(() => {
    if (!isVisible || !socket?.connected) return;
    getList(filters, false);
  }, config?.client_refresh_interval_milliseconds ?? 5000);

  return (
    <ProtectedRoute title="Message Center" className="MessageCenter">
      {/* TODO: Implement actual filtering */}
      <MessageCenterFilters onSubmit={getList} />
      <PageInfo>
        <ChatConnectionStatus displayStatus />
      </PageInfo>
      {/* <PageInfo>Unread Messages: {totalUnread || 0}</PageInfo> */}
      <Row className={`MessageCenter-Container m-0`}>
        <Col sm="2">
          <Row ref={buttonTabsRef}>
            <Col className="g-0">
              <Button className="MessageCenter-Button" variant="primary" size="lg">
                All Chats
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              className={`position-relative {max-height:${viewportHeight - (buttonTabsRef.current?.offsetHeight || 0)}px;height:${viewportHeight}px;overflow:auto;} p-0`}
            >
              <MessageCenterChatList type={ChatRoomType.ALL_CHAT} />
            </Col>
          </Row>
        </Col>
        <Col sm="8" className={`d-flex p-0`}>
          {selectedRoom && <MessageCenterContent room={selectedRoom} />}
          {!selectedRoom && <MessageCenterNoContent />}
        </Col>
        <Col sm="2">
          <Row>
            <Col className="g-0">
              <Button className="MessageCenter-Button" ref={defaultChatRef} variant="primary" size="lg">
                My Chats
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              className={`position-relative {max-height:${viewportHeight - (buttonTabsRef.current?.offsetHeight || 0)}px;height:${viewportHeight}px;overflow:auto;} p-0`}
            >
              <MessageCenterChatList type={ChatRoomType.MY_CHAT} />
            </Col>
          </Row>
        </Col>
      </Row>
    </ProtectedRoute>
  );
};

export default MessageCenter;
