// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatMessageAvatar {
  width: 3rem;
  height: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWidget/ChatMessage/ChatMessageAvatar/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".ChatMessageAvatar {\n  width: 3rem;\n  height: 3rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
