import './styles.scss';

import { Button, Col, InputGroup, Row } from 'react-bootstrap';
import { Datetime, queryInput } from 'utils';
import { Location, Stop } from 'models/gen/graphql';

import FormField from 'components/FormField';
import React from 'react';
import SelectAirport from '../SelectAirport';
import SelectLocation from 'components/SelectLocation';
import SelectStopType from 'components/SelectStopType';
import { stringify } from '../../utils/objects';

interface TripStop extends Stop {
  airportFilter?: string;
}
interface TripStopProps {
  data: TripStop;
  onDelete?: any;
  onChange: any;
  index: number;
  disabled?: boolean;
  readOnly?: boolean;
  valid?: { locationId: boolean };
  options?: {
    autoSelectLocation?: boolean;
    filterLocationByAirport?: boolean;
    location?: {
      props?: any;
    };
  };
}
const TripStopComponent = ({
  data: stop,
  onDelete,
  onChange,
  index: s,
  disabled,
  readOnly,
  valid,
  options,
}: TripStopProps): JSX.Element => {
  const filters = options?.location?.props?.filter || {};
  return (
    <Row className="TripStop align-items-center py-2">
      <Col xs={12} md={3} className="pe-0">
        <InputGroup size={onDelete || !disabled ? 'sm' : undefined}>
          {onDelete && (
            <InputGroup.Text>
              <Button variant="icon" className="p-0" onClick={onDelete}>
                <i className="fa fa-minus-circle text-danger" />
              </Button>
            </InputGroup.Text>
          )}
          <SelectStopType
            name={`trip.stops.${s}.type`}
            value={stop?.type || 'PU'}
            onChange={onChange}
            disabled={!!disabled || readOnly}
            placeholder="--"
          />
          {!disabled && (
            <InputGroup.Text>
              <i className="fa fa-2x fa-bars" />
            </InputGroup.Text>
          )}
        </InputGroup>
      </Col>
      <Col xs={12} md={6} className="ps-0 pe-1">
        <FormField
          {...(options?.location?.props || {})}
          query={{
            ...filters,
            airportCode: queryInput(filters?.airportCode || stop?.airportFilter || stop?.airportCode),
          }}
          inputOptions={{ removeAirportPrefix: true, autoSelectLocation: !!options?.autoSelectLocation }}
          label={`${s + 2}.`}
          placeholder="Select Location"
          name={`trip.stops.${s}.locationId`}
          value={stop?.locationId}
          onChange={onChange}
          disabled={readOnly}
          valid={valid?.locationId}
          searchable
          onCreate={(location: Location): void => onChange({ target: { name: `trip.stops.${s}.locationId`, value: location?.id } })}
          options={{ input: { as: SelectLocation }, group: { className: 'ps-1' } }}
          inline
          condensed
        />
      </Col>
      {options?.filterLocationByAirport && (
        <Col xs={12} md={3} className="ps-0">
          <FormField
            placeholder="City"
            name={`trip.stops.${s}.airportFilter`}
            value={stop?.airportFilter || stop?.airportCode}
            onChange={onChange}
            disabled={readOnly}
            searchable
            options={{ input: { as: SelectAirport }, group: { className: 'ps-1' } }}
            condensed
          />
        </Col>
      )}
    </Row>
  );
};
const TripStop: any = React.memo(TripStopComponent, (prev: Readonly<TripStopProps>, next: Readonly<TripStopProps>): boolean =>
  stringify.compare(prev?.data, next?.data)
);

interface TripStopTimeScheduleProps {
  stop: Stop;
  onChange: any;
  errors?: any;
  index: number;
  disabled?: boolean;
  readOnly?: boolean;
  valid?: { scheduled: boolean; actual?: boolean; completed?: boolean };
}
const TripStopTimeScheduleComponent = ({ stop, onChange, index: s, disabled, readOnly, valid }: TripStopTimeScheduleProps): JSX.Element => {
  return (
    <Row xs={1} sm={3} className="TripStop-TimeSchedule py-2">
      <Col>
        <InputGroup className="p-relative">
          <FormField
            type="time"
            name={`trip.stops.${s}.scheduled`}
            key={`trip.stops.${s}.scheduled`}
            value={stop?.scheduled ? new Datetime(stop?.scheduled).fullTime : ''}
            onChange={onChange?.time || onChange}
            valid={valid?.scheduled}
            readOnly={readOnly}
            disabled={disabled}
            options={{ input: { className: 'Scheduled p-0' } }}
            condensed
          />
          {s === -1 && <small>Scheduled</small>}
        </InputGroup>
      </Col>
      <Col>
        <InputGroup className="p-relative">
          <FormField
            type="time"
            name={`trip.stops.${s}.actual`}
            key={`trip.stops.${s}.actual`}
            value={stop?.actual ? new Datetime(stop?.actual).fullTime : ''}
            onChange={onChange?.time || onChange}
            readOnly={readOnly}
            disabled={disabled}
            valid={valid?.actual}
            options={{ input: { className: 'Ready p-0' } }}
            condensed
          />
          {s === -1 && <small>Ready</small>}
        </InputGroup>
      </Col>
      <Col>
        <InputGroup className="p-relative">
          <FormField
            type="time"
            name={`trip.stops.${s}.completed`}
            key={`trip.stops.${s}.completed`}
            value={stop?.completed ? new Datetime(stop?.completed).fullTime : ''}
            onChange={onChange?.time || onChange}
            readOnly={readOnly}
            disabled={disabled}
            valid={valid?.completed}
            options={{ input: { className: 'Actual' } }}
            condensed
          />
          {s === -1 && <small>Picked Up</small>}
        </InputGroup>
      </Col>
    </Row>
  );
};
const TripStopTimeSchedule: any = React.memo(
  TripStopTimeScheduleComponent,
  (prev: Readonly<TripStopTimeScheduleProps>, next: Readonly<TripStopTimeScheduleProps>): boolean =>
    stringify.compare(prev?.stop, next?.stop)
);

export { TripStop, TripStopTimeSchedule };
