import './styles.scss';

import { Button, ButtonProps } from 'react-bootstrap';

import { getClasses } from '@/utils';

export type CloseButtonProps = {} & ButtonProps;

const CloseButton = ({ ...buttonProps }: CloseButtonProps): JSX.Element => {
  if (!buttonProps.onClick) return null;
  return (
    <Button {...buttonProps} className={getClasses('CloseButton', buttonProps.className)} variant="icon">
      <i className="sv sv-cross" />
    </Button>
  );
};

export default CloseButton;
