import { useCallback, useMemo } from 'react';

import SimpleTableCell from '@/components/SimpleTable/SimpleTableCell';
import SimpleTableRow from '@/components/SimpleTable/SimpleTableRow';
import { SortDirectionEnum } from '@/models/gen/graphql';
import Tippy from '@tippyjs/react';
import { useIncidentHistory } from '@/features/IncidentHistory';

const IncidentHistoryTableColumns = (): JSX.Element => {
  return (
    <SimpleTableRow header>
      <SelectAllCell />
      <SortableCell name="AIRPORT">Airport</SortableCell>
      <SortableCell name="DATETIME">Incident Time</SortableCell>
      <SortableCell name="AIRLINE">AL</SortableCell>
      <SortableCell name="FLIGHT_NUMBER">FLT #</SortableCell>
      <SortableCell name="SCHEDULED_TIME">Sch Time</SortableCell>
      <SortableCell name="VAN">Van</SortableCell>
      <SortableCell name="EMPLOYEE">Employee</SortableCell>
      <SortableCell name="ID">ID #</SortableCell>
      <SortableCell name="MANAGER">Manager</SortableCell>
      <SortableCell name="INJURY">Injury?</SortableCell>
      <SortableCell name="CREW_INVOLVED">Crew Involved?</SortableCell>
      <SimpleTableCell name="EDIT">
        <Tippy content="Edit Incident">
          <i className="sv sv-pencil" />
        </Tippy>
      </SimpleTableCell>
      <SimpleTableCell name="DELETE">
        <Tippy content="Delete Incident">
          <i className="sv sv-trash2" />
        </Tippy>
      </SimpleTableCell>
    </SimpleTableRow>
  );
};

const SelectAllCell = (): JSX.Element => {
  const [{ incidents, selected }, setState] = useIncidentHistory(({ incidents, selected }) => ({ incidents, selected }));

  const isSelectAll = useMemo((): boolean => {
    if (selected.size !== incidents.size) return false;
    for (const id of selected.keys()) {
      if (!incidents.has(id)) return false;
    }
    return true;
  }, [incidents, selected]);

  const onClick = useCallback(
    (): void => setState((current) => ({ ...current, selected: isSelectAll ? new Map() : incidents })),
    [incidents, isSelectAll, setState]
  );

  return <SimpleTableCell.Select checked={isSelectAll} onChange={onClick} />;
};
const SortableCell = ({ name, children }: { name: string; children: React.ReactNode }): JSX.Element => {
  const [{ sortBy, sortDirection }, setState] = useIncidentHistory(({ sortBy, sortDirection }) => ({ sortBy, sortDirection }));
  const onSort = (name: string, direction: SortDirectionEnum): void =>
    setState((current) => ({ ...current, sortBy: name, sortDirection: direction }));
  return (
    <SimpleTableCell.Sortable name={name} sortBy={sortBy} sortDirection={sortDirection} onSort={onSort}>
      {children}
    </SimpleTableCell.Sortable>
  );
};

export default IncidentHistoryTableColumns;
