import './styles.scss';

import { Dropdown, MenuProps } from 'antd';

import { Button } from 'react-bootstrap';
import { ReactNode } from 'react';
import { getClasses } from '@/utils';

export type ActionsDropdownProps = {
  items: MenuProps['items'];
  onClick: (key: string) => void;
  value?: string;
  icon?: ReactNode;
  className?: string;
};

const ActionsDropdown = ({ value, items, onClick, icon, className }: ActionsDropdownProps): ReactNode => {
  return (
    <Dropdown
      className="ActionsDropdown"
      menu={{
        onClick: ({ key }: { key: string }): void => onClick(key),
        items,
        selectedKeys: [value],
      }}
      trigger={['click']}
      overlayClassName={getClasses('ActionsDropdown-Menu', className)}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Button as="a" variant="text">
          {icon || <i className="fa fa-fw fa-ellipsis-v" />}
        </Button>
      </div>
    </Dropdown>
  );
};

export default ActionsDropdown;
