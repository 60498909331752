import { Config } from '@/state/session';
import NavigationDrawerItem from '@/components/Header/NavigationDrawer/NavigationDrawerItem';
import NavigationDrawerItemGroup from '@/components/Header/NavigationDrawer/NavigationDrawerItemGroup';

export const settingsPermissions: Record<string, keyof Config['auth']> = {
  bufferTimes: 'allowViewBufferTime',
  variableTimes: 'allowViewVariableTime',
  combineRules: 'allowViewCombineRule',
};

const SettingsGroup = (): JSX.Element => (
  <NavigationDrawerItemGroup title="Company Management" permissions={Object.values(settingsPermissions)}>
    <NavigationDrawerItem name="Buffer Times" to="/settings/buffer-times" restricted permissions={[settingsPermissions.bufferTimes]} />
    <NavigationDrawerItem
      name="Variable Times"
      to="/settings/variable-times"
      restricted
      permissions={[settingsPermissions.variableTimes]}
    />
    <NavigationDrawerItem name="Combine Rules" to="/settings/combine-rules" restricted permissions={[settingsPermissions.combineRules]} />
  </NavigationDrawerItemGroup>
);

export default SettingsGroup;
