import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Datetime, titleCase } from '@/utils';
import React, { ChangeEvent, useEffect } from 'react';

import EditModal from '@/components/EditModal/new';
import PhoneInput from '@/components/MaskedInput/PhoneInput';
import { STATUSES } from '@/constants';
import SelectAirportGroup from '@/components/SelectAirportGroup';
import SelectExchangeLocation from '@/components/SelectExchangeLocation';
import SelectInt from '../SelectInt';
import SelectState from '@/components/SelectState';
import SelectVehicleMake from '../SelectVehicleMake';
import SelectVehicleModel from '../SelectVehicleModel';
import SelectVehicleTracker from '@/components/SelectVehicleTracker';
import { VehicleMakeEnum } from '@/models/gen/graphql';
import { createVehicleValidator } from 'queries/vehicles/createVehicleBulk';
import useForm from 'hooks/useForm';
import useUuid from '@/hooks/useUuid';
import useValidation from '@/hooks/useValidation';

interface Props {
  show: boolean;
  title?: string;
  name: string;
  onHide?: Function;
  onSubmit?: Function;
  onExited?: Function;
  onCancel?: Function;
  data: any;
  options?: any;
  loading?: boolean;
}

const EditVehiclesModal = ({ show, title, onHide, onSubmit, onCancel, onExited, data, options = {} }: Props): JSX.Element => {
  const [form, onChange, setForm] = useForm(undefined);
  const [valid, validity] = useValidation(createVehicleValidator, form);

  const fields = options?.fields || {};
  const uuid = useUuid();
  const handleSubmit = (): void => {
    onSubmit(form);
  };

  useEffect((): void => {
    if (show) return setForm(data);
    setForm(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <EditModal
      show={show}
      title={title}
      icon="fa fa-location-dot"
      size="xl"
      onHide={onHide}
      onCancel={onCancel}
      onExited={onExited}
      onSubmit={valid ? handleSubmit : false}
      options={undefined}
      loading={false}
      name="editVehicles"
    >
      <Row className="{text-transform:uppercase!;font-size:0.8rem!;py:0!;text-align:right!;line-height:0.9rem;}_.input-group-text,label {margin-top:1rem;}_.input-group">
        {/* Left Column */}
        <Col xs={12} md={4}>
          <InputGroup>
            <InputGroup.Text>Vin:</InputGroup.Text>
            <Form.Control
              name="vin"
              value={form?.vin || ''}
              disabled={(fields?.vin || []).length > 1}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                event.target.value = event.target.value.toUpperCase();
                onChange(event);
              }}
              isInvalid={validity?.vin?.valid === false}
              isValid={validity?.vin?.valid === true}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Make:</InputGroup.Text>
            <SelectVehicleMake
              name="make"
              value={VehicleMakeEnum[titleCase(form?.make)] || form?.make || ''}
              disabled={(fields?.make || []).length > 1}
              onChange={(event) => setForm((current) => ({ ...current, [event.target.name]: event.target.value, model: '' }))}
              isInvalid={validity?.make?.valid === false}
              isValid={validity?.make?.valid === true}
              placeholder="--"
              searchable
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Model:</InputGroup.Text>
            <SelectVehicleModel
              name="model"
              value={form?.model || ''}
              make={VehicleMakeEnum[titleCase(form?.make)]}
              disabled={(fields?.model || []).length > 1}
              onChange={onChange}
              isInvalid={validity?.model?.valid === false}
              isValid={validity?.model?.valid === true}
              placeholder="--"
              searchable
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Year:</InputGroup.Text>
            <Form.Control
              className="w-50"
              type="number"
              min="1900"
              max="2099"
              value={form?.year || ''}
              name="year"
              onChange={onChange.int}
              disabled={(fields?.year || []).length > 1}
              isInvalid={validity?.year?.valid === false}
              isValid={validity?.year?.valid === true}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>License Plate:</InputGroup.Text>
            <Form.Control
              name="licensePlate"
              value={form?.licensePlate || ''}
              disabled={(fields?.licensePlate || []).length > 1}
              onChange={onChange}
              isInvalid={validity?.licensePlate?.valid === false}
              isValid={validity?.licensePlate?.valid === true}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Toll Tag:</InputGroup.Text>
            <Form.Control
              name="tollTag"
              value={form?.tollTag || ''}
              disabled={(fields?.tollTag || []).length > 1}
              onChange={onChange}
              isInvalid={validity?.tollTag?.valid === false}
              isValid={validity?.tollTag?.valid === true}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Tracking ID:</InputGroup.Text>
            <Form.Control
              name="trackingId"
              value={form?.trackingId || ''}
              disabled={(fields?.trackingId || []).length > 1}
              onChange={onChange}
              isInvalid={validity?.trackingId?.valid === false}
              isValid={validity?.trackingId?.valid === true}
            />
          </InputGroup>
        </Col>
        {/* Middle Column */}
        <Col xs={12} md={4}>
          <InputGroup>
            <InputGroup.Text>Phone Number:</InputGroup.Text>
            <PhoneInput
              name="phoneNumber"
              value={form?.phoneNumber || ''}
              disabled={(fields?.phoneNumber || []).length > 1}
              isInvalid={validity?.phoneNumber?.valid === false}
              isValid={validity?.phoneNumber?.valid === true}
              onChange={(value) => onChange({ target: { name: 'phoneNumber', value } })}
            />
          </InputGroup>
          {/* Int Input */}
          <InputGroup>
            <InputGroup.Text>Capacity:</InputGroup.Text>
            <Form.Control
              type="number"
              name="capacity"
              min="0"
              max="20"
              value={form?.capacity || ''}
              disabled={(fields?.capacity || []).length > 1}
              onChange={onChange}
              isInvalid={validity?.capacity?.valid === false}
              isValid={validity?.capacity?.valid === true}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>
              State
              <br />
              Registered:
            </InputGroup.Text>
            <SelectState
              name="stateRegistered"
              value={form?.stateRegistered || ''}
              disabled={(fields?.stateRegistered || []).length > 1}
              onChange={onChange}
              isInvalid={validity?.stateRegistered?.valid === false}
              isValid={validity?.stateRegistered?.valid === true}
              placeholder="--"
              searchable
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>
              Assigned
              <br />
              Airports:
            </InputGroup.Text>
            <SelectAirportGroup
              placeholder="--"
              name="airportCodes"
              value={(form?.airportCodes || []).map((node: any): string => node?.airportCode || node)}
              disabled={(fields?.airportCodes || []).length > 1}
              isInvalid={validity?.airportCodes?.valid === false}
              isValid={validity?.airportCodes?.valid === true}
              onChange={onChange}
              searchable
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>
              Exchange
              <br />
              Location:
            </InputGroup.Text>
            <SelectExchangeLocation
              placeholder="--"
              name="locationId"
              value={form?.locationId}
              disabled={(fields?.locationId || []).length > 1}
              isInvalid={validity?.locationId?.valid === false}
              isValid={validity?.locationId?.valid === true}
              onChange={onChange}
              searchable
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>
              Vehicle
              <br />
              Tracker:
            </InputGroup.Text>
            <SelectVehicleTracker
              placeholder="--"
              name="tracker"
              value={form?.tracker}
              disabled={(fields?.tracker || []).length > 1}
              isInvalid={validity?.tracker?.valid === false}
              isValid={validity?.tracker?.valid === true}
              onChange={onChange}
              searchable
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Status:</InputGroup.Text>
            <SelectInt
              name="active"
              value={form?.active}
              disabled={(fields?.active || []).length > 1}
              onChange={onChange}
              isInvalid={validity?.active?.valid === false}
              isValid={validity?.active?.valid === true}
              placeholder="--"
            >
              {STATUSES.default.map(
                (node: any, n: number): JSX.Element => (
                  <option key={n} value={node.id}>
                    {node.displayName}
                  </option>
                )
              )}
            </SelectInt>
          </InputGroup>
        </Col>
        {/* Right Column */}
        <Col xs={12} md={4}>
          <InputGroup>
            <InputGroup.Text>
              Registration
              <br />
              Expiry Date:
            </InputGroup.Text>
            <Form.Control
              type="date"
              name="registrationExpirationDate"
              value={form?.registrationExpirationDate ? new Datetime(form?.registrationExpirationDate).dateInput || '' : ''}
              isInvalid={validity?.registrationExpirationDate?.valid === false}
              isValid={validity?.registrationExpirationDate?.valid === true}
              onChange={onChange.date}
              disabled={(fields?.registrationExpirationDate || []).length > 1}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>
              Emission Permit
              <br />
              Expiry Date:
            </InputGroup.Text>
            {/* Switch Input */}
            <Form.Switch
              id={`hasEmissionPermit_${uuid}`}
              className="{h:2.25rem;py:0.5rem;m:0|1.5rem|0|0.8rem;} {font:bold}>label {scale:1.5;translateX(0.5rem)}>input"
              name="hasEmissionPermit"
              checked={!!form?.emissionPermitExpirationDate}
              disabled={(fields?.emissionPermitExpirationDate || []).length > 1}
              onChange={(): void =>
                onChange({
                  target: {
                    name: 'emissionPermitExpirationDate',
                    value: form?.emissionPermitExpirationDate ? null : new Datetime().toString(),
                  },
                })
              }
            />
            {/* Date Input */}
            <Form.Control
              type="date"
              name="emissionPermitExpirationDate"
              isInvalid={validity?.emissionPermitExpirationDate?.valid === false}
              isValid={validity?.emissionPermitExpirationDate?.valid === true}
              value={form?.emissionPermitExpirationDate ? new Datetime(form?.emissionPermitExpirationDate).dateInput || '' : ''}
              onChange={onChange.date}
              disabled={!form?.emissionPermitExpirationDate || (fields?.emissionPermitExpirationDate || []).length > 1}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>
              Airport Permit
              <br />
              Expiry Date:
            </InputGroup.Text>
            {/* Switch Input */}
            <Form.Switch
              id={`hasAirportPermit_${uuid}`}
              className="{h:2.25rem;py:0.5rem;m:0|1.5rem|0|0.8rem;} {font:bold}>label {scale:1.5;translateX(0.5rem)}>input"
              name="hasAirportPermit"
              checked={!!form?.airportPermitExpirationDate}
              disabled={(fields?.airportPermitExpirationDate || []).length > 1}
              onChange={(): void =>
                onChange({
                  target: {
                    name: 'airportPermitExpirationDate',
                    value: form?.airportPermitExpirationDate ? null : new Datetime().toString(),
                  },
                })
              }
            />
            {/* Date Input */}
            <Form.Control
              type="date"
              name="airportPermitExpirationDate"
              isInvalid={validity?.airportPermitExpirationDate?.valid === false}
              isValid={validity?.airportPermitExpirationDate?.valid === true}
              value={form?.airportPermitExpirationDate ? new Datetime(form?.airportPermitExpirationDate).dateInput || '' : ''}
              onChange={onChange.date}
              disabled={!form?.airportPermitExpirationDate || (fields?.airportPermitExpirationDate || []).length > 1}
            />
          </InputGroup>
        </Col>
      </Row>
    </EditModal>
  );
};

export default EditVehiclesModal;
