import './styles.scss';

import Input, { InputProps } from '@/components/Input';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { fieldWrapper } from '@/components/Field';
import { getClasses } from '@/utils';

export type CurrencyInputProps = {
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement> & { target: { value?: string } }) => void;
} & Omit<InputProps, 'onKeyDown'>;

// Regex for allowed characters in the input.
const ALLOWED_INPUTS = /[0-9.]/;

const CurrencyInput = ({
  className,
  onKeyDown,
  onFocus,
  onBlur,
  onChange,
  value: inputValue,
  isValid,
  isInvalid,
  ...inputProps
}: CurrencyInputProps): ReactNode => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(inputValue?.toString?.() || parseFloat('0.00').toFixed(2));

  const formatValue = useCallback((input): string => {
    // Get the current value.
    // Format the value to two decimal places.
    const formattedValue = (parseFloat(input) || 0).toFixed(2);
    // Return the formatted value for use elsewhere.
    setValue(formattedValue);
    return formattedValue;
  }, []);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
    // Select the input when it is focused.
    inputRef.current?.select?.();
    onFocus?.(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    // Only format the value on blur and update the event.
    event.target.value = formatValue(value);
    onChange?.(event.target.value);
    onBlur?.(event);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement> & { target: { value: string } }): void => {
    if (event.ctrlKey || event.metaKey) return; // Allow copy, paste, etc.
    const isAllSelected = inputRef.current?.selectionStart === 0 && inputRef.current?.selectionEnd === value.length; // Check if the entire value is selected.
    switch (true) {
      case event.key === 'Enter': {
        // Format the value and update the event.
        event.target.value = formatValue(value);
        // Pressing enter should blur the field, unless an onKeyDown handler is passed.
        if (onKeyDown) return onKeyDown?.(event);
        return inputRef.current?.blur?.();
      }
      case event.key === '.' && value.includes('.') && !isAllSelected: // Only allow one decimal point unless we're selecting all of the value.
      case event.key.length === 1 && !ALLOWED_INPUTS.test(event.key): // Only allow numbers and decimal points.
        return event.preventDefault();
      default: // Call onKeyDown if it exists.
        return onKeyDown?.(event);
    }
  };

  const handleChange = (value: string): void => {
    if (value.startsWith('.')) value = '0.'; // If the first character is a decimal point, prepend a zero.
    setValue(value);
  };

  // AUTO FOCUS EFFECT
  useEffect(() => {
    if (!inputProps?.autoFocus) return;
    // Select the input if autoFocus is enabled after a short delay to allow the input to mount.
    const pauseForMount = setTimeout(() => inputRef.current?.select?.());
    return (): void => clearTimeout(pauseForMount);
  }, [inputProps?.autoFocus]);

  // PARENT VALUE / STATE SYNC EFFECT
  useEffect(() => {
    if ((inputValue ?? false) === false) return;
    const formattedValue = formatValue(inputValue);
    if (formattedValue !== value) onChange(formattedValue);
    setValue(formattedValue);
    /* NOTE:
      Adding value to this array causes an infinite loop. This is because the value is updated in the formatValue function, which triggers a re-render.
      It is not needed for the comparison logic here, as it is only to update the parent's value on mount so that state and props match.
    */ // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatValue, inputValue, onChange]);

  return (
    <span className={getClasses('CurrencyInput', className)}>
      <Input
        {...inputProps}
        value={value}
        ref={inputRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        isValid={inputValue === value ? isValid : undefined}
        isInvalid={inputValue === value ? isInvalid : undefined}
      />
    </span>
  );
};

export const CurrencyInputField = fieldWrapper<CurrencyInputProps>(CurrencyInput);
CurrencyInput.Field = CurrencyInputField;

export default CurrencyInput;
