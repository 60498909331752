import './styles.scss';

import { IssueHistoryRow, useIssueHistory } from '@/features/IssueHistory';

import { Datetime } from '@/utils';
import IssueHistoryTableColumns from '@/features/IssueHistory/components/IssueHistoryTable/IssueHistoryTableColumns';
import IssueHistoryTableRow from '@/features/IssueHistory/components/IssueHistoryTable/IssueHistoryTableRow';
import SimpleTable from '@/components/SimpleTable';
import { SortDirectionEnum } from '@/models/gen/graphql';

const IssueHistoryTable = (): JSX.Element => {
  const [{ issues: sortedIssues }] = useIssueHistory(({ issues, sortBy, sortDirection }) => ({
    issues: Array.from(issues.keys()).sort((a: string, b: string): number => {
      if (!sortDirection || !sortBy) return 0;
      const sortIssues = compareIssuesBy(sortBy);
      const result = sortIssues(issues.get(a), issues.get(b));
      if (sortDirection === SortDirectionEnum.Desc) return -result;
      return result;
    }),
  }));

  return <SimpleTable className="IssueHistoryTable" rows={sortedIssues} columns={IssueHistoryTableColumns} row={IssueHistoryTableRow} />;
};

const compareIssuesBy =
  (sortBy: string) =>
  (issueA: IssueHistoryRow, issueB: IssueHistoryRow): number => {
    if (!issueA || !issueB) return 0;
    switch (sortBy) {
      case 'AIRPORT':
        return issueA.airport.localeCompare(issueB.airport);
      case 'DATETIME':
        return new Datetime(issueA.datetime).diff(issueB.datetime, 'millisecond');
      case 'AIRLINE':
        return issueA.airline.localeCompare(issueB.airline);
      case 'FLIGHT_NUMBER':
        return issueA.flightNumber.localeCompare(issueB.flightNumber);
      case 'EXPLOYEE':
        return 0;
      case 'ROOT_CAUSE':
        return 0;
      case 'TAXI_CALLED':
        return 0;
      case 'TAXI_COST':
        return 0;
      case 'RECOVERY':
        return issueA.recovery === issueB.recovery ? 0 : issueA.recovery ? -1 : 1;
      case 'PLANE_PUSHED':
        return issueA.planePushed === issueB.planePushed ? 0 : issueA.planePushed ? -1 : 1;
      default:
        return 0;
    }
  };

export default IssueHistoryTable;
