import AccidentHistoryDrawer from '@/features/AccidentHistory/components/AccidentHistoryDrawer';
import ChangePasswordModal from '@/components/ChangePasswordModal';
import ConfirmTripRatesModal from '@/pages/Rates/ConfirmTripRatesModal';
import { Container } from 'react-modal-promise';
import DebugModal from '@/components/ModalContainer/DebugModal';
import EditCombine from '@/pages/Trips/EditCombine';
import EditCommunication from '@/pages/Trips/EditCommunication';
import EditCompletion from '@/pages/Trips/EditCompletion';
import EditFcr from '@/pages/Trips/EditFcr';
import EditFlag from '@/pages/Trips/EditFlag';
import EditPotentialTripsModal from '@/components/EditPotentialTripsModal';
import EditRatesModal from '@/components/EditRatesModal';
import GlobalEditLocationModal from '@/components/GlobalEditLocationModal';
import IncidentHistoryDrawer from '@/features/IncidentHistory/components/IncidentHistoryDrawer';
import IssueHistoryDrawer from '@/features/IssueHistory/components/IssueHistoryDrawer';
import LostAndFoundHistoryDrawer from '@/features/LostAndFoundHistory/components/LostAndFoundHistoryDrawer';
import RateReportModal from '@/components/RateReportModal';
import SummaryHistoryDrawer from '@/features/SummaryHistory/components/SummaryHistoryDrawer';
import TripModal from '@/features/Trips/components/TripModal';

const ModalContainer = (): JSX.Element => {
  return (
    <>
      {/* Start Custom Modals */}
      <ChangePasswordModal type="change" />
      <TripModal />
      <EditCompletion />
      <EditFcr />
      <EditCombine />
      <EditFlag />
      <EditCommunication />
      <RateReportModal />
      <EditPotentialTripsModal />
      <EditRatesModal />
      <ConfirmTripRatesModal />
      <GlobalEditLocationModal />
      <LostAndFoundHistoryDrawer />
      <IncidentHistoryDrawer />
      <AccidentHistoryDrawer />
      <SummaryHistoryDrawer />
      <IssueHistoryDrawer />
      {/* End Custom Modals */}
      <>
        <DebugModal />
        <Container />
      </>
    </>
  );
};

export default ModalContainer;
