import useSession, { Config } from '@/state/session';

export type UseHasPermissionCheck = 'some' | 'every' | ((permissions: (keyof Config['auth'])[]) => boolean);

const useHasPermission = (permissions: (keyof Config['auth'])[], check: UseHasPermissionCheck = 'some'): boolean => {
  const hasPermission = useSession(({ state }) => {
    if (!permissions?.length) return true;
    if (typeof check === 'function') return check?.(permissions);
    if (!check) return false;
    return permissions?.[check]?.((key: keyof Config['auth']): boolean => !!(state.config?.auth || ({} as Config['auth']))?.[key]) || false;
  });
  return hasPermission;
};

export default useHasPermission;
