import { Datetime, Schema } from '@/utils';
import {
  LostAndFoundIssueReturnStatusEnum,
  LostAndFoundIssueWithCache,
  LostAndFoundIssueWithCacheReturnStatusEnum,
} from '@/models/gen/graphql';

export namespace LostAndFoundHistoryFormDefinitions {
  export type Locale =
    | 'City'
    | 'Create Lost and Found'
    | 'Crew Member Email'
    | 'Crew Member Name'
    | 'Crew Member Phone'
    | 'Description'
    | 'Dispatcher'
    | 'Description of Item'
    | 'Driver Name'
    | 'Edit Lost and Found'
    | 'Invalid date format'
    | 'Invalid email format'
    | 'Item Lost'
    | 'Lost and Found'
    | 'Lost and Found History'
    | 'Must be a positive number'
    | 'Must be exactly 2 characters'
    | 'Must be exactly 4 characters'
    | 'Must be exactly 5 characters'
    | 'Must not exceed 256 characters'
    | 'Must not exceed 512 characters'
    | 'Must not exceed 64 characters'
    | 'Notes'
    | 'Outbound FLT #'
    | 'Outbound Date/Time'
    | 'Return Status'
    | 'Required'
    | 'State'
    | 'SUBMIT_BUTTON_TEXT'
    | 'Shipping Address'
    | 'Shipping Cost'
    | 'Shipping Method'
    | 'Tracking Number'
    | 'Upload Files'
    | 'Van Number'
    | 'Zip Code';
  export type Value = Partial<LostAndFoundIssueWithCache>;
  export type Props = {
    onSubmit?: (data: Value) => void | Value | Promise<Value> | undefined;
    locale?: Partial<Record<Locale, string>>;
    value?: Value;
  };
  export const initValue: Value = {
    id: undefined,
    crewMemberEmail: '',
    crewMemberName: '',
    crewMemberPhone: '',
    description: '',
    dispatcherId: '',
    driverId: '',
    itemLost: '',
    notes: '',
    outboundDatetime: '',
    outboundFlightNumber: undefined,
    returnStatus: LostAndFoundIssueWithCacheReturnStatusEnum.Created,
    shippingAddress: '',
    shippingCity: '',
    shippingCost: 0,
    shippingMethod: '',
    shippingStateCode: '',
    shippingZipCode: '',
    trackingNumber: '',
    tripId: '',
    vehicleId: '',
  };
  export const getLocale = (value: Value): Partial<Record<Locale, string>> => ({
    SUBMIT_BUTTON_TEXT: value.id ? 'Edit Lost and Found' : 'Create Lost and Found',
  });
  export const validator = (locale: (original: Locale) => string) =>
    Schema((z) =>
      z.object({
        id: z.string().uuid(),
        crewMemberEmail: z.string().email({ message: locale('Invalid email format') }),
        crewMemberName: z
          .string()
          .max(64, { message: locale('Must not exceed 64 characters') })
          .refine((val: string): boolean => val?.trim() !== '', { message: locale('Required') }),
        crewMemberPhone: z.string().refine((val: string): boolean => val?.trim() !== '', { message: locale('Required') }),
        description: z
          .string()
          .min(1, { message: locale('Required') })
          .max(512, { message: locale('Must not exceed 512 characters') }),
        dispatcherId: z.string().uuid({ message: 'Required' }),
        driverId: z.string().uuid({ message: 'Required' }),
        itemLost: z
          .string()
          .min(1, { message: 'Required' })
          .max(64, { message: locale('Must not exceed 64 characters') }),
        notes: z.string().max(512, { message: locale('Must not exceed 512 characters') }),
        outboundDatetime: z.string().refine((val: string): boolean => !!val && !!new Datetime(val).toString()),
        outboundFlightNumber: z
          .string()
          .min(1, { message: 'Required' })
          .max(4, { message: locale('Must be exactly 4 characters') }),
        returnStatus: z.nativeEnum(LostAndFoundIssueReturnStatusEnum, { message: 'Required' }),
        shippingAddress: z.string().max(256, { message: locale('Must not exceed 256 characters') }),
        shippingCity: z.string().max(64, { message: locale('Must not exceed 64 characters') }),
        shippingCost: z
          .string()
          .refine((val: string = '0'): boolean => parseFloat(val) >= 0, { message: locale('Must be a positive number') }),
        shippingMethod: z.string().max(64, { message: locale('Must not exceed 64 characters') }),
        shippingStateCode: z.string().length(2, { message: locale('Must be exactly 2 characters') }),
        shippingZipCode: z.string().length(5, { message: locale('Must be exactly 5 characters') }),
        trackingNumber: z.string().max(64, { message: locale('Must not exceed 64 characters') }),
        tripId: z.string().uuid({ message: 'Required' }),
        vehicleId: z.string().uuid({ message: 'Required' }),
      })
    );
}
