// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.Feature {
  position: relative;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
main.Feature > .container {
  max-width: initial;
  padding: 0 4rem;
}
main.Feature > .LoadingBlur {
  position: fixed;
  top: var(--header-height);
  width: 100%;
  height: calc(100% - var(--header-height));
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/common/Feature/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,kBAAA;EACA,eAAA;AAEJ;AAAE;EACE,eAAA;EACA,yBAAA;EACA,WAAA;EACA,yCAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,oBAAA;AAEJ","sourcesContent":["main.Feature {\n  position: relative;\n  flex-grow: 1;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\nmain.Feature > .container {\n  max-width: initial;\n  padding: 0 4rem;\n}\nmain.Feature > .LoadingBlur {\n  position: fixed;\n  top: var(--header-height);\n  width: 100%;\n  height: calc(100% - var(--header-height));\n  bottom: 0;\n  left: 0;\n  right: 0;\n  pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
