import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';

import { fieldWrapper } from '@/components/Field';
import { getStates } from '@/api/services/filters/states';
import { useCallback } from 'react';

export type StateDropdownProps = {} & Omit<QueryDropdownProps, 'query'>;

const StateDropdown = ({ ...queryDropdownProps }: StateDropdownProps): JSX.Element => {
  const getItems = useCallback(async () => {
    const res = await getStates();
    return (res || []).map(({ id, displayName }) => ({
      label: displayName,
      keywords: [displayName],
      value: id,
    }));
  }, []);

  return <QueryDropdown name="state" {...queryDropdownProps} query={getItems} />;
};

export const StateDropdownField = fieldWrapper<StateDropdownProps>(StateDropdown);

export default StateDropdown;
