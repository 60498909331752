import { Button, Col, Collapse, Container, InputGroup, Row } from 'react-bootstrap';
import { Datetime, Validation, getClasses } from '@/utils';
import { EventObject, OnChange } from '@/hooks/useOnChange';
import { Location, Stop, StopTypeEnum, TrackFlightLabelEnum, TripKindEnum, TripStatusEnum, TripTypeEnum } from '@/models/gen/graphql';
import React, { useCallback, useMemo, useState } from 'react';
import useTripView, { TripStop, UseTripViewState } from '@/features/Trips/components/TripModal/views/trip/hook';
import TippyWhen from '@/components/TippyWhen';
import { TripViewProps } from '@/features/Trips/components/TripModal/views/trip';
import { TripViewHeader } from '@/features/Trips/components/TripModal/views/trip/modal';
import { NumberInputField } from '@/components/NumberInput';
import Calendar from '@/components/Calendar';
import equal from 'fast-deep-equal/es6/react';
import { TripTypeDropdownField } from '@/components/TripTypeDropdown';
import { ClientDropdownField } from '@/components/ClientDropdown';
import { AirlineIataDropdownField } from '@/components/AirlineDropdown';
import AirportDropdown, { AirportDropdownField } from '@/components/AirportDropdown';
import { InputField } from '@/components/Input';
import { KindsDropdownField } from '@/components/KindsDropdown';
import TripStopTypeDropdown from '@/components/TripStopTypeDropdown';
import LocationQueryDropdown from '@/components/LocationDropdown';
import TimeInput, { TimeInputField } from '@/components/TimeInput';
import { TripFlagDropdownField } from '@/components/TripFlagDropdown';
import { TripStatusDropdownField } from '@/components/TripStatusDropdown';
import Field from '@/components/Field';
import { TextAreaField } from '@/components/TextArea';
import { DatetimeInputField } from '@/components/DatetimeInput';
import SwitchInput from '@/components/SwitchInput';
import useSession from '@/state/session';
import useDoubleKeyDown from '@/hooks/useDoubleKeyDown';
import HasPermission from '@/components/HasPermission';

export const useValidity = (validity, isValid, trip, data): [boolean, (name: string) => boolean] => {
  const isEqual = equal(trip, data) && (trip?.pilots > 0 || trip?.attendants > 0);
  const valid = isValid && !isEqual;

  const getValidity = useCallback(
    (name: string): boolean => {
      return validity?.[name]?.valid === Validation.ValidityType.VALID
        ? true
        : validity?.[name]?.valid === Validation.ValidityType.INVALID || validity?.[name]?.valid === Validation.ValidityType.INVALID_WARNING
          ? false
          : undefined;
    },
    [validity]
  );
  return [valid, getValidity];
};

const TripForm = (props: TripViewProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [
    { data, title, mode, trip, stopsDisabled, validity, loopOrFlightNumber, selectedCount, isValid },
    { onChange, setState, onSaveAndCopy, handleSubmit, handleHide, onBlurFlightNumber, onAddStop },
  ] = useTripView(props);
  const upsProviderId = useSession(({ state }) => state.config?.upsProviderId || '');
  const [valid, getValidity] = useValidity(validity, isValid, trip, data);

  const MemoizedTripStop = useMemo(() => {
    const TripsStopComponent = (props: {
      index: number;
      data: Partial<Stop>;
      onCreateLocation: (location: Location) => void;
    }): JSX.Element => {
      return (
        <TripStop
          onDelete={(): void =>
            setState(
              (current: UseTripViewState): UseTripViewState => ({
                ...current,
                trip: {
                  ...(current?.trip || {}),
                  stops: (current?.trip?.stops || []).filter((_stop: Partial<Stop>, i: number): boolean => i !== props?.index),
                },
              })
            )
          }
          onChange={(event: EventObject): void => {
            const { name, value } = event?.target || {};
            switch (name.split('.').pop()) {
              case 'locationId':
                return value !== '' ? onChange(event) : undefined;
              case 'airportFilter':
                setState((current) => ({
                  ...current,
                  trip: {
                    ...current?.trip,
                    stops: (current?.trip?.stops || []).map((stop, i) => {
                      if (i === props?.index) {
                        return {
                          ...stop,
                          locationId: '',
                          airportCode: value,
                        };
                      }
                      return stop;
                    }),
                  },
                }));
              default:
                return onChange(event);
            }
          }}
          onCreateLocation={props?.onCreateLocation}
          valid={{
            locationId: Validation.isValidUUID(props?.data?.locationId) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
          }}
          options={{
            filterLocationByAirport: true,
          }}
          disabled={mode === 'editing' && stopsDisabled}
          readOnly={mode === 'editing' && stopsDisabled}
          tripScheduledTime={trip?.scheduled}
          {...props}
        />
      );
    };
    return TripsStopComponent;
    // TODO: Why is this being ignored?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDoubleKeyDown('Enter', () => {
    if (valid) handleSubmit();
  });

  return (
    <Container className="contain-inline pb-4" fluid>
      <TripViewHeader manifestImportId={trip?.manifestImportId} title={title} />
      <Row>
        <Col xs={6}>
          <TripTypeDropdownField
            name="trip.type"
            label="Sch Type:"
            value={trip?.type || ''}
            valid={getValidity('type')}
            dirty={validity?.type?.valid === Validation.ValidityType.VALID_WARNING}
            onChange={(value: TripTypeEnum) => onChange({ target: { name: 'trip.type', value } })}
          />
          <ClientDropdownField
            label="Client:"
            name="trip.payerProviderId"
            value={trip?.payerProviderId || ''}
            valid={getValidity('payerProviderId')}
            dirty={validity?.payerProviderId?.valid === Validation.ValidityType.VALID_WARNING}
            onChange={(value: string) => {
              onChange.bulk([{ target: { name: 'trip.providerId', value } }, { target: { name: 'trip.payerProviderId', value } }]);
            }}
          />
          <AirlineIataDropdownField
            label="Airline:"
            placeholder="Select Airline"
            name="trip.servicerIataAirlineCode"
            value={trip?.servicerIataAirlineCode || ''}
            valid={getValidity('servicerIataAirlineCode')}
            dirty={validity?.servicerIataAirlineCode?.valid === Validation.ValidityType.VALID_WARNING}
            onChange={(value: string) => onChange({ target: { name: 'trip.servicerIataAirlineCode', value } })}
          />
          <AirportDropdownField
            label="Airport:"
            name="trip.airportCode"
            value={trip?.airportCode || ''}
            valid={getValidity('airportCode')}
            dirty={validity?.airportCode?.valid === Validation.ValidityType.VALID_WARNING}
            onChange={(value: string): void => {
              onChange.bulk([
                { target: { name: 'trip.airportCode', value } },
                { target: { name: 'trip.doAirportCode', value } },
                { target: { name: 'trip.puLocationId', value: '' } },
                { target: { name: 'trip.doLocationId', value: '' } },
                {
                  target: {
                    name: 'trip.stops',
                    value: (trip?.stops || []).map(
                      (stop: Partial<Stop>): TripStop => ({
                        ...stop,
                        airportFilter: value,
                        airportCode: value,
                        locationId: '',
                      })
                    ),
                  },
                },
              ]);
            }}
          />
        </Col>
        <Col xs={6}>
          <Field label="Pickup Date:">
            <Calendar
              name={mode === 'creating' ? 'trip.scheduledDays' : 'trip.scheduled'}
              value={mode === 'creating' ? trip?.scheduledDays || [] : trip?.scheduled ? [trip.scheduled] : []}
              selector="day"
              onChange={
                mode === 'creating'
                  ? onChange
                  : (event: EventObject): void => {
                      const { value: initValue } = event?.target || {};
                      const value = (Array.isArray(initValue) ? initValue[0] : initValue) || '';
                      if (!value || trip?.increment) return;
                      const currentScheduledTime = trip?.scheduled?.split('T')?.[1];
                      const newScheduledTime = currentScheduledTime ? value.concat(' ', currentScheduledTime) : value;
                      const result: EventObject[] = [
                        { target: { name: 'trip.scheduled', value: new Datetime(newScheduledTime).toString() } },
                      ];
                      if (trip?.stops?.length) {
                        const originalStops = [...(trip?.stops || [])];
                        const stops = originalStops.map(
                          (stop: Stop): Stop => ({
                            ...stop,
                            scheduled:
                              value && stop?.scheduled ? new Datetime(stop?.scheduled || undefined).setDate(value).toString() : null,
                          })
                        );
                        result.push({ target: { name: 'trip.stops', value: stops } });
                      }
                      onChange.bulk(result);
                    }
              }
              range={false}
              multiple={mode === 'creating'}
              disabled={mode === 'creating' && (selectedCount > 1 || trip?.increment)}
              isValid={mode === 'creating' ? !!trip?.scheduledDays?.length : !!validity?.scheduled?.valid}
              isInvalid={mode === 'creating' ? !trip?.scheduledDays?.length : !validity?.scheduled?.valid}
              isDirty={validity?.scheduled?.valid === Validation.ValidityType.VALID_WARNING}
            />
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <TimeInputField
            name="trip.scheduled"
            label="SCH Time:"
            value={trip?.scheduled ? new Datetime(trip?.scheduled).fullTime : ''}
            valid={getValidity('scheduled')}
            dirty={validity?.scheduled?.valid === Validation.ValidityType.VALID_WARNING}
            disabled={stopsDisabled}
            onChange={(event: EventObject) => {
              const value = event?.target?.value;
              if (!value) return;
              const formattedTime = new Datetime(trip?.scheduled).setTime(value).time;
              const input = { target: { name: 'trip.scheduled', value: formattedTime } };
              onChange.time(input);
            }}
          />
        </Col>
        <Col>
          <TimeInputField name="trip.actual" label="ACT Time:" value={''} onChange={() => {}} disabled={true} />
        </Col>
      </Row>
      {mode === 'editing' && (
        <Row>
          <Col>
            <DatetimeInputField
              label="Arrival Date / Time:"
              value={trip?.trackFlight?.actual || ''}
              onChange={(value) => onChange({ target: { name: 'trip.trackFlight.actual', value } })}
              disabled={!trip?.trackFlightId}
            />
          </Col>
          <Col>
            <SwitchInput
              label="Flight Gate Arrived?"
              checked={trip?.trackFlight?.label === TrackFlightLabelEnum.Gated}
              onChange={(checked) =>
                onChange({
                  target: { name: 'trip.trackFlight.label', value: checked ? TrackFlightLabelEnum.Gated : TrackFlightLabelEnum.Unknown },
                })
              }
              checkedChildren="Yes"
              unCheckedChildren="No"
              disabled={!trip?.trackFlightId || data?.trackFlight?.label === TrackFlightLabelEnum.Gated}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <KindsDropdownField
            label="Arrival/Departure:"
            placeholder="Select Kind"
            name="trip.kind"
            value={trip?.kind || ''}
            valid={getValidity('kind')}
            dirty={validity?.kind?.valid === Validation.ValidityType.VALID_WARNING}
            onChange={(value: TripKindEnum): void => {
              const { puLocationId = '', doLocationId = '' } = trip || {};
              onChange.bulk([
                { target: { name: 'trip.kind', value } },
                { target: { name: 'trip.puLocationId', value: doLocationId } },
                { target: { name: 'trip.doLocationId', value: puLocationId } },
                { target: { name: 'trip.airportCode', value: trip?.doAirportCode } },
                { target: { name: 'trip.doAirportCode', value: trip?.doAirportCode } },
              ]);
            }}
          />
        </Col>
        <Col>
          <InputField
            label="Flight # / Loop Name:"
            name="trip.loopOrFlightNumber"
            value={loopOrFlightNumber || ''}
            valid={getValidity('flightNumber') || getValidity('loopName')}
            dirty={
              validity?.flightNumber?.valid === Validation.ValidityType.VALID_WARNING ||
              validity?.loopName?.valid === Validation.ValidityType.VALID_WARNING
            }
            onBlur={onBlurFlightNumber}
            onChange={(value: string) => {
              const isLoopName = value.length > 4 || value.match(/\D/g);
              const flightNumber = !isLoopName ? value : undefined;
              const loopName = isLoopName ? value : undefined;
              setState(
                (current: UseTripViewState): UseTripViewState => ({
                  ...current,
                  loopOrFlightNumber: value,
                  trip: { ...(current?.trip || {}), flightNumber, loopName },
                })
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberInputField
            label="PLT:"
            name="trip.pilots"
            value={trip?.pilots || 0}
            onChange={(value: number) => onChange({ target: { name: 'trip.pilots', value } })}
            valid={trip?.pilots > 0 || trip?.attendants > 0}
            min={0}
            max={99}
          />
        </Col>
        <Col>
          <NumberInputField
            label="F/A:"
            name="trip.attendants"
            value={trip?.attendants || 0}
            onChange={(value: number) => onChange({ target: { name: 'trip.attendants', value } })}
            valid={trip?.attendants > 0 || trip?.pilots > 0}
            min={0}
            max={99}
          />
        </Col>
      </Row>
      {trip?.payerProviderId === upsProviderId && (
        <>
          <Row>
            <Col>
              <NumberInputField
                label="Crew ID:"
                name="trip.crewId"
                isValid={getValidity('crewId') === true}
                isInvalid={getValidity('crewId') === false}
                isDirty={validity?.crewId?.valid === Validation.ValidityType.VALID_WARNING}
                value={trip?.crewId || null}
                onChange={(value: number) => onChange.int({ target: { name: 'trip.crewId', value } })}
                className="CrewId"
                min={0}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputField
                label="First Name:"
                name="trip.firstName"
                value={trip?.firstName || ''}
                isValid={getValidity('firstName') === true}
                isInvalid={getValidity('firstName') === false}
                isDirty={validity?.firstName?.valid === Validation.ValidityType.VALID_WARNING}
                onChange={(value: string) => onChange({ target: { name: 'trip.firstName', value } })}
              />
            </Col>
            <Col>
              <InputField
                label="Last Name:"
                name="trip.lastName"
                value={trip?.lastName || ''}
                isValid={getValidity('lastName') === true}
                isInvalid={getValidity('lastName') === false}
                isDirty={validity?.lastName?.valid === Validation.ValidityType.VALID_WARNING}
                onChange={(value: string) => onChange({ target: { name: 'trip.lastName', value } })}
              />
            </Col>
          </Row>
        </>
      )}
      <TripStop
        data={{
          type: StopTypeEnum.Pu,
          airportFilter: trip?.airportCode || '',
          locationId: trip?.puLocationId,
          scheduled: trip?.scheduled,
        }}
        valid={{ locationId: validity?.puLocationId?.valid, scheduled: validity?.scheduled?.valid }}
        index={-1}
        tripScheduledTime={trip?.scheduled}
        onChange={(event: EventObject) => {
          const { name, value } = event?.target || {};
          switch (name.split('.').pop()) {
            case 'locationId':
              return value !== '' ? onChange({ target: { name: 'trip.puLocationId', value } }) : undefined;
            default:
              return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
          }
        }}
        onCreateLocation={(location: Location): void => {
          onChange({ target: { name: `trip.puLocationId`, value: location?.id } });
        }}
        options={{
          autoSelectLocation: trip?.kind === TripKindEnum.Arr ? trip?.airportCode : undefined,
          disableType: true,
          hideCity: true,
          hideScheduled: true,
          location: {
            props: {
              query: {
                airportCode: trip?.airportCode || null,
              },
            },
          },
        }}
      />
      {trip?.stops?.length > 0 &&
        trip?.stops.map((stop, s) => (
          <MemoizedTripStop
            key={s}
            index={s}
            data={stop}
            onCreateLocation={(location: Location): void => {
              onChange({ target: { name: `trip.stops.${s}.locationId`, value: location?.id } });
            }}
          />
        ))}
      <TripStop
        data={{
          type: StopTypeEnum.Do,
          locationId: trip?.doLocationId,
          scheduled: trip?.doScheduledDatetime,
          airportFilter: trip?.doAirportCode || trip?.airportCode || '',
        }}
        tripScheduledTime={trip?.scheduled}
        valid={{ locationId: validity?.doLocationId?.valid }}
        index={trip?.stops?.length || 0}
        onChange={(event: EventObject) => {
          const { name, value } = event?.target || {};
          switch (name.split('.').pop()) {
            case 'locationId':
              return value !== '' ? onChange({ target: { name: 'trip.doLocationId', value } }) : undefined;
            case 'airportFilter':
              onChange.bulk([{ target: { name: 'trip.doLocationId', value: '' } }, { target: { name: 'trip.doAirportCode', value } }]);
            default:
              return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
          }
        }}
        onCreateLocation={(location: Location): void => {
          onChange({ target: { name: `trip.doLocationId`, value: location?.id } });
        }}
        options={{
          autoSelectLocation: trip?.kind === TripKindEnum.Dep ? trip?.airportCode : undefined,
          filterLocationByAirport: true,
          disableType: true,
          disableTime: true,
          hideScheduled: true,
        }}
      />
      <Row>
        <Col>
          <Button name="ADD_STOP" className="w-100 my-3 add-stop-btn" variant="outline-gray" onClick={onAddStop} disabled={stopsDisabled}>
            <i className="fa fa-plus" /> ADD STOP
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            className="view-more-button"
            variant="text"
            onClick={() => setShowMore((current) => !current)}
            aria-controls="collapse-additional-drawer-fields"
            aria-expanded={showMore}
          >
            {showMore ? 'View Less' : 'View More'}
          </Button>
        </Col>
      </Row>
      <Collapse in={showMore}>
        <div id="collapse-additional-drawer-fields">
          <Row>
            <Col>
              <TimeInputField
                name="trip.puActualDatetime"
                label="RFP:"
                value={trip?.puActualDatetime ? new Datetime(trip?.puActualDatetime).fullTime : ''}
                valid={validity?.puActualDatetime?.valid === 1 ? true : validity?.puActualDatetime?.valid === 2 ? false : undefined}
                dirty={validity?.puActualDatetime?.valid === Validation.ValidityType.VALID_WARNING}
                disabled={stopsDisabled}
                onChange={onChange?.time || onChange}
                tabIndex={-1}
              />
            </Col>
            <Col>
              <TimeInputField
                name="trip.puCompletedDatetime"
                label="P/U:"
                value={trip?.puCompletedDatetime ? new Datetime(trip?.puCompletedDatetime).fullTime : ''}
                valid={validity?.puCompletedDatetime?.valid === 1 ? true : validity?.puCompletedDatetime?.valid === 2 ? false : undefined}
                dirty={validity?.puCompletedDatetime?.valid === Validation.ValidityType.VALID_WARNING}
                disabled={stopsDisabled}
                onChange={onChange?.time || onChange}
                tabIndex={-1}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TripFlagDropdownField
                label={'Flags:'}
                placeholder="No Flags"
                name="trip.flags"
                value={(trip?.flags || ([] as string[])).map((node) => node.id || node)}
                onChange={(value) =>
                  onChange({
                    target: {
                      name: 'trip.flags',
                      value: (trip?.flags || []).includes(value)
                        ? (trip?.flags || []).filter((id) => id !== value)
                        : [...(trip?.flags || []), value],
                    },
                  })
                }
                disabled={selectedCount > 1}
              />
            </Col>
            <Col className="w-100">
              <TripStatusDropdownField
                label={'Status:'}
                placeholder="Select Status"
                name="trip.status"
                value={trip?.status || ''}
                valid={getValidity('status')}
                dirty={validity?.status?.valid === Validation.ValidityType.VALID_WARNING}
                onChange={(value: TripStatusEnum) => onChange({ target: { name: 'trip.status', value } })}
                options={{ showClearButton: false }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SwitchInput
                label="Include this trip in future manifests?"
                checked={trip?.fromManifest !== undefined ? !!trip?.fromManifest : true}
                onChange={(checked) => onChange({ target: { name: 'trip.fromManifest', value: checked ? 1 : 0 } })}
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            </Col>
            <Col className="w-100">
              <NumberInputField
                label="Split:"
                name="trip.split"
                value={trip?.split || undefined}
                onChange={(value: number) => onChange({ target: { name: 'trip.split', value } })}
                min={0}
                max={99}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextAreaField
                className="mt-0 {height:5rem;resize:none;}"
                label="Comments:"
                name="trip.comments"
                value={trip?.comments || ''}
                onChange={(value: string) => onChange({ target: { name: 'trip.comments', value } })}
                // TODO: Remove the "field" option. It's misleading since it's technically styling the input, and is not needed.
                options={{ disableResize: true }}
              />
            </Col>
          </Row>
          {mode === 'editing' && (
            <Row>
              <Col>
                <SwitchInput
                  label="Increment?"
                  checked={!!trip?.increment}
                  onChange={(checked) => onChange({ target: { name: 'trip.increment', value: checked } })}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              </Col>
              <Col>
                <NumberInputField
                  label="Days:"
                  type="number"
                  name="trip.days"
                  value={trip?.days || 0}
                  disabled={!trip?.increment}
                  onChange={(value: number) => onChange.int({ target: { name: 'trip.days', value } })}
                  min={0}
                />
              </Col>
            </Row>
          )}
        </div>
      </Collapse>
      <div className="mt-4">
        {mode === 'editing' && (
          <HasPermission name="allowSaveAndCopyExistingTrips">
            <TippyWhen
              isTrue={!isValid || !trip?.increment || (trip?.days || 0) <= 0}
              options={{
                content: !isValid
                  ? 'Fields are invalid.'
                  : !trip?.increment
                    ? 'You must increment the scheduled date.'
                    : (trip?.days || 0) <= 0
                      ? 'You must set the number of days to increment.'
                      : 'Something is wrong with the data provided.',
              }}
            >
              <span>
                <Button
                  className="w-100 my-2"
                  variant="gray"
                  onClick={() => handleSubmit(true)}
                  disabled={!isValid || !trip?.increment || (trip?.days || 0) <= 0}
                >
                  SAVE AS COPY
                </Button>
              </span>
            </TippyWhen>
          </HasPermission>
        )}
        {mode === 'creating' && (
          <Row>
            <Col>
              <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
                <Button className="w-100 my-2" variant="black" onClick={onSaveAndCopy} disabled={!valid}>
                  SAVE & COPY
                </Button>
              </TippyWhen>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={6}>
            <Button name="CANCEL" className="w-100" variant="secondary" onClick={handleHide}>
              CANCEL
            </Button>
          </Col>
          <Col xs={6}>
            <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
              <Button name="SUBMIT" className="w-100" variant="primary" onClick={() => handleSubmit()} disabled={!valid}>
                SAVE
              </Button>
            </TippyWhen>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

interface TripStopProps {
  data: TripStop;
  onDelete?: () => void;
  onChange: OnChange;
  onCreateLocation: (location: Location) => void;
  index: number;
  disabled?: boolean;
  readOnly?: boolean;
  valid?: { locationId?: boolean | Validation.ValidityType; scheduled?: boolean | Validation.ValidityType };
  options?: {
    autoSelectLocation?: string;
    filterLocationByAirport?: boolean;
    location?: {
      props?: any;
    };
    disableType?: boolean;
    disableTime?: boolean;
    hideCity?: boolean;
    hideScheduled?: boolean;
  };
  tripScheduledTime?: string;
}
const TripStop = (() => {
  const TripStop = ({
    data: stop,
    onDelete,
    onChange,
    onCreateLocation,
    index: s,
    disabled,
    readOnly,
    valid,
    options,
    tripScheduledTime,
  }: TripStopProps): JSX.Element => {
    const filters = options?.location?.props?.filter || {};
    return (
      <Row className="TripStop align-items-center pt-2 pb-3">
        <Col xs={2} className="pe-0">
          <InputGroup style={{ flexWrap: 'nowrap' }}>
            {onDelete && !disabled && (
              <InputGroup.Text className="px-1">
                <Button variant="icon" className="p-0" onClick={onDelete}>
                  <i className="fa fa-minus-circle text-danger" />
                </Button>
              </InputGroup.Text>
            )}
            <TripStopTypeDropdown
              name={`trip.stops.${s}.type`}
              value={stop?.type || 'PU'}
              onChange={(value: StopTypeEnum) => onChange({ target: { name: `trip.stops.${s}.type`, value } })}
              readOnly={readOnly}
              options={{
                disabled: options?.disableType || disabled,
                showClearButton: false,
              }}
            />
          </InputGroup>
        </Col>
        <Col
          xs={10 - (options?.hideCity ? 0 : 2) - (options?.hideScheduled ? 0 : 2)}
          className={`ps-1 ${options?.hideCity && options?.hideScheduled ? '' : 'pe-0'}`}
        >
          <LocationQueryDropdown
            airports={[
              ...(filters?.airportCode || []),
              ...(stop?.airportFilter ? [stop.airportFilter] : []),
              ...(stop?.airportCode ? [stop.airportCode] : []),
            ]}
            name={`trip.stops.${s}.locationId`}
            value={stop?.locationId || ''}
            onChange={(value: string) => onChange({ target: { name: `trip.stops.${s}.locationId`, value } })}
            readOnly={readOnly}
            isValid={valid?.locationId === Validation.ValidityType.VALID}
            isInvalid={
              valid?.locationId === Validation.ValidityType.INVALID || valid?.locationId === Validation.ValidityType.INVALID_WARNING
            }
            onCreate={onCreateLocation}
            options={{
              disabled,
              showAirportPrefix: false,
              locale: { 'Select...': 'Select Location' },
              autoSelectLocation: options?.autoSelectLocation,
            }}
            className={valid?.locationId === Validation.ValidityType.VALID_WARNING ? 'is-dirty' : undefined}
          />
        </Col>
        {!options?.hideCity && (
          <Col xs={2} className={getClasses('ps-1', !options?.hideScheduled ? 'pe-0' : undefined)}>
            <AirportDropdown
              placeholder="City"
              name={`trip.stops.${s}.airportFilter`}
              value={stop?.airportCode || stop?.airportFilter || ''}
              onChange={(value) => onChange({ target: { name: `trip.stops.${s}.airportFilter`, value } })}
              readOnly={readOnly}
              className={`px-0 ${options?.hideCity ? 'd-none' : ''}`}
              options={{ disabled, showClearButton: false }}
            />
          </Col>
        )}
        {!options?.hideScheduled && (
          <Col xs={2} className="ps-1">
            <InputGroup className="p-relative">
              <TimeInput
                name={`trip.stops.${s}.scheduled`}
                value={stop?.scheduled ? new Datetime(stop?.scheduled).time : ''}
                onChange={(event: EventObject) => {
                  const value = event?.target?.value;
                  const formattedTime = new Datetime(tripScheduledTime).setTime(value).toString();
                  const input: EventObject = { target: { name: `trip.stops.${s}.scheduled`, value: formattedTime } };
                  onChange?.time ? onChange.time(input) : onChange(input);
                }}
                isValid={!!valid?.scheduled}
                isInvalid={!valid?.scheduled}
                disabled={options?.disableTime || disabled}
                readOnly={readOnly}
              />
            </InputGroup>
          </Col>
        )}
      </Row>
    );
  };
  return React.memo(TripStop, (prev, next) => equal(prev, next));
})();

export default TripForm;
