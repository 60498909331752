import { LoadingBlur } from '@/components/LoadingSpinner';
import PageInfo from '@/components/PageInfo';
import { useSummaryHistory } from '@/features/SummaryHistory';

const SummaryHistoryFiltersInfo = () => {
  const [{ summaries, loading }] = useSummaryHistory(({ summaries, loading }) => ({
    summaries,
    loading,
  }));

  return (
    <>
      <LoadingBlur label="Loading Summaries" loading={loading} />
      <PageInfo>{summaries.size} Summaries</PageInfo>
    </>
  );
};

export default SummaryHistoryFiltersInfo;
